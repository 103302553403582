import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import landingPage from "./components/landingPage";
import enterCode from "./components/enterCode";
import waitingRoom from "./components/waitingRoom";
import facilitatorPortal from "./components/facilitatorPortal";
import waitingRoomHost from "./components/waitingRoomHost";
import participantView from "./components/participantView";
import walkResults from "./components/walkResults";
import questionSearch from "./components/questionSearch";
import createWalk from "./components/createWalk";
import testView from "./components/TestView";
import endWalkParticipant from "@/components/endWalkParticipant";
import adminPage from "@/components/adminPage";
import responsesPerQuestion from "@/components/responsesPerQuestion";
import facilitatorLedWalkFacilitator from "@/components/facilitatorLedWalkFacilitator";
import participantPacedWalkParticipant from "@/components/participantPacedWalkParticipant";
import store from '@/stores';
import StoreIndex from "@/store/facilitator-portal/_StoreIndex"
import {StoreState} from "@/store/facilitator-portal/state"
import folders from "@/components/folders.vue";

var VueCookie = require('vue-cookie');

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(VueCookie);

Vue.config.productionTip = false;

export const router = new VueRouter({
    routes: [
        {path: '/waitingRoom', component: waitingRoom },
        {path: '/waitingRoomHost', component: waitingRoomHost},
        {path: '/', component: landingPage, name: 'landingPage'},
        {path: '/portal', component: facilitatorPortal},
        {path: '/enterCode', component: enterCode, name: 'enterCode'},
        {path: '/waitingRoom', component: waitingRoom},
        {path: "/question", component: participantView},
        {path: "/results", component: walkResults},
        {path: "/questionSearch", component: questionSearch},
        {path: "/createWalk", component: createWalk},
        {path: "/test", component: testView},
        {path: "/endofwalk", component: endWalkParticipant},
        {path: "/admin", component: adminPage},
        {path: "/responsesPerQuestion", component: responsesPerQuestion},
        {path: "/facilitatorLedWalkFacilitator", component: facilitatorLedWalkFacilitator},
        {path: "/participantPacedWalkParticipant", component: participantPacedWalkParticipant},
        {path: "/folders", component: folders},
    ],
    mode: 'hash',
});

router.beforeEach(async (to, from, next) => {
    try {
        const id = store.state[StoreIndex.storeName][StoreState.id]
        // if the user is logged in it the id will have a value
        if (
            to.path === "/enterCode" ||
            to.path === "/waitingRoom" ||
            to.path === "/login" ||
            to.path === "/participantPacedWalkParticipant" ||
            to.path === "/endofwalk" ||
            to.path === "/" ||
            to.path === "/test" ||
            to.path === "/question"
        ) {
            // if going to any of these routes proceed, no login is required (if more are needed add here)
            next();
            return
        } else if (id !== null && id !== undefined){
            // Users must be signed in to access any other page, if they're not don't let them go
            next();
            return
        }
        next("/");
    } catch (error) {
        console.error(error)
        // there was an error, dont let them leave the page
        next(false); // Cancel the navigation
    }
});

new Vue({
    render: h => h(App),
    router: router,
    store
}).$mount('#app')