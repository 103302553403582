import axios from 'axios'

const TAG_API_URL = "/api/tag"

class TagServices {
    getTags() {
        return axios.get(TAG_API_URL + '/allnames');
    }
    setTags(tags, question_id) {
        return axios.post(TAG_API_URL + '/setTag/' + tags + '/' + question_id);
    }
    getQuestionsByTags(tags) {
        return axios.get(TAG_API_URL + '/all/questions/tag/' + tags);
    }
}

export default new TagServices()