import axios from 'axios'

const WALK_RESULTS_API_URL = "/api/"

class WalkResultsServices {
    submitResults(session_id,participant_id) {
        return axios.post(WALK_RESULTS_API_URL + 'walkresult/user/' + participant_id);
    }
    submitCollection(session_id, result_id){
        return axios.post(WALK_RESULTS_API_URL + 'walkresultssessioncollection/add/'+ session_id + '/' + result_id);
    }
    submitCollectionWalkResponse(result_id, response_id, session_id){
        return axios.post(WALK_RESULTS_API_URL + 'walkresultswalkresponsecollection/add/'+ result_id + '/' + response_id + '/' + session_id);
    }
    updateSteps(wrwr_id, result_id, total_steps){
        return axios.put(WALK_RESULTS_API_URL + 'walkresultswalkresponsecollection/' + wrwr_id + '/update/' + result_id + '/' + total_steps);
    }
    getCsvResults(session_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultswalkresponsecollection/csv/walkresult/session/' + session_id);
    }
    checkResults(session_id, walk_responses){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultswalkresponsecollection/check/walkresult/session/' + session_id + '/' + walk_responses);
    }
    getResults(session_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultswalkresponsecollection/walkresults/id/' + session_id);
    }
    getCsvForWalkResults(session_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultswalkresponsecollection/all/walkresult/steps/' + session_id);
    }
    submitResponseCollection(session_id, result_id) {
        return axios.post(WALK_RESULTS_API_URL + 'walkresponsessessioncollection/add/' + session_id + '/' + result_id);
    }
    getResultsByID(session_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultssessioncollection/results/' + session_id + '/users');
    }
    getResultsTotalByID(session_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultssessioncollection/results/total/' + session_id);
    }
    getTotalSteps(session_id, participant_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultssessioncollection/getscore/' + session_id + '/' + participant_id);
    }
    getNumberOfResultsByID(session_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultssessioncollection/findNumberResultsBy/session/' + session_id);
    }
    getScoresPerQuestion(session_id){
        return axios.get(WALK_RESULTS_API_URL + 'walkresultswalkresponsecollection/session/' + session_id + '/questionResults/');
    }
}

export default new WalkResultsServices()