<template>
  <div class="navBar">
    <b-navbar type="light">
      <b-navbar-brand href="/">
        <h3>Privilege Walk</h3>
      </b-navbar-brand>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
      <div v-if="this.windowWidth > 720">   <!-- LARGE SCREEN WIDTH -->
          <b-nav-form>
            <a v-b-toggle class="menu" @click="setLogin" style="cursor: pointer" v-if="id === null || id === ''">
              <span style="font-weight: bolder">Sign In</span>
            </a>
            <a v-b-toggle class="menu" @click="goToPortal" style="cursor: pointer" v-if="id !== null">
              <span style="font-weight: bolder">Portal</span>
            </a>
            <a v-b-toggle class="space" v-if="true">
              <span style="font-weight: bolder"></span>
            </a>
            <a v-b-toggle class="menu" @click="openUserManual()" style="cursor: pointer" v-if="true">
              <span style="font-weight: bolder">Help</span>
            </a>
            <a v-b-toggle class="space" v-if="id !== null">
              <span style="font-weight: bolder"></span>
            </a>
            <a v-b-toggle class="menu" @click="logout" style="cursor: pointer" v-if="id !== null && id !== ''">
              <span style="font-weight: bolder">Logout</span>
            </a>
          </b-nav-form>
      </div>
      <div v-else>                <!-- SMALL SCREEN WIDTH -->
        <b-nav-item-dropdown text="Options" right>
          <b-dropdown-item href="#">
            <a v-b-toggle class="menu" @click="setLogin" style="cursor: pointer" v-if="id === null || id === ''">
              <span style="font-weight: bolder">Sign In</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <a v-b-toggle class="menu" @click="goToPortal" style="cursor: pointer" v-if="id !== null">
              <span style="font-weight: bolder">Portal</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <a v-b-toggle class="menu" @click="openUserManual()" style="cursor: pointer" v-if="true">
              <span style="font-weight: bolder">User Manual</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <a v-b-toggle class="menu" @click="logout" style="cursor: pointer" v-if="id !== null && id !== ''">
              <span style="font-weight: bolder">Logout</span>
            </a>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </div>
      </b-navbar-nav>

      <!-- <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <a v-b-toggle class="menu" @click="setLogin" style="cursor: pointer" v-if="id === null || id === ''">
            <span style="font-weight: bolder">Sign In</span>
          </a>
          <a v-b-toggle class="menu" @click="goToPortal" style="cursor: pointer" v-if="id !== null">
            <span style="font-weight: bolder">Portal</span>
          </a>
          <a v-b-toggle class="space" v-if="true">
            <span style="font-weight: bolder"></span>
          </a>
          <a v-b-toggle class="menu" @click="openUserManual()" style="cursor: pointer" v-if="true">
            <span style="font-weight: bolder">User Manual</span>
          </a>
          <a v-b-toggle class="space" v-if="id !== null">
            <span style="font-weight: bolder"></span>
          </a>
          <a v-b-toggle class="menu" @click="logout" style="cursor: pointer" v-if="id !== null && id !== ''">
            <span style="font-weight: bolder">Logout</span>
          </a>
        </b-nav-form>
      </b-navbar-nav>  -->
    </b-navbar>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import StoreIndex from "@/store/facilitator-portal/_StoreIndex"
import LandingStoreIndex from "@/store/landing-page/_StoreIndex"
import { StoreState as FacilitatorStoreState } from "@/store/facilitator-portal/state";
import { StoreState as LandingPageStoreState } from "@/store/landing-page/state";
import {StoreMutations} from "@/store/landing-page/mutations";
import {StoreActions} from "@/store/facilitator-portal/actions";
import FacilitatorServices from "../services/FacilitatorServices";


export default {
  name: "title_bar",
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      admin: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.windowResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize);
  },
  computed: {
    ...mapState(StoreIndex.storeName,{
      id: FacilitatorStoreState.id
    }),
    ...mapState(LandingStoreIndex.storeName,{
      login: LandingPageStoreState.loginSelected
    })
  },
  methods: {
    ...mapMutations(LandingStoreIndex.storeName, {
      SET_LOGIN_SELECTED: StoreMutations.SET_LOGIN_SELECTED
    }),
    ...mapActions(StoreIndex.storeName, {
      logout: StoreActions.logout
    }),
    setLogin() {
      this.SET_LOGIN_SELECTED(!this.login);
    },
    goToPortal() {
      this.$router.push({path: 'portal', query: {id: this.id}});
    },
    async openUserManual() {
      await this.getFacilitatorStatus();
      if (this.admin) {
        const pdfUrl = "./assets/static/UserManualAdmin.pdf"
        window.open(pdfUrl, '_blank');
      } else if (this.id !== null) {
        const pdfUrl = "./assets/static/UserManualFacilitator.pdf"
        window.open(pdfUrl, '_blank');
      } else {
        const pdfUrl = "./assets/static/UserManualParticipant.pdf"
        window.open(pdfUrl, '_blank');
      }
    },
    windowResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    },
    async getFacilitatorStatus() {
      if(this.id !== null) {
        await FacilitatorServices.getFacilitatorByID(this.id).then(response => {
          this.admin = response.data.admin;
        })
      }
      else {
        this.admin = false;
      }
    },
  }
}
</script>

<style scoped>
.navBar{
  top: 0;
  right: 0;
  left: 0;
  background-color: #8FC1E3;
  font-weight: bolder;
  box-shadow: 0 4px 8px rgb(128, 128, 128);
  position: fixed;
  z-index: 20;
}

.menu {
  text-decoration: none;
  color: #333333;
}

.space {
  padding-left: 50px;
}
</style>