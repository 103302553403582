import axios from 'axios'

const FACILITATOR_API_URL = "/api/"

class AdminServices {
    getFacilitatorList(){
        return axios.get(FACILITATOR_API_URL + 'facilitator/all');
    }
    getFolderList(){
        return axios.get(FACILITATOR_API_URL + 'folder/all');
    }
    hideQuestion(question_id, flag, ID){
        return axios.put(FACILITATOR_API_URL + 'admin/flag/question/' + question_id + '/' + flag + '/' + ID);
    }
    hideWalk(walk_id, flag, ID) {
        return axios.put(FACILITATOR_API_URL + 'admin/flag/privilegeWalk/' + walk_id + '/' + flag + '/' + ID);
    }
    flipAdminFlag(facilitator_id){
        return axios.put(FACILITATOR_API_URL + 'admin/flipadminflag/' + facilitator_id);
    }
    flipHiddenFlag(facilitator_id){
        return axios.put(FACILITATOR_API_URL + 'admin/fliphiddenflag/' + facilitator_id);
    }
}

export default new AdminServices()
