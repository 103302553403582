export function QuestionAdapter(adapter) {
    if (adapter.question_id) {
        this.questionText = adapter.question_id.questionText || '';
        this.question_id = adapter.question_id.question_id || '';
    } else if (adapter.questionText) {
        this.questionText = adapter.questionText || '';
        this.question_id = adapter.question_id || '';
    } else {
        this.questionText = adapter.question_text || '';
        this.question_id = adapter.questionID || '';
    }
    this.tags = [];
    if (adapter.backwardScore) {
        this.step_back = adapter.backwardScore || '';
        this.step_forward = adapter.forwardScore || '';
        this.idk = adapter.allowIDK || '';
        this.priorityOrder = adapter.priorityOrder || '';
    } else {
        this.step_back = adapter.step_back || '';
        this.step_forward = adapter.step_forward || '';
        this.idk = adapter.idk || '';
        this.priorityOrder = adapter.priorityOrder || '';
    }
    this.inWalk = adapter.inWalk;
    this.added = adapter.added;
    if (adapter.walk_id.walk_id) {
        this.walkId = adapter.walk_id.walk_id;
    }
}

