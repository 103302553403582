import axios from 'axios'

const FOLDER_API_URL = "/api/folder/"
const WALK_FOLDER_API_URL = "/api/walkfolder/"

class FolderServices {
    findAllFolders() {
        return axios.get(FOLDER_API_URL + "all");
    }
    findFoldersByFacilitatorID(facilitator_id) {
        return axios.get(FOLDER_API_URL + "facilitator_id/" + facilitator_id);
    }
    newFolder(folder_name, facilitator_id) {
        return axios.post(FOLDER_API_URL + "newfolder/" + folder_name + "/" + facilitator_id);
    }
    newWalkFolder(folder_id, walk_id) {
        return axios.post(WALK_FOLDER_API_URL + "newWalkFolder/" + folder_id + "/" + walk_id);
    }
    findAllWalkFolders(){
        return axios.get(WALK_FOLDER_API_URL + "all");
    }
    findAllWalkFoldersById(folder_id){
        return axios.get(WALK_FOLDER_API_URL + "find/walkfolder/" + folder_id);
    }
    async deleteWalkFolder(folder_id,walk_id) {
        try {
            const response = await axios.delete(WALK_FOLDER_API_URL + "delete/" + folder_id+"/"+walk_id);
            return response.data; // Return data if needed
        }catch (error) {
            if (error.response && error.response.status === 404) {
                console.error("Walk folder not found:", error.response.data);
            } else {
                console.error("Error deleting walk folder:", error);
            }
            throw error; // Rethrow the error to handle it elsewhere
        }
    }
}

export default new FolderServices()