import axios from 'axios'

const TEST_API_URL = "/api"

class TestServices {
    getText() {
        return axios.get(TEST_API_URL + '/test');
    }
}

export default new TestServices()