import {StoreState} from "@/store/facilitator-portal/state";
import Cookies from "vue-cookie";

export const StoreMutations = {
  SET_ID: 'SET_ID',
  REMOVE_ID: 'REMOVE_ID'
};

export default {
  SET_ID(state, payload) {
    Cookies.set(StoreState.id, payload, { expires: 1 });
    state[StoreState.id] = payload
  },
  REMOVE_ID(state) {
    state[StoreState.id] = null
    Cookies.delete(StoreState.id);
  }
};
