<template>
  <div style="margin-top: 10rem">
    <div>
      <b-container align="center">
        <p style="font-size: x-large">Waiting for Participants to join</p>
        <p style="font-size: xx-large">Your room Code is: {{ room }}</p>
        <img style="align-content: center; justify-content: center; width: 40%; height: auto" src="../assets/pw_walk_qrCode.png" alt="QR-Code">
        <p style="font-size: larger">Number of Participants: {{ numParticipants }}</p>
        <b-button v-if="!button_name" variant="primary" @click="goToResults">Results</b-button>
        <b-button v-if="button_name" variant="primary" @click="goToResults">Start Walk</b-button>
      </b-container>
    </div>
  </div>
</template>

<script>
//document.getElementById("roomtxt").innerHTML = room;
import SessionServices from "@/services/SessionServices";
export default {
  data() {
    return {
      id: this.$route.query.id,
      room: this.$route.query.code,
      numParticipants: 0,
      counterInterval: null,
      selected:this.$route.query.selected,
      sessionID: this.$route.query.sessionID,
      button_name: false,
      walkType: "p",
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.confirmClose);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmClose);
  },
  methods: {
    confirmClose(event) {
      event.preventDefault();
      event.returnValue = '';
      return ''; // For Chrome
    },
    getSession(){
      SessionServices.getSessionByAccessCode(this.room).then((response) => {
        this.numParticipants = response.data.numberOfCurrentParticipants
      })
    },
    goToResults(){
      console.log("selected")
      console.log(this.selected);
      if(this.selected === 'A'){
        this.$router.push({path: 'results', query: {roomKey: this.room, id: this.id, walkType: this.walkType} })
      }
      else{
        this.$router.push({path: 'facilitatorLedWalkFacilitator', query: {roomKey: this.room, id: this.id, sessionID: this.sessionID}})
      }
    },
    async changeButton() {
      await SessionServices.getCurrentQuestionNum(this.sessionID).then((resp) => {
        if (resp.data < 0) {
          this.button_name = true
        }
      })
    }
  },
  created() {
    //if(this.selected === 'B'){
    //  SessionServices.changeQuestionNum(this.room, 1);
    //}
    this.changeButton();
    this.counterInterval = setInterval(
        function()
        {
          this.getSession();
        }.bind(this), 2500);
    return this.getSession();
  },
  beforeRouteLeave(to, from , next) {
    clearInterval( this.counterInterval )
    next();
  },
  beforeMount() {
    //if(this.selected === 'B'){
    //  SessionServices.changeQuestionNum(this.room, 1);
    //}
  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  color: dimgray;
}
</style>
