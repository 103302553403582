<template>
  <div style="padding-top: 10%">
    <div style="margin-top: 5rem">
      <div v-if="this.windowWidth <= 720">
        <b-container align="center">
          <form action="/waitingRoom">
            <br>
            <label style="font-size: x-large">Enter Room Code:</label>
            <p></p>
            <div class="input">
              <b-form @submit.stop.prevent>
                <b-form-input v-model="accessCode" :state="validation"
                              v-on:keyup.enter="checkAccessCode(validation)"></b-form-input>
                <b-form-invalid-feedback :state="validation">
                  Needs to be six numbers long.
                </b-form-invalid-feedback>
              </b-form>
            </div>
            <p></p>
            <p><b-button variant="outline-dark" size="large" style="font-size: x-large" @click="checkAccessCode(validation)">Submit</b-button></p>
          </form>
        </b-container>
      </div>
      <div v-else>
        <b-container align="center">
          <form action="/waitingRoom">
            <br>
            <label>Enter Room Code:</label>
            &nbsp;
            <div class="input">
              <b-form @submit.stop.prevent>
                <b-form-input v-model="accessCode" :state="validation"
                              v-on:keyup.enter="checkAccessCode(validation)"></b-form-input>
                <b-form-invalid-feedback :state="validation">
                  Needs to be six numbers long.
                </b-form-invalid-feedback>
              </b-form>
            </div>&nbsp;
            <p><b-button variant="outline-dark" @click="checkAccessCode(validation)">Submit</b-button></p>
          </form>
        </b-container>
      </div>
    </div>
    <div>
      <b-modal ref="nocode-modal" title="Room Code" hide-footer><h5>This room code does not exist.</h5></b-modal>
      <b-modal ref="closed-modal" title="Room Code" hide-footer><h5>This walk is no longer active.</h5></b-modal>
      <b-modal ref="max-modal" title="Room Code" hide-footer><h5>Max Participants Reached.</h5></b-modal>
    </div>
  </div>
</template>

<script>
import SessionServices from "@/services/SessionServices";
import ParticipantServices from "@/services/ParticipantServices";
export default {
  name: 'Test',
  data() {
    return {
      selected: '',
      accessCode: '',
      codename: "",
      participant_id: "",
      session_id: "",
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    validation() {
      return this.accessCode.length === 6 && (/\d/.test(this.accessCode))
    }
  },
  methods: {
    checkAccessCode(val) {
      if(val === false) return;
      SessionServices.getSessionByAccessCode(this.accessCode).then((response) => {
        if(response.data === "") return this.$refs['nocode-modal'].show();
        if(response.data.active === false) return this.$refs['closed-modal'].show();
        if(response.data.max  <= response.data.numberOfCurrentParticipants) return this.$refs['max-modal'].show();
        // this.addParticipant();
        this.session_id = response.data.id;
        ParticipantServices.addParticipant(this.codename).then((response) => {
          this.participant_id = response.data.participant_id;
          this.addUserToSession(this.participant_id);

          // Skip waiting room (for asynchronous walk)
          SessionServices.getWalkIDFromSessionID(this.session_id).then((response) => {
            this.walkID = response.data.walk_id;
            this.version = response.data.currentVersion;
            SessionServices.getCurrentQuestionNum(this.session_id).then(response => {
              if(response.data === 0){
                this.$router.push({path: 'participantPacedWalkParticipant', query: {walkID: this.walkID, participant_id: this.participant_id, sessionID: this.session_id, codename: this.codename, version: this.version, room_key: this.accessCode} })

              }
              else {
                //this.$router.push({path: 'question', query: {walkID: this.walkID, participant_id: this.participant_id, sessionID: this.session_id, codename: this.codename, version: this.version, room_key: this.accessCode} })
                this.$router.push({path: 'waitingRoom', query: {walkID: this.walkID, participant_id: this.participant_id, sessionID: this.session_id, codename: this.codename, version: this.version, room_key: this.accessCode} })
              }
            })
          })


          // Feature for synchronous walks
          //this.$router.push({path: 'waitingRoom', query: {roomKey: this.accessCode, session_id: this.session_id, participant_id: response.data.user_id, codename: this.codename } })
          //
          //this.$router.push({path: 'question', query: {walkID: this.walkID, participant_id: this.participant_id, sessionID: this.session_id, codename: this.codename, version: this.version, room_key: this.accessCode} })
        })
      })
    },
    generateName() {
      this.codename = Math.random().toString(36).substr(2, 6)
    },
    addParticipant() {
      ParticipantServices.addParticipant(this.codename).then((response) => {
        this.addUserToSession(response.data.participant_id);
      })
    },
    addUserToSession(participant_id){
      SessionServices.addParticipantToSession(participant_id, this.accessCode);
    },
    windowResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    this.generateName()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.windowResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
