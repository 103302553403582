import SpringSecurityServices from "@/services/SpringSecurityServices";
import {router} from "@/main";
import {StoreMutations} from "@/store/facilitator-portal/mutations";

export const StoreActions = {
    checkSession: 'checkSession',
    logout: 'logout'
};

export default {
    checkSession ({ commit }) {
        return SpringSecurityServices.getSession().then(result => {
            if (result.data !== null && result.data !== '') {
                commit(StoreMutations.SET_ID, result.data)
            } else {
                router.push({name: 'landingPage'})
            }
        })
    },
    logout ({ commit }) {
        return SpringSecurityServices.logout().then(() => {
            commit(StoreMutations.SET_ID, "")
            commit(StoreMutations.REMOVE_ID);
            router.push({name: 'landingPage'})
        })
    }
};
