import axios from 'axios'

const PARTICIPANT_API_URL = "/api/participant/"

class ParticipantServices {
    getParticipant(codename) {
        return axios.get(PARTICIPANT_API_URL + codename);
    }
    addParticipant(codename){
        return axios.post(PARTICIPANT_API_URL + codename);
    }
}

export default new ParticipantServices()
