import axios from 'axios'

const PRIVILEGE_WALK_API_URL = "/api/privilegewalk/"

class PrivilegeWalkServices {
    createWalk(pw_name, facilitator_id) {
        return axios.post(PRIVILEGE_WALK_API_URL + "walk/name/" + pw_name + "/" + facilitator_id);
    }
    findAllWalks() {
        return axios.get((PRIVILEGE_WALK_API_URL + "all"))
    }
    findWalks(facilitator_id) {
        return axios.get(PRIVILEGE_WALK_API_URL + "walkbyid/" + facilitator_id);
    }
    //doesn't work
    findWalkByID(walk_id){
        return axios.get(PRIVILEGE_WALK_API_URL + walk_id);
    }
    findWalkCollabByID(facilitator_id){
        return axios.get(PRIVILEGE_WALK_API_URL + "get/walk/collaborator/" + facilitator_id);
    }
    changeWalkTitle(walk_id, title){
        return axios.put(PRIVILEGE_WALK_API_URL + "changeWalkTitle/id/" + walk_id + '/' + title);
    }
    setWalkStatus(walk_id, walkstatus){
        return axios.put(PRIVILEGE_WALK_API_URL + "set/walkStatus/" + walkstatus + '/' + walk_id);
    }
    changeWalkVersion(walk_id){
        return axios.put(PRIVILEGE_WALK_API_URL + "updateVersion/id/" + walk_id);
    }
    changeModifiedAt(walk_id){
        return axios.put(PRIVILEGE_WALK_API_URL + "changeModifiedAt/id/" + walk_id);
    }
    addCollab(walk_id, user_id, privileges){
        return axios.put(PRIVILEGE_WALK_API_URL + "set/collaborator/" + walk_id + '/' + user_id + '/' + privileges);
    }
    getEditStatus(walk_id){
        return axios.get(PRIVILEGE_WALK_API_URL + "get/walk/editStatus/" + walk_id);
    }
    setEditStatus(walk_id, status){
        return axios.put(PRIVILEGE_WALK_API_URL + "set/walk/editStatus/" + walk_id + '/' + status);
    }
    setWalkFinalized(walk_id){
        return axios.put(PRIVILEGE_WALK_API_URL + "set/walk/finalized/" + walk_id);
    }
    setMaxMin(walk_id, max, min) {
        return axios.put(PRIVILEGE_WALK_API_URL + "set/maxmin/" + walk_id + "/" + max + "/" + min);
    }
    getMax(walk_id) {
        return axios.get(PRIVILEGE_WALK_API_URL + "get/max/" + walk_id);
    }
    getMin(walk_id) {
        return axios.get(PRIVILEGE_WALK_API_URL + "get/min/" + walk_id);
    }
}

export default new PrivilegeWalkServices()