<template>
  <div style="margin-top: 10rem">
    <div>
      <b-container align="center">
        <p style="font-size: x-large">Now Waiting in Room: {{ room }}</p>
        <b-button variant="primary" @click="toQuestionsPage()">Join</b-button>
      </b-container>
    </div>
  </div>
</template>

<script>
//let URL = new URLSearchParams(window.location.search);
//document.getElementById("roomtxt").innerHTML = room;
import SessionServices from "@/services/SessionServices";
export default {
  name: 'Test',
  props: {
    msg: String
  },
  data() {
    return {
      selected: '',
      //room: URL.get("room")
      room: this.$route.query.room_key,
      session_id: this.$route.query.sessionID,
      participant_id: this.$route.query.participant_id,
      walkID: this.$route.query.walkID,
      version: this.$route.query.version,
      codename: this.$route.query.codename
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.confirmClose);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmClose);
  },
  methods: {
    confirmClose(event) {
      event.preventDefault();
      event.returnValue = '';
      return ''; // For Chrome
    },
    showMsgBox() {
      this.$bvModal.msgBoxOk('Please wait for the facilitator to start the Walk', {
        title: 'Please Wait',
        size: 'sm',
        buttonSize: 'md',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
    //getWalkIDFromSessionID(){
    //  SessionServices.getWalkIDFromSessionID(this.session_id).then((response) => {
    //    this.walkID = response.data.walkID
    //    this.version = response.data.currentVersion
    //  })
    //},
    async toQuestionsPage() {
      await SessionServices.getCurrentQuestionNum(this.session_id).then((resp) => {
        if (resp.data < 1) {
          this.showMsgBox();
          return;
        }
        else{
          this.$router.push({path: 'question',
            query: {
              walkID: this.walkID,
              participant_id: this.participant_id,
              sessionID: this.session_id,
              codename: this.codename,
              version: this.version,
              room_key: this.room
            }
          })
        }
      })

      //this.$router.push({path: 'question', query: {walkID: this.walkID, participant_id: this.participant_id, sessionID: this.session_id, codename: this.$route.query.codename, version: this.version, room_key: this.room} })
      //                                     query: {walkID: this.walkID, participant_id: this.participant_id, sessionID: this.session_id, codename: this.codename, version: this.version, room_key: this.accessCode}
    }
  },
  created() {
    //this.getWalkIDFromSessionID()
  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  color: black;
}
</style>
