<template>
  <div>
    <div class="small-screen" v-if="this.windowWidth <= 720">
      <div v-if="wait" class="loading">
        <h4 v-if="getQs">Getting Questions</h4>
        <h4 v-if="submitRes">Submitting Responses</h4>
        <b-spinner label="Spinning" type="grow"></b-spinner>
        &nbsp;
        <b-spinner label="Spinning" type="grow"></b-spinner>
        &nbsp;
        <b-spinner label="Spinning" type="grow"></b-spinner>
      </div>
      <div v-else>
        <div class="question">
          <p style="font-size: larger; margin-top: 0.5rem"><b>Question: </b> {{ Questions[counter].question_text }}</p>
          <!--      <b-progress :value="counter + 1" :max="Questions.length" variant="success" animated></b-progress>-->
          <b-progress :max="Questions.length" height="1.2rem" variant="success">
            <b-progress-bar :value="counter + 1">
              <span><strong>{{ counter + 1 }} of {{ Questions.length }}</strong></span>
            </b-progress-bar>
          </b-progress>
        </div>
        <div class="buttonSelect">
          <b-container align="center">
            <b-button :pressed="Questions[counter].answer[0]" block pill variant="outline-primary"
                      v-on:click="Questions[counter].answer = [true, false, false, false, false];">Yes
            </b-button>
            <b-button :pressed="Questions[counter].answer[1]" block pill variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, true, false, false, false]; ">No
            </b-button>
            <b-button v-if="Questions[counter].sometimes" :pressed="Questions[counter].answer[2]" block pill
                      variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, false, true, false, false];">Sometimes
            </b-button>
            <b-button v-if="Questions[counter].idk" :pressed="Questions[counter].answer[3]" block pill
                      variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, false, false, true, false];">I Don't Know
            </b-button>
            <b-button :pressed="Questions[counter].answer[4]" block pill variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, false, false, false, true];">Prefer Not to Answer
            </b-button>
          </b-container>
        </div>
        <div class="button-next-small">
          <div v-if="Questions.length === counter + 1 || submitbtn">
            <b-button variant="warning" @click="answerQuestion(counter);">Submit
            </b-button>
          </div>
          <div v-else>
            <b-button variant="success" @click="answerQuestion(counter); counter = counter + 1">Next Question
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 10rem" v-else>
      <div v-if="wait" class="loading">
        <h4 v-if="getQs">Getting Questions</h4>
        <h4 v-if="submitRes">Submitting Responses</h4>
        <b-spinner label="Spinning" type="grow"></b-spinner>
        &nbsp;
        <b-spinner label="Spinning" type="grow"></b-spinner>
        &nbsp;
        <b-spinner label="Spinning" type="grow"></b-spinner>
      </div>
      <div v-else>
        <div class="question">
          <p style="font-size: larger; margin-top: 0.5rem"><b>Question: </b> {{ Questions[counter].question_text }}</p>
          <!--      <b-progress :value="counter + 1" :max="Questions.length" variant="success" animated></b-progress>-->
          <b-progress :max="Questions.length" height="1.2rem" variant="success">
            <b-progress-bar :value="counter + 1">
              <span><strong>{{ counter + 1 }} of {{ Questions.length }}</strong></span>
            </b-progress-bar>
          </b-progress>
        </div>
        <div class="buttonSelect">
          <b-container align="center">
            <b-button :pressed="Questions[counter].answer[0]" block pill variant="outline-primary"
                      v-on:click="Questions[counter].answer = [true, false, false, false, false];">Yes
            </b-button>
            <b-button :pressed="Questions[counter].answer[1]" block pill variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, true, false, false, false]; ">No
            </b-button>
            <b-button v-if="Questions[counter].sometimes" :pressed="Questions[counter].answer[2]" block pill
                      variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, false, true, false, false];">Sometimes
            </b-button>
            <b-button v-if="Questions[counter].idk" :pressed="Questions[counter].answer[3]" block pill
                      variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, false, false, true, false];">I Don't Know
            </b-button>
            <b-button :pressed="Questions[counter].answer[4]" block pill variant="outline-primary"
                      v-on:click="Questions[counter].answer = [false, false, false, false, true];">Prefer Not to Answer
            </b-button>
          </b-container>
        </div>
        <div class="button-name">
          <!--        <div v-if="counter !== 0" style="float:left">-->
          <!--          <div v-if="submitbtn === false">-->
          <!--            <b-button variant="success" @click="counter = counter - 1">Previous Question-->
          <!--            </b-button>-->
          <!--          </div>-->
          <!--        </div>-->
          <!-- <b-dropdown dropright style="float:left;" text="  " variant="primary">
            <b-dropdown-text style="width: 240px;">
              {{ codename }}
            </b-dropdown-text>
          </b-dropdown> -->
        </div>
        <div class="button-next">
          <div v-if="Questions.length === counter + 1 || submitbtn">
            <b-button variant="warning" @click="answerQuestion(counter);">Submit
            </b-button>
          </div>
          <div v-else>
            <b-button variant="success" @click="answerQuestion(counter); counter = counter + 1">Next Question
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import QuestionServices from "@/services/QuestionServices";
import QuestionListCollectionServices from "@/services/QuestionListCollectionServices";
import WalkResponseServices from "@/services/WalkResponseServices";
import WalkResultsServices from "@/services/WalkResultsServices";
//import walkResults from "@/components/walkResults";

export default {
  name: "participantPacedWalkParticipant",
  data() {
    return {
      Questions: [{
        question_text: '',
        answer: [false, false, false, false, false],
        questionID: '',
        step_back: 1,
        step_forward: 1,
        step_sometimes: 0
      }],
      Answer: '',
      codename: this.$route.query.codename,
      // QID: parseInt(this.$route.query.id) || 0,
      walk_id: this.$route.query.walkID,
      counter: 0,
      participant_id: this.$route.query.participant_id,
      session_id: this.$route.query.sessionID,
      room_key: this.$route.query.room_key,
      result_id: '',
      newname: '',
      valid: true,
      submitbtn: false,
      newcount: 0,
      show: false,
      finaltotal: 0,
      wait: false,
      getQs: false,
      submitRes: false,
      wrsc_id: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.confirmClose);
    this.$nextTick(() => {
      window.addEventListener('resize', this.windowResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmClose);
    window.removeEventListener('resize', this.windowResize);
  },
  methods: {
    confirmClose(event) {
      event.preventDefault();
      event.returnValue = '';
      return ''; // For Chrome
    },
    showMsgBox() {
      this.$bvModal.msgBoxOk('Please select an answer before moving on', {
        title: 'Question Not Answered',
        size: 'sm',
        buttonSize: 'md',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
    score() { //calculate score at end of walk
      this.Questions.forEach(question => {
        if (question.answer[0]) {
          this.finaltotal += question.step_forward;
        } else if (question.answer[1]) {
          this.finaltotal += question.step_back;
        } else if (question.answer[2]) {
          this.finaltotal += question.step_sometimes;
        }
      })
    },
    async submit() { //not used part of old process
      for (let i = 0; i < this.Questions.length; i++) {
        if (this.Questions[i].answer[0] === false && this.Questions[i].answer[1] === false && this.Questions[i].answer[2] === false && this.Questions[i].answer[3] === false && this.Questions[i].answer[4] === false) {
          this.valid = false
          this.counter = i
          this.submitbtn = true
          this.showMsgBox(this.Questions[i].question_text)
          break;
        }
      }
      if (this.valid) {
        //this.singleScore(this.counter);
        this.submitRes = true;
        this.wait = true;
        //this.sendAnswers(this.counter);
        this.submitResponses();
        this.wait = false;
        this.submitRes = false;
        await this.$router.push({
          path: 'endofwalk',
          query: {
            sessionID: this.session_id,
            codename: this.codename,
            walkstep: this.finaltotal
          }
        })
      }
      this.valid = true
    },
    async answerQuestion(counter){ //new process ran everytime next/submit is pressed
      if(this.Questions[counter].answer.length == 4){
        this.showMsgBox();
        this.counter = this.counter - 1;
        return;
      }
      if (this.Questions[counter].answer[0] === false && this.Questions[counter].answer[1] === false && this.Questions[counter].answer[2] === false && this.Questions[counter].answer[3] === false && this.Questions[counter].answer[4] === false) {
        // this.valid = false
        // this.counter = counter
        // this.showMsgBox(this.Questions[counter].question_text)
        if(counter + 1 != this.Questions.length){
          this.counter = this.counter - 1;
        }
        this.showMsgBox();
      }
      else {
        if(counter == 0){
          WalkResultsServices.submitResults(this.session_id, this.participant_id).then(log => {
            this.newname = log.data.participantInWalk.codename
            this.result_id = log.data.result_id
          })
        }
        if(counter + 1 == this.Questions.length){
          this.score();
          this.submitRes = true;
        }
        this.wait = true;
        await this.sendAnswers(counter);
        this.wait = false;
        this.submitRes = false;
      }
    },
    async getQuestions() {
      this.getQs = true;
      this.wait = true;
      await QuestionListCollectionServices.getQuestionsCollectionFromWalkAndVersion(this.walk_id, this.$route.query.version).then((response) => {
        this.Questions = []
        response.data.forEach(question => this.Questions.push(
            {
              question_text: question.question_id.questionText,
              answer: [false, false, false, false],
              questionID: question.listID,
              step_back: question.backwardScore,
              step_forward: question.forwardScore,
              step_sometimes: question.sometimesScore,
              idk: question.allowIDK,
              sometimes: question.allowSometimes,
              priorityOrder: question.priorityOrder
            }
        ))
      })
      let tmpArray = Array(this.Questions.length - 1);
      this.Questions.forEach(question => {
        tmpArray[question.priorityOrder] = question;
      })
      this.Questions = tmpArray;
      this.wait = false;
      this.getQs = false;
    },
    sendAnswers(counter){ //new process called by answerQuestion to send api calls
      WalkResponseServices.submitResponse(this.Questions[counter].questionID, this.participant_id, this.Questions[counter].answer).then(responess => {
        WalkResultsServices.submitCollection(this.session_id, this.result_id).then(() => {
          WalkResultsServices.submitResponseCollection(this.session_id, this.result_id).then(newlog => {
            WalkResultsServices.submitCollectionWalkResponse(this.result_id, responess.data.response_id, this.session_id).then(() => {
              console.log(this);
              if(counter == 0) {
                this.wrsc_id = newlog.data.wrsc_id
              }
              // WalkResultsServices.updateSteps(newlog.data.wrsc_id, log.data.result_id, this.finaltotal).then(() => {
              // })
              if(this.Questions.length === counter + 1 || this.submitbtn){
                this.submit();
              }
            })
          })
        })
      })
    },
    // Submit
    //   submitResponses() {
    //     WalkResultsServices.submitResults(this.session_id, this.participant_id).then(log => {
    //       this.newname = log.data.participantInWalk.codename
    //       this.Questions.forEach(response => {
    //         WalkResponseServices.submitResponse(response.questionID, this.participant_id, response.answer).then(responess => {
    //           WalkResultsServices.submitCollection(this.session_id, log.data.result_id).then(() => {
    //             WalkResultsServices.submitResponseCollection(this.session_id, log.data.result_id).then(newlog => {
    //               WalkResultsServices.submitCollectionWalkResponse(log.data.result_id, responess.data.response_id, this.session_id).then(() => {
    //                 WalkResultsServices.updateSteps(newlog.data.wrsc_id, log.data.result_id, this.finaltotal).then(() => {
    //                 })
    //               })
    //             })
    //           })
    //         })
    //       })
    //     })
    //     this.$cookie.set(this.room_key + '/' + this.codename + '/done', {expires: '5h'});
    //   }
    // },
    submitResponses() {//new process send final api call with final score and take to landing page
      WalkResultsServices.updateSteps(this.wrsc_id, this.result_id, this.finaltotal)
      this.$cookie.set(this.room_key + '/' + this.codename + '/done', {expires: '5h'});
    },
    windowResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    this.getQuestions()
    if (this.$cookie.get(this.room_key + '/' + this.codename + '/done')) {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style scoped>
.question {
  padding: 1.5rem;
  margin-right: 15%;
  margin-left: 15%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.buttonSelect {
  padding: 1.5rem;
  margin-top: 2%;
  margin-right: 20%;
  margin-left: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.button-next {
  position: fixed;
  font-size: x-large;
  bottom: 30px;
  right: 20px;
}

.button-next-small {
  margin-top: 5%;
  text-align: center;
  font-size: x-large;
  bottom: 30px;
}

.button-name {
  position: fixed;
  font-size: x-large;
  bottom: 30px;
  left: 20px;
}

.loading {
  text-align: center;
  margin-top: 40vh;
}

.small-screen {
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
}
</style>