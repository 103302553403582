import axios from 'axios'

const QUESTION_API_URL = "/api/question/"

class QuestionServices {
    getQuestionList() {
        return axios.get(QUESTION_API_URL + 'all');
    }
    getQuestionListNotHidden(page, size) {
        return axios.get(QUESTION_API_URL + 'all/not_hidden', {
            params: {
                page: page,
                size: size
            }
        });
    }
    getQuestionListByEmail(email) {
        return axios.get(QUESTION_API_URL + 'question/email/' + email);
    }
    getQuestionText(id) {
        return axios.get(QUESTION_API_URL + 'text/' + id);
    }
    postQuestionText(text, user_id) {
        return axios.post(QUESTION_API_URL + 'add/text/' + text + '/' + user_id);
    }

    putChangeDuplicate(isDuplicate, question_id, dupe_question_id) {
        return axios.put(QUESTION_API_URL + 'add/isDuplicate/' + isDuplicate + '/' + question_id + '/' + dupe_question_id);
    }

    getQuestionByID(id) {
        return axios.get(QUESTION_API_URL + id);
    }
}

export default new QuestionServices()