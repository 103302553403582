<template>
  <div>
    <div class="box">
      <b-container fluid>
        <b-row align-v="center" style="margin-bottom: -1rem">
          <b-col>
            <p class="title">Question: {{(questionNum+1)}}</p>
            <p class="room-key">{{ questionText[questionNum]}}</p>
          </b-col>
          <b-col class="text-right">
            <p>
              <b-button style="margin-right: 1rem" variant="outline-secondary" @click="goToPortal()">Back to Portal</b-button>
              <b-button style="margin-right: 1rem" variant="outline-secondary" @click="goToResults()">Back to Results</b-button>
              <b-button variant="outline-primary" @click="reload(count)">Refresh</b-button>
              <b-dropdown id="dropdown-1" style="margin-right: 1rem" dropleft text="Select Question" class="m-md-2">
                <li v-for='index in questionText.length' :key='index'>
                  <b-dropdown-item @click="count = (index-1)*4; updateGraph(count);questionNum = index-1">{{questionText[index-1]}}</b-dropdown-item>
                </li>
              </b-dropdown>
            </p>
          </b-col>
        </b-row>
        <!--        <b-row>-->
        <!--          <b-form-group v-slot="{ ariaDescribedby }" style="margin-left: 1rem;">-->
        <!--            <b-form-radio-group-->
        <!--                v-model="selected"-->
        <!--                :aria-describedby="ariaDescribedby"-->
        <!--                :options="options"-->
        <!--                name="plain-inline"-->
        <!--                plain-->
        <!--                @change="changeGraph"-->
        <!--            ></b-form-radio-group>-->
        <!--          </b-form-group>-->
        <!--        </b-row>-->
      </b-container>

      <!--      <p class="title">{{ WalkTitle }}</p>-->
      <!--      <div class="button">-->
      <!--        <div class="d-flex align-items-start">-->
      <!--          <b-button variant="outline-primary" @click="reload()" style="margin-right: 1rem">Refresh</b-button>-->
      <!--          <b-button variant="outline-secondary" @click="goToPortal()">Back to Portal</b-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div><p class="room-key">Room Code: {{ roomKey }}</p></div>-->
      <!--      <p></p>-->
    </div>
    <div class="button-next">
      <b-button variant="success" @click="count+=5;updateGraph(count);questionNum+=1">Next Question</b-button>
    </div>
    <div class="button-name">
      <div v-if="count!== 0" style="float:left">
        <b-button variant="success" @click="count-=5;updateGraph(count);questionNum-=1">Previous Question</b-button>
      </div>
    </div>
    <p></p>
    <b-overlay :show="isLoading" rounded="sm">
      <BarChart :chart-data="datacollection" :chart-options="dataoptions"
                style="margin-left: 3%; margin-right: 3%; height: 60vh"></BarChart>
    </b-overlay>
  </div>
</template>

<script>
import WalkResultsServices from "@/services/WalkResultsServices";
import SessionServices from "@/services/SessionServices";
import BarChart from "@/services/BarChartPerQuestion";
import QuestionListCollectionServices from "@/services/QuestionListCollectionServices";

export default {
  name: "responsesPerQuestion",
  components:{
    BarChart
  },
  data() {
    return {
      id: this.$route.query.id,
      roomKey: this.$route.query.roomKey,
      datacollection: null,
      dataoptions: null,
      currentQuestionCount: [],
      count: 0,
      sessionID: this.$route.query.sessionID,
      questionResponses: [],
      questionNum: 0,
      questionText: [],
      walkID: null,
      isLoading:false,
    }
  },
  methods: {
    goToPortal() {
      this.$router.push({path: 'portal', query: {id: this.id}})
    },
    reload(count){
      this.isLoading=true;
      this.getAllQuestionCounts();
      this.updateGraph(count);
      this.isLoading=false;
    },
    goToResults() {
      this.$router.push({path: 'results', query: {roomKey: this.roomKey, id: this.id}})
    },
    async updateGraph(count) {
      this.isLoading = true;
      if(count > this.questionResponses.length-4) {
        count = 0;
        this.count = 0;
        this.questionNum = -1;
      }
      this.currentQuestionCount[0] = this.questionResponses[count];
      this.currentQuestionCount[1] = this.questionResponses[count+1];
      this.currentQuestionCount[2] = this.questionResponses[count+2];
      this.currentQuestionCount[3] = this.questionResponses[count+3];
      this.currentQuestionCount[4] = this.questionResponses[count+4];
      this.datacollection = {
        labels: ['Yes', 'No', 'Sometimes', 'I Dont Know', 'Prefer Not to Answer'],
        datasets: [
          {
            label: 'Number of Responses',
            backgroundColor: "#73be73",
            data: this.currentQuestionCount
          }
        ]
      };
      this.isLoading = false;
      this.dataoptions = {}
    },
    async getAllQuestionCounts(){
      this.isLoading=true;
      await WalkResultsServices.getScoresPerQuestion(this.sessionID).then(resp => {
        this.questionResponses = resp.data;
      })
      // for(let i = 0; i < 4; i++){
      //   this.currentQuestionCount[i] = this.questionResponses[i];
      // }
      await this.updateGraph(this.count);
      this.isLoading=false;
    },
    async getQuestionText(){

      await SessionServices.getWalk(this.sessionID).then((resp) => {
        QuestionListCollectionServices.getQuestionsCollectionFromWalkAndVersion(resp.data.walk_id, resp.data.currentVersion).then(res => {
          res.data.forEach(q => {
            this.questionText.push(q.question_id.questionText);
          })
        })
      })
    }

  },
  beforeMount() {
    this.getAllQuestionCounts();
    this.getQuestionText();
  }
}

</script>

<style scoped>
.box {
  margin-top: 85px;
  padding: 5px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.title {
  /*float: left;*/
  margin-bottom: -0.5rem;
  font-size: 30px;
  font-weight: 500;
}

.room-key {
  font-size: larger;
  /*float: left;*/
  /*margin-top: 0.7rem;*/
  /*margin-bottom: -1px;*/
}

.button-next {
  position: fixed;
  font-size: x-large;
  bottom: 30px;
  right: 20px;
}

.button-name {
  position: fixed;
  font-size: x-large;
  bottom: 30px;
  left: 20px;
}
</style>