<template>
  <div style="margin-top: 80px">
    <div>
      <div v-if="title">
        <div class="d-flex align-items-center name">
          <p><b>Walk Title:</b> {{ title_name }}</p>
        </div>
      </div>
      <div v-else>
        <div class="title">
          <div class="name">
            <p><b>Walk Title:</b></p>
          </div>
          <div class="input">
            <b-form @submit.stop.prevent>
              <b-form-input v-model="title_name" :state="validation"></b-form-input>
              <b-form-invalid-feedback :state="validation">
                Title must be 5-100 characters long. (No special characters)
              </b-form-invalid-feedback>
            </b-form>
          </div>
          <div class="button">
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-dropdown id="dropdown-1" :text="'Status: '+ status_text" class="m-md-2">
        <b-dropdown-item @click="setWalkStatus('Created')">Created</b-dropdown-item>
        <b-dropdown-item @click="setWalkStatus('Open or Collecting Responses')">Open or Collecting Responses
        </b-dropdown-item>
        <b-dropdown-item @click="setWalkStatus('Closed')">Closed</b-dropdown-item>
        <b-dropdown-item @click="setWalkStatus('Archived')">Archived</b-dropdown-item>
      </b-dropdown>
      <b-col class="text-right" style="margin-left: -1.5rem;">
        <b-button style="margin-right: 0.5rem" variant="outline-success"
                  @click="detectEmptyWalk() ? finalized ? detectChange() ? $refs['modal-2'].show() : toPortal() : $refs['modal-save'].show() : toPortal()">
          Save Changes
        </b-button>
        <b-button variant="outline-dark" @click="detectChange() ? $refs['modal-portal'].show() : toPortal()">
          Portal
        </b-button>
      </b-col>
    </div>
    <div v-if="version > 0">
      <b-dropdown id="dropdown-1" :text="version_text" class="m-md-2">
        <b-dropdown-item v-for="index in version+1" :key="index" @click="getVersion(walk_id, index-1)">Version {{
            index
          }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <table class="table" style="background-color: whitesmoke; margin-top: 1%">
      <thead class="thead">
      <tr style="font-size: large; font-weight: bold">
        <td>#</td>
        <td style="width: 70%;">Questions</td>
        <td></td>
        <td></td>
        <td>Sometimes Response</td>
        <td>Yes Response</td>
        <td>No Response</td>
        <td>Allow IDK</td>
        <td>Allow Sometimes</td>
      </tr>
      </thead>
      <draggable v-model="array" ghost-class="ghost" tag="tbody">
        <tr v-for="item in array" :key="item.question_text">
          <td>
            <div><b>{{array.indexOf(item) + 1}}</b></div>
          </td>
          <td>
            <div>
              {{ item.question_text }}
            </div>
            <div>
              <span v-for="tag in item.tags" :key="tag">
                <b-badge style="margin-left: 0.2rem" variant="info">{{ tag }}</b-badge>
              </span>
              <span v-if="!item.questionID">
                <span v-for="tag in value" :key="tag">
                  <b-badge style="margin-left: 0.2rem" variant="info">{{ tag }}</b-badge>
                </span>
              </span>
            </div>
          </td>
          <td>
            <div>
              <b-button size="sm" @click="editQuestion(item)">
                Edit
              </b-button>
            </div>
          </td>
          <td>
            <div>
              <b-button size="sm" @click="compilePW(item)">
                Remove
              </b-button>
            </div>
          </td>
          <td>
            <div>
              <b-form-spinbutton id="demo-sb" v-model="item.step_sometimes" max="10" min="-10"></b-form-spinbutton>
            </div>
          </td>
          <td>
            <div>
              <b-form-spinbutton id="demo-sb" v-model="item.step_forward" max="10" min="-10"></b-form-spinbutton>
            </div>
          </td>
          <td>
            <div>
              <b-form-spinbutton id="demo-sb" v-model="item.step_back" max="10" min="-10"></b-form-spinbutton>
            </div>
          </td>
          <td>
            <div style="text-align: center">
              <b-form-checkbox
                  v-model="item.idk"
                  name="checkbox-1"
                  value="true"
              >
              </b-form-checkbox>
            </div>
          </td>
          <td>
            <div style="text-align: center">
              <b-form-checkbox
                  v-model="item.sometimes"
                  name="checkbox-2"
                  value="true"
              >
              </b-form-checkbox>
            </div>
          </td>
        </tr>
      </draggable>
    </table>
    <p style="margin-left: 1%; margin-top: -0.4rem; font-size: medium"><i>Click and drag to change order</i></p>

    <b-container fluid>
      <b-row>
        <b-col cols="7">
          <div style="display: flex">
            <div class="q-name" style="flex: 1">
              <p>Question:</p>
            </div>
            <div style="flex: 10">
              <b-form-group
                  id="fieldset-1"
                  :invalid-feedback="invalidFeedback"
                  :state="questionLength"
                  description="Make Yes or No Questions."
                  label-for="input-1"
              >
                <div v-if="edit_question">
                  <b-form-input id="input-1" v-model="question_text" :state="questionLength"
                                v-on:keyup.enter="updateQuestion()"></b-form-input>
                </div>
                <div v-else>
                  <b-form-input id="input-1" v-model="question_text" :state="questionLength"
                                v-on:keyup.enter="submitQuestion()"></b-form-input>
                </div>
              </b-form-group>

            </div>
          </div>
        </b-col>
        <b-col>
          <div v-if="edit_question">
            <b-button size="md" variant="success" @click="updateQuestion()">Update</b-button>
          </div>
          <div v-else>
            <b-button size="md" variant="success" @click="submitQuestion()">Add</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row style="margin-right: 1rem; margin-left: 1rem">
        <b-col>
          <b-button variant="outline-dark" @click="toQuestionRepo()">Add from Question
            List
          </b-button>
        </b-col>
        <b-col class="text-right">
          <!--          <b-button v-if="edit && finalized" variant="outline-success" @click="detectChange() ? $refs['modal-2'].show() : toPortal()">Save Changes-->
          <!--          </b-button>-->
          <!--          <b-button v-else variant="outline-success" @click="detectChange() ? $refs['modal-save'].show() : toPortal()">Save Changes-->
          <!--          </b-button>-->

          <!--     If the walk is empty go to portal, else if it is not finalized ask them to save, finalize or don't save,   -->
          <!--        else if detect they made a change and if no change go to portal, else ask them to save (auto version), new walk, or don't save -->
          <b-button style="margin-right: 0.5rem" variant="outline-success"
                    @click="detectEmptyWalk() ? finalized ? detectChange() ? $refs['modal-2'].show() : toPortal() : $refs['modal-save'].show() : toPortal()">
            Save Changes
          </b-button>
          <b-button variant="outline-dark" @click="detectChange() ? $refs['modal-portal'].show() : toPortal()">Portal
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-modal ref="modal" hide-footer title="Walk Title">
      You already have a walk with that title.
    </b-modal>
    <b-modal ref="modal-title" hide-footer title="Walk Title">
      Title must be 5-100 characters long.
    </b-modal>
    <b-modal ref="modal-err" hide-footer title="Can't Edit" @close="toPortal()">
      Can't edit a previously ran walk.
    </b-modal>
    <b-modal id="modal-2" ref="modal-2" hide-footer title="Save Walk">
      <p align="center">How do you want to save this walk?</p>
      <span v-if="!wait_save">
        <b-button block pill variant="primary" @click="editWalk(true, false)">Save</b-button>
        <b-button block pill variant="success" @click="SaveList(false, true)">New Walk</b-button>
        <b-button block pill variant="secondary" @click="$bvToast.show('dont-save')">Don't Save
        </b-button>
        <b-toast id="dont-save" no-auto-hide static style="display: flex; margin-left: 13%" title="Don't Saves">
          WARNING, The new changes made to this walk will not be saved. Are you sure you want to proceed?
          <br>
          <b-button size="sm" style="float: right; margin-bottom: 0.3rem" variant="success" @click="toPortal()">Yes
          </b-button>
        </b-toast>
      </span>
      <div v-if="wait_save" style="justify-content: center; text-align: center;">
        <b-spinner label="Spinning" type="grow"></b-spinner>
        <b-spinner label="Spinning" type="grow"></b-spinner>
        <b-spinner label="Spinning" type="grow"></b-spinner>
      </div>
    </b-modal>
    <b-modal ref="modal-save" hide-footer title="Save Walk">
      Would you like to save and continue or finalize and begin hosting your walk.
      <p></p>
      <span v-if="!wait_save">
        <b-button block pill variant="success" @click="SaveList(false, false)">Save
        </b-button>
        <b-button block pill variant="primary" @click="SaveList(true, false)">Finalize Walk
        </b-button>
        <b-button block pill @click="toPortal()">Don't Save</b-button>
      </span>
      <div v-if="wait_save" style="justify-content: center; text-align: center;">
        <b-spinner label="Spinning" type="grow"></b-spinner>
        <b-spinner label="Spinning" type="grow"></b-spinner>
        <b-spinner label="Spinning" type="grow"></b-spinner>
      </div>
    </b-modal>
    <b-modal ref="modal-portal" id="modal-portal" hide-footer hide-header-close title="Warning">
      The new changes made to this walk will not be saved. Are you sure you want to proceed?
      <p></p>
      <b-button block pill @click="toPortal()">Yes, Don't Save
      </b-button>
      <b-button block pill variant="danger" @click="$bvModal.hide('modal-portal')">Cancel
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import QuestionServices from "@/services/QuestionServices";
import PrivilegeWalkServices from "@/services/PrivilegeWalkServices";
import QuestionListCollectionServices from "@/services/QuestionListCollectionServices";
import TagServices from "@/services/TagServices";
import {mapActions, mapState} from "vuex";
// import Profanity from "profanity-util";
import FacilitatorStoreIndex from "@/store/facilitator-portal/_StoreIndex"
import QuestionSearchStoreIndex from "@/store/question-search/_StoreIndex"
import {StoreActions as FacilitatorStoreAction} from "@/store/facilitator-portal/actions";
import {StoreActions as QuestionSearchStoreAction} from "@/store/question-search/actions";
import {StoreState as FacilitatorState} from "../store/facilitator-portal/state";

export default {
  data() {
    return {
      array: [],
      question_array: [],
      question_ids: [],
      title: false,
      title_name: '',
      edit: false,
      valid: false,
      walk_id: null,
      question_text: '',
      tags: [],
      tag_text: '',
      status_text: '',
      finalized: false,
      version: 0,
      active_version: 0,
      version_text: "",
      options: [],
      search: '',
      value: [],
      restrictPopUp: true,
      toQList: false,
      wait_save: false,
      max: 0,
      min: 0,
      edit_question: false,
      edit_question_array: []
    }
  },
  components: {
    draggable
  },
  computed: {
    ...mapState(FacilitatorStoreIndex.storeName, {
      id: FacilitatorState.id
    }),
    validation() {
      //let characters = /^[\w -!%,./(/)]{5,100}$/;
      let characters = /^[A-Za-z0-9 ]+$/;
      return characters.test(this.title_name);
    },
    invalidFeedback() {
      // let character = /^[\w <>+-=!$%,.?"'/(/)]*$/;
      let spaces = /\s{3,}/;
      let word_array = this.question_text.split(" ");
      let last_char = this.question_text[this.question_text.length - 1];
      if (word_array[word_array.length - 1] === '') {
        word_array.pop();
      }
      // if(Profanity.check(this.question_text).length !== 0){
      //   return 'No profanity.';
      // }else
      if (spaces.test(this.question_text)) {
        return 'Three or more spaces detected.';
      } else if (this.question_text.indexOf("|") !== -1) {
        return 'No special characters.';
      } else if (this.question_text.length > 150) {
        return 'Too long.';
      } else if (word_array.length >= 2 && (last_char !== '.' || last_char !== '!' || last_char !== '?')) {
        return 'Needs punctuation.';
      } else if (word_array.length < 2) {
        return 'Need at least 2 words.';
      }
      return '';
    },
    questionLength() {
      // let character = /^[\w <>+-=!$%,.?"'/(/)]*$/;
      let spaces = /\s{3,}/;
      let word_array = this.question_text.split(" ");
      let last_char = this.question_text[this.question_text.length - 1];
      if (word_array[word_array.length - 1] === '') {
        word_array.pop();
      }
      return this.question_text.indexOf("|") === -1 && !(spaces.test(this.question_text)) && word_array.length > 1 && this.question_text.length < 150 && (word_array.length > 1 && last_char === '.' || last_char === '!' || last_char === '?');
    },
    criteria() {
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      const options = this.options.filter(opt => this.value.indexOf(opt) === -1);
      if (criteria) {
        return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
      }
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria';
      }
      return '';
    }
  },
  methods: {
    ...mapActions(FacilitatorStoreIndex.storeName, {
      checkLogin: FacilitatorStoreAction.checkSession
    }),
    ...mapActions(QuestionSearchStoreIndex.storeName, {
      getAllQuestions: QuestionSearchStoreAction.getAllQuestionsFromWalk
    }),
    onOptionClick({option, addTag}) {
      addTag(option)
      this.search = ''
    },
    detectEmptyWalk() {
      let change = true;
      let array = localStorage.getItem('array') || '[]';
      let array2 = JSON.stringify(this.array) || '[]';
      let walkStat = localStorage.getItem('status_text') || '';
      let walkName = localStorage.getItem('title_name') || '';
      if ((array === array2 && array === '[]') && (this.status_text === walkStat && walkStat === '') && (this.title_name === walkName && walkName === '')) {
        change = false;
      }
      return change;
    },
    detectChange() {
      let change = true;
      let array = localStorage.getItem('array') || '[]';
      let array2 = JSON.stringify(this.array) || '[]';
      let walkStat = localStorage.getItem('status_text') || '';
      let walkName = localStorage.getItem('title_name') || '';

      if (array === array2 && this.status_text === walkStat && this.title_name === walkName) {
        change = false;
      } else if (array === null && array2 === "[]" && walkStat === null && this.status_text === " " && this.title_name === '' && walkName === null) {
        change = false;
      }

      return change;
    },
    async editWalk(change, finalize) {
      try {
        this.restrictPopUp = false;
        this.wait_save = true;
        if (!(this.title_name.length < 100 && this.title_name.length >= 5)) {
          this.$refs['modal-title'].show();
          return;
        }
        let version = 0;
        if (change) {
          await PrivilegeWalkServices.changeWalkVersion(this.$route.query.walkID);
        }
        if (finalize) {
          await PrivilegeWalkServices.setWalkFinalized(this.$route.query.walkID);
        }
        if (this.status_text === '') {
          this.status_text = ' '
        }
        await PrivilegeWalkServices.setWalkStatus(this.$route.query.walkID, this.status_text);
        await PrivilegeWalkServices.changeWalkTitle(this.$route.query.walkID, this.title_name).then(response => {
          version = response.data.currentVersion;
        });
        await PrivilegeWalkServices.changeModifiedAt(this.$route.query.walkID);

        for (let i = 0; i < this.question_array.length; i++) {
          let tmp = this.question_array[i];
          this.question_array[i] = this.question_array[i].replace(/\//g, '--FS--');
          this.question_array[i] = this.question_array[i].replace(/%/g, '--PCT--');
          this.question_array[i] = this.question_array[i].replace(/\\/g, '--BS--');
          this.question_array[i] = this.question_array[i].replace(/;/g, '--SC--');
          await QuestionServices.postQuestionText(encodeURIComponent(this.question_array[i]), this.id).then(async response1 => {
            let yes = 0;
            let no = 0;
            let some = 0;
            let idk = true;
            let sometimes = false;
            let order = 0;

            /*
            for (let j = 0; j < this.array.length; j++) {
              if (this.array[j].question_text === tmp) {

                if(this.array[j].step_forward > this.array[j].step_back) {  //"Yes" step weight is the largest
                  yes = this.array[j].step_forward;
                  no = this.array[j].step_back;
                }
                else {   //"No" step weight is the largest OR all step weights are equal
                  yes = this.array[j].step_back;
                  no = this.array[j].step_forward;
                }
                //yes = this.array[j].step_forward;
                //no = this.array[j].step_back;
                idk = this.array[j].idk;
                sometimes = this.array[j].sometimes;
                order = j;
                break;
              }
            }
             */

            for (let j = 0; j < this.array.length; j++) {
              if (this.array[j].question_text === tmp) {
                yes = this.array[j].step_forward;
                no = this.array[j].step_back;
                some = this.array[j].step_sometimes;
                idk = this.array[j].idk;
                sometimes = this.array[j].sometimes
                order = j;
                break;
              }
            }

            // this.array.forEach(question => {
            //   if (question.question_text === tmp) {
            //     yes = question.step_forward;
            //     no = question.step_back;
            //     idk = question.idk;
            //   }
            // });

            await QuestionListCollectionServices.createCollectionWeight(this.$route.query.walkID, response1.data, version, yes, no, idk, order, some, sometimes);
            await QuestionListCollectionServices.createSometimesWeight(this.$route.query.walkID, response1.data, version, some, sometimes);

          })
        }
        if (this.array.length !== 0) {
          let yes = 1;
          let no = 1;
          for (let i = 0; i < this.array.length; i++) {
            if (this.array[i].questionID !== undefined) {
              await QuestionListCollectionServices.createCollectionWeight(this.$route.query.walkID, this.array[i].questionID, version, this.array[i].step_forward, this.array[i].step_back, this.array[i].idk, i);
              await QuestionListCollectionServices.createSometimesWeight(this.$route.query.walkID, this.array[i].questionID, version, this.array[i].step_sometimes, this.array[i].sometimes);

              /*
              for(let j = 0; j < this.question_array.length; j++) {
                if (this.array[i] === this.question_array[j]){
                  if (this.array[i].step_forward > this.array[i].step_back) {  // "Yes" response has a higher step weight
                    yes += this.array[i].step_forward;
                    no += this.array[i].step_back;
                  } else {  // "No" response has a higher step weight / All responses have the same step weight
                    yes += this.array[i].step_back;
                    no += this.array[i].step_forward;
                  }

                  this.max += yes;
                  this.min += no;
                }
              }

              */
              if(this.array[i].sometimes) {
                if(this.array[i].step_forward > this.array[i].step_back && this.array[i].step_forward > this.array[i].step_sometimes) {
                  yes = this.array[i].step_forward;
                  if(this.array[i].step_back > this.array[i].step_sometimes) {
                    no = this.array[i].step_sometimes;
                  }
                  else {
                    no = this.array[i].step_back;
                  }
                }
                else if(this.array[i].step_back > this.array[i].step_forward && this.array[i].step_back > this.array[i].step_sometimes) {
                  yes = this.array[i].step_back;
                  if(this.array[i].step_forward > this.array[i].step_sometimes) {
                    no = this.array[i].step_sometimes;
                  }
                  else {
                    no = this.array[i].step_forward;
                  }
                }
                else {
                  yes = this.array[i].step_sometimes;
                  if(this.array[i].step_forward > this.array[i].step_back) {
                    no = this.array[i].step_back;
                  }
                  else {
                    no = this.array[i].step_forward;
                  }
                }
              }
              else {
                if (this.array[i].step_forward > this.array[i].step_back) {  // "Yes" response has a higher step weight
                  yes = this.array[i].step_forward;
                  no = this.array[i].step_back;
                } else {  // "No" response has a higher step weight / All responses have the same step weight
                  yes = this.array[i].step_back;
                  no = this.array[i].step_forward;
                }
              }

              this.max += yes;
              this.min += no;

              //this.max += this.array[i].step_forward;
              //this.min += this.array[i].step_back;
            }
          }
          await PrivilegeWalkServices.setMaxMin(this.walk_id, this.max, this.min);
        }
        this.$cookie.delete('title');
        await PrivilegeWalkServices.setEditStatus(this.$route.query.walkID, false);
        await this.$router.push({path: 'portal', query: {id: this.id}});
      }
      catch (error) {
        this.wait_save = false;
      }
    },
    submit(valid) {
      if (valid) {
        this.title = true;
      }
    },
    toQuestionRepo() {
      this.toQList = true;
      this.$cookie.set('title', this.title_name, 1);
      if (this.array.length > 0) {
        localStorage.setItem('array', JSON.stringify(this.array));
      }
      if (this.walk_id) {
        this.$router.push({path: 'questionSearch', query: {ID: this.id, walkID: this.walk_id, edit: true}});
      } else {
        this.$router.push({path: 'questionSearch', query: {ID: this.id}});
      }
    },
    submitQuestion() {
      // let character = /^[\w <>+-=!$%,.?"'/(/)]*$/;
      let spaces = /\s{3,}/;
      let last_char = this.question_text[this.question_text.length - 1];
      let word_array = this.question_text.split(" ");
      if (word_array[word_array.length - 1] === '') {
        word_array.pop();
      }
      if (this.question_text.indexOf("|") === -1 && !(spaces.test(this.question_text)) && word_array.length > 1 && this.question_text.length < 150 && (word_array.length > 1 && last_char === '.' || last_char === '!' || last_char === '?')) {
        if (this.question_text[this.question_text.length - 1] === ' ' && this.question_text[this.question_text.length - 2] === ' ') {
          this.question_text = this.question_text.slice(0, -1);
          this.question_text = this.question_text.slice(0, -1);
        } else if (this.question_text[this.question_text.length - 1] === ' ') {
          this.question_text = this.question_text.slice(0, -1);
        }
        this.array.push({'question_text': this.question_text, 'step_back': -1, 'step_forward': 1, 'step_sometimes': 0, 'idk': true, 'sometimes': false});
        this.question_array.push(this.question_text);
        this.question_text = '';
      }
    },
    updateQuestion() {
      // let character = /^[\w <>+-=!$%,.?"'/(/)]*$/;
      let spaces = /\s{3,}/;
      let last_char = this.question_text[this.question_text.length - 1];
      let word_array = this.question_text.split(" ");
      if (word_array[word_array.length - 1] === '') {
        word_array.pop();
      }
      if (this.question_text.indexOf("|") === -1 && !(spaces.test(this.question_text)) && word_array.length > 1 && this.question_text.length < 150 && (word_array.length > 1 && last_char === '.' || last_char === '!' || last_char === '?')) {
        if (this.question_text[this.question_text.length - 1] === ' ' && this.question_text[this.question_text.length - 2] === ' ') {
          this.question_text = this.question_text.slice(0, -1);
          this.question_text = this.question_text.slice(0, -1);
        } else if (this.question_text[this.question_text.length - 1] === ' ') {
          this.question_text = this.question_text.slice(0, -1);
        }
        this.array.splice(this.edit_question_array[0].index,0,{'question_text': this.question_text, 'step_back': this.edit_question_array[0].step_back, 'step_forward': this.edit_question_array[0].step_forward, 'idk': this.edit_question_array[0].idk});
        this.question_array.push(this.question_text);
        this.question_text = '';

        this.edit_question_array.splice(0,1);
        this.edit_question = false;
      }
    },
    async editQuestion(item) {
      this.edit_question = true;

      let api_question_array = [];
      if (this.walk_id != null) {
        await QuestionListCollectionServices.getQuestionsCollectionFromWalk(this.walk_id).then((response) => {
          response.data.forEach(question => api_question_array.push(question.question_id.questionText))
        });
      }
      if (this.question_array !== api_question_array) {
        const uniqueQuestions = this.question_array.filter(q => !api_question_array.includes(q));
        this.question_array = api_question_array.concat(uniqueQuestions);
      }

      const index = this.array.indexOf(item);
      const question_index = this.question_array.indexOf(item.question_text);
      const step_forward = this.array[index].step_forward;
      const step_back = this.array[index].step_back;
      const step_sometimes = this.array[index].step_sometimes
      const idk = this.array[index].idk;

      this.array.splice(index, 1);
      this.question_text = this.question_array[question_index];
      this.question_array.splice(question_index, 1);

      this.edit_question_array.push({'questions_text': this.question_text, 'step_back': step_back, 'step_forward': step_forward, 'step_sometimes': step_sometimes, 'idk': idk, 'index': index});
    },
    compilePW(item) {
      const index = this.array.indexOf(item);
      this.array.splice(index, 1);
    },
    async postQuestionArray() {
      for (let i = 0; i < this.question_array.length; i++) {
        let tmpString = this.question_array[i];
        tmpString = tmpString.replace(/\//g, '--FS--');
        tmpString = tmpString.replace(/%/g, '--PCT--');
        tmpString = tmpString.replace(/\\/g, '--BS--');
        tmpString = tmpString.replace(/;/g, '--SC--');
        await QuestionServices.postQuestionText(encodeURIComponent(tmpString), this.id).then((response) => {
          this.question_ids.push(response.data)
        })
      }
    },
    createWalk(finalize) {
      this.restrictPopUp = false;
      if (!(this.title_name.length < 100 && this.title_name.length >= 5)) {
        this.$refs['modal-title'].show()
        return;
      }
      PrivilegeWalkServices.createWalk(this.title_name, this.id).then(async (response) => {
        if (finalize) {
          await PrivilegeWalkServices.setWalkFinalized(response.data.walk_id);
        }
        if (response.data === "") {
          this.$refs['modal'].show()
          return;
        }

        this.walk_id = response.data.walk_id;


        for (let i = 0; i < this.question_array.length; i++) {
          let yes = 1;
          let no = 1;
          let some = 0;
          let idk = true;
          let sometimes = false;
          let order = 0;
          for (let j = 0; j < this.array.length; j++) {
            if (this.array[j].question_text === this.question_array[i]) {
              if(this.array[j].sometimes) {
                if(this.array[j].step_forward > this.array[j].step_back && this.array[j].step_forward > this.array[j].step_sometimes) {
                  yes = this.array[j].step_forward;
                  if(this.array[j].step_back > this.array[j].step_sometimes) {
                    no = this.array[j].step_sometimes;
                  }
                  else {
                    no = this.array[j].step_back;
                  }
                }
                else if(this.array[j].step_back > this.array[j].step_forward && this.array[j].step_back > this.array[j].step_sometimes) {
                  yes = this.array[j].step_back;
                  if(this.array[j].step_forward > this.array[j].step_sometimes) {
                    no = this.array[j].step_sometimes;
                  }
                  else {
                    no = this.array[j].step_forward;
                  }
                }
                else {
                  yes = this.array[j].step_sometimes;
                  if(this.array[j].step_forward > this.array[j].step_back) {
                    no = this.array[j].step_back;
                  }
                  else {
                    no = this.array[j].step_forward;
                  }
                }
              }
              else {
                if(this.array[j].step_forward > this.array[j].step_back) {  // "Yes" response has a higher step weight
                  yes = this.array[j].step_forward;
                  no = this.array[j].step_back;
                }
                else {  // "No" response has a higher step weight / All responses have the same step weight
                  yes = this.array[j].step_back;
                  no = this.array[j].step_forward;
                }
              }

              this.max += yes;
              this.min += no;
              idk = this.array[j].idk;
              sometimes = this.array[j].sometimes
              order = j;

              yes = this.array[j].step_forward;
              no = this.array[j].step_back;
              some = this.array[j].step_sometimes;
              break;

              //yes = this.array[j].step_forward;
              //this.max += yes;
              //no = this.array[j].step_back;
              //this.min += no;
              //idk = this.array[j].idk;
              //order = j;
              //break;
            }
          }

          await QuestionListCollectionServices.createCollectionWeight(this.walk_id, this.question_ids[i], response.data.currentVersion, yes, no, idk, order, some, sometimes);
          await QuestionListCollectionServices.createSometimesWeight(this.walk_id, this.question_ids[i], response.data.currentVersion, some, sometimes);

          await PrivilegeWalkServices.setMaxMin(this.walk_id, this.max, this.min);

        }
        if (this.array.length !== 0) {
          for (let i = 0; i < this.array.length; i++) {
            if (this.array[i].questionID !== undefined) {
              await QuestionListCollectionServices.createCollectionWeight(this.walk_id, this.array[i].questionID, response.data.currentVersion, this.array[i].step_forward, this.array[i].step_back, this.array[i].idk, i);
              await QuestionListCollectionServices.createSometimesWeight(this.walk_id, this.array[i].questionID, response.data.currentVersion, this.array[i].step_sometimes, this.array[i].sometimes);
            }
          }
        }
        if (this.status_text === '') {
          this.status_text = ' '
        }
        await PrivilegeWalkServices.setWalkStatus(response.data.walk_id, this.status_text);

        this.$cookie.delete('title');
        if (this.$route.query.walkID) {
          await PrivilegeWalkServices.setEditStatus(this.$route.query.walkID, false);
        }
        await this.$router.push({path: 'portal', query: {id: this.id}});
      })
    },
    async SaveList(finalize, newWalk) {
      try {
        this.wait_save = true
        if (finalize && this.$route.query.walkID) {
          // PrivilegeWalkServices.setWalkFinalized(this.$route.query.walkID);
          await this.editWalk(false, true);
        } else if (this.$route.query.walkID && !newWalk) {
          await this.editWalk(false, false);
        } else if (finalize) {
          await this.postQuestionArray()
          await this.createWalk(true)
        } else {
          await this.postQuestionArray()
          await this.createWalk(false)
        }
      }
      catch (error) {
        this.wait_save = false;
      }
    },
    async getQuestionsEdit(walk_id) {
      await PrivilegeWalkServices.findWalkByID(walk_id).then((response) => {
        this.title_name = response.data.pw_name;
        this.status_text = response.data.walkStatus;
        this.version = response.data.currentVersion;
        this.active_version = response.data.currentVersion;
        this.version_text = "Version: " + (this.active_version + 1);
        this.finalized = response.data.finalized;
        localStorage.setItem('title_name', this.title_name);
        localStorage.setItem('status_text', this.status_text);
      })
      if (!localStorage.getItem('array')) {
        await QuestionListCollectionServices.getQuestionsFromWalkVersion(walk_id, this.version).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            let tag_arr = []
            response.data[i].question_id.tags.forEach(tag => {
              if (!this.value.includes(tag.name)) {
                this.value.push(tag.name)
              }
              tag_arr.push(tag.name)
            })
            this.array.push(
                {
                  question_text: response.data[i].question_id.questionText,
                  questionID: response.data[i].question_id.question_id,
                  tags: tag_arr,
                  step_back: response.data[i].backwardScore,
                  step_forward: response.data[i].forwardScore,
                  step_sometimes: response.data[i].sometimesScore,
                  idk: response.data[i].allowIDK,
                  sometimes: response.data[i].allowSometimes,
                  priorityOrder: response.data[i].priorityOrder
                }
            )
          }

          this.sortArray();

          localStorage.setItem('array', JSON.stringify(this.array));
        })
      } else {
        await QuestionListCollectionServices.getQuestionsFromWalkVersion(walk_id, this.version).then((response) => {
          if (response.data[0].tags) {
            response.data[0].tags.forEach(tag => {
              this.value.push(tag.name)
            })
          }
        })
      }
    },
    toPortal() {
      this.restrictPopUp = false;
      this.$cookie.delete('title');
      if (this.$route.query.walkID) {
        PrivilegeWalkServices.setEditStatus(this.$route.query.walkID, false);
      }
      this.$router.push({path: 'portal', query: {id: this.id}});
    },
    async getVersion(walk_id, version) {
      this.array = [];
      await QuestionListCollectionServices.getQuestionsFromWalkVersion(walk_id, version).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          let tag_arr = []
          response.data[i].question_id.tags.forEach(tag => {
            if (!this.value.includes(tag.name)) {
              this.value.push(tag.name)
            }
            tag_arr.push(tag.name)
          })
          this.array.push(
              {
                question_text: response.data[i].question_id.questionText,
                questionID: response.data[i].question_id.question_id,
                tags: tag_arr,
                step_back: response.data[i].backwardScore,
                step_forward: response.data[i].forwardScore,
                step_sometimes: response.data[i].sometimesScore,
                idk: response.data[i].allowIDK,
                sometimes: response.data[i].allowSometimes,
                priorityOrder: response.data[i].priorityOrder
              }
          )
        }

        this.sortArray();

      })
      this.active_version = version;
      this.version_text = "Version: " + (this.active_version + 1);
    },
    setWalkStatus(walkstatus) {
      this.status_text = walkstatus;
    },
    sortArray() {
      let tmpArray = Array(this.array.length - 1);
      this.array.forEach(question => {
        tmpArray[question.priorityOrder] = question;
      })
      this.array = tmpArray;
    },
    async handler() {
      // window.alert("Wait")
      await PrivilegeWalkServices.setEditStatus(this.walk_id, false);
    }
  },
  created() {
    TagServices.getTags().then(response => {
      this.options = response.data;
    })
    if (this.$route.query.qarray && localStorage.getItem('array')) {
      if (this.$route.query.qarray[0] === "[object Object]" || this.$route.query.qarray === "[object Object]") {
        this.array = JSON.parse(localStorage.getItem('array'));
      } else {
        this.array = this.$route.query.qarray;
        let tmp = JSON.parse(localStorage.getItem('array'));
        this.array = tmp.concat(this.array);
        localStorage.setItem('array', JSON.stringify(this.array));
      }
    } else if (this.$route.query.qarray) {
      this.array = this.$route.query.qarray;
      localStorage.setItem('array', JSON.stringify(this.array));
    } else if (localStorage.getItem('array')) {
      this.array = JSON.parse(localStorage.getItem('array'));
    }

    if (this.$route.query.walkID) {
      this.walk_id = this.$route.query.walkID;
      this.getQuestionsEdit(this.$route.query.walkID);
      this.edit = this.$route.query.edit;
    }

    if ((this.title_name = this.$cookie.get('title')) == null) {
      this.title_name = ""
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.toQList) {
      next();
    }
    // Check if they click save
    if (this.restrictPopUp) {
      // If it detects change, prompt that changes will be lost
      if (this.detectChange()) {
        this.$refs['modal-portal'].show();
        next(false);
      } else if (this.edit) {
        PrivilegeWalkServices.setEditStatus(this.walk_id, false);
        next();
      } else {
        next();
      }
    } else {
      next();
    }
  },
  mounted() {
    if (this.id === null || this.id === undefined) {
      this.checkLogin();
    }

    window.addEventListener('beforeunload', this.handler);
  }
}
</script>

<style scoped>
.title {
  display: flex;
}

.name {
  font-size: xx-large;
  margin-top: -0.5rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.input {
  flex: 1;
}

.button {
  flex: 1;
  margin-left: 1rem;
}

.q-name {
  font-size: x-large;
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.t-name {
  font-size: x-large;
  font-weight: bold;
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.t-space {
  flex: 2;
}

.ghost {
  opacity: 0.5;
  background: lightgreen;
}

table td {
  table-layout: fixed;
  overflow: hidden;
  word-wrap: break-word;
}

.scrollable-menu {
  height: auto;
  width: 100%;
  max-height: 30vh;
  overflow-y: auto;
}
</style>