import QuestionListCollectionServices from "@/services/QuestionListCollectionServices";
import {QuestionAdapter} from "@/services/adapters/QuestionAdapter";
import {StoreMutations} from "@/store/question-search/mutations";

export const StoreActions = {
    getAllQuestionsFromWalk: 'getAllQuestionsFromWalk'
};

export default {
    getAllQuestionsFromWalk({ state, commit }) {
        QuestionListCollectionServices.getQuestionsFromWalkVersion(state.walkId, state.version).then((response) => {
            commit(StoreMutations.SET_QUESTIONS_IN_WALK,
                response.data.map(question => {
                    return new QuestionAdapter(question);
                }));
        })
    }
};
