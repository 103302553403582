import { Bar, mixins } from "vue-chartjs";

export default {
    extends: Bar,
    mixins: [mixins.reactiveProp],
    props: ["chartData", "chartOptions"],
    data() {
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    // pointLabels: {fontStyle: 'bold'},
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Codenames'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Steps'
                        }
                    }]
                },
                legend: {
                    display: false
                }
            }
        };
    },
    mounted() {
        this.render();
    },
    methods: {
        render() {
            this.renderChart(this.chartdata, this.options);
        }
    }
};