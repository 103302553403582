<template>
  <div>
    <div style="margin-left: 1%; margin-top: 5%; margin-bottom: 1rem">
      <h1 class="display-4 hr" style="padding-top: 1%">Admin Page</h1>
      <p></p>
      <b-button :to="{path: 'portal', query: {id: id}}">Portal</b-button>
    </div>

    <b-container fluid>
      <div>
        <b-card no-body style="min-height: 70vh; border-color: #e8e8e8; border-radius: 0px">
          <b-overlay :show="isLoading" rounded="sm">
            <b-tabs card pills style="min-height: 70vh; background-color: #e8e8e8" vertical>
              <b-tab title="Manage Walks" @click="getWalkList()">
                <b-card-text>
                  <b-form-input v-model="searchQueryWalk" list="questions-list" placeholder="Search"
                                type="text"></b-form-input>
                  <div class="q-list" style="display: flex">
                    <b-table
                        id="my-table"
                        :current-page="currentPage"
                        :fields="walkFields"
                        :items="filteredQuestionsWalk"
                        :per-page="perPage"
                        :tbody-tr-class="rowClass"
                        hover
                        striped
                    >
                      <template #cell(actions)="row">
                        <div style="float: right; margin: 1px">
                          <td>
                            <h5 v-if="row.item.isHidden">
                              <b-badge variant="primary">H</b-badge>
                            </h5>
                            <h5 v-else>
                              <b-badge variant="danger"></b-badge>
                            </h5>
                          </td>
                          <td>
                            <div class="btn-group">
                              <!-- <b-button class="mr-1" size="sm" @click="$bvModal.show('walk-title-modal')">
                                Edit Walk Title
                              </b-button> -->
                              <b-button class="mr-1" size="sm" @click="hideWalk(row.item); row.item.isHidden ^= true">
                                Hide
                              </b-button>
                              <b-button class="mr-1" size="sm"
                                        @click="setWalkInfo(row.item); $bvModal.show('walk-info-modal')">
                                View
                              </b-button>
                              <b-button class="mr-1" size="sm"
                                        @click="viewQuestionsInWalk(row.item); $bvModal.show('walk-question-modal')">
                                View Questions
                              </b-button>
                            </div>
                          </td>
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div class="tab-bar">
                    <div class="mt-3">
                      <div class="d-flex align-items-start">
                        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows"
                                      aria-controls="my-table"
                                      pills></b-pagination>
                        <b-button-group size="sm" style="background-color: whitesmoke">
                          <b-button :pressed="button[0]" variant="outline-primary"
                                    v-on:click="button = [true, false, false, false]; perPage = 5;">5 Walks
                          </b-button>
                          <b-button :pressed="button[1]" variant="outline-primary"
                                    v-on:click="button = [false, true, false, false]; perPage = 10;">10 Walks
                          </b-button>
                          <b-button :pressed="button[2]" variant="outline-primary"
                                    v-on:click="button = [false, false, true, false]; perPage = 20;">20 Walks
                          </b-button>
                          <b-button :pressed="button[3]" variant="outline-primary"
                                    v-on:click="button = [false, false, false, true]; perPage = 50;">50 Walks
                          </b-button>
                        </b-button-group>

                      </div>

                    </div>
                  </div>

                </b-card-text>
              </b-tab>


              <b-tab title="Manage Question List Collection" @click="getQuestionList()">
                <b-card-text>
                  <b-form-input v-model="searchQuery" list="questions-list" placeholder="Search"
                                type="text"></b-form-input>
                  <!--                <datalist id="questions-list">-->
                  <!--                  <option v-for="question in filteredQuestions" :value="question.questions" :key="question.questionID">{{ question.questions }}</option>-->
                  <!--                </datalist>-->
                  <!--                <ul>-->
                  <!--                  <li v-for="question in filteredQuestions" :key="question.questionID">-->
                  <!--                    {{ question.questions }}-->
                  <!--                  </li>-->
                  <!--                </ul>-->
                  <!--          View Questions List Collection-->

                  <b-container class="text-right" style="margin-left: 1.0rem;">
                    <b-button v-if="!showDupes" size="sm"> <!--@click="checkForDuplicates()"-->
                      Show Duplicates
                    </b-button>
                    <b-button v-else size="sm" @click="toggleShowDupes()">
                      Show All Questions
                    </b-button>
                  </b-container>
                  <b-modal ref="duplicatePopupModal" hide-footer title="Duplicates">
                    <b-table
                        id="dupe-table"
                        :fields="dupePopupFields"
                        :items="dupeItems"
                        :tbody-tr-class="rowClass"
                        hover
                        striped
                    >
                    </b-table>
                  </b-modal>
                  <div class="q-list" style="display: flex">
                    <b-table
                        id="my-table"
                        :current-page="currentPage"
                        :fields="fields"
                        :items="filteredQuestions"
                        :per-page="perPage"
                        :tbody-tr-class="rowClass"
                        hover
                        striped
                    >
                      <template v-slot:head(questionText)="data">
                      <span style="cursor: pointer;" @click="sortBy('questionText')">
                        {{ data.label }}
                        <b-icon :icon="sortIcon(data.column, 'field1')"></b-icon>
                      </span>
                      </template>
                      <template v-slot:head(duplicate)="data">
                      <span style="cursor: pointer;" @click="sortBy('duplicate')">
                        {{ data.label }}
                        <b-icon :icon="sortIcon(data.column, 'field2')"></b-icon>
                      </span>
                      </template>
                      <template #cell(actions)="row">
                        <div style="float: right">
                          <td>
                            <h5 v-if="row.item.hidden">
                              <b-badge variant="primary">H</b-badge>
                            </h5>
                            <h5 v-else>
                              <b-badge variant="danger"></b-badge>
                            </h5>
                          </td>
                          <td>
                            <!-- <div class="btn-group">
                              <b-button class="mr-1" size="sm"
                                        @click="row.item.hidden ^= true; $bvModal.show('question-text-modal')">
                                Edit Question
                              </b-button>
                            </div> -->
                          </td>
                          <td>
                            <div class="btn-group">
                              <b-button class="mr-1" size="sm" @click="hideQuestion(row.item); row.item.hidden ^= true">
                                Hide
                              </b-button>
                            </div>
                          </td>
                          <td>
                            <div class="btn-group">
                              <b-button v-if="row.item.duplicate" class="mr-1" size="sm"
                                        @click="openDuplicatePopup(row.item)">
                                Duplicates
                              </b-button>
                            </div>
                          </td>
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div class="tab-bar">
                    <div class="mt-3">
                      <div class="d-flex align-items-start">
                        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows"
                                      aria-controls="my-table"
                                      pills></b-pagination>
                        <b-button-group size="sm" style="background-color: whitesmoke">
                          <b-button :pressed="button[0]" variant="outline-primary"
                                    v-on:click="button = [true, false, false, false]; perPage = 5;">5 Questions
                          </b-button>
                          <b-button :pressed="button[1]" variant="outline-primary"
                                    v-on:click="button = [false, true, false, false]; perPage = 10;">10 Questions
                          </b-button>
                          <b-button :pressed="button[2]" variant="outline-primary"
                                    v-on:click="button = [false, false, true, false]; perPage = 20;">20 Questions
                          </b-button>
                          <b-button :pressed="button[3]" variant="outline-primary"
                                    v-on:click="button = [false, false, false, true]; perPage = 50;">50 Questions
                          </b-button>
                        </b-button-group>

                      </div>

                    </div>
                  </div>
                </b-card-text>
              </b-tab>

              <!-- <b-tab title="Change Active Tags"><b-card-text>

                                  <b-button v-b-toggle.collapse-1 variant="primary">Add or Remove Tags</b-button>
                                  <b-collapse id="collapse-1" class="mt-2">
                                    <b-card>
                                      <p class="card-text">Collapse contents Here</p>
                                      <b-button v-b-toggle.collapse-1-inner size="sm">Toggle Inner Collapse</b-button>
                                      <b-collapse id="collapse-1-inner" class="mt-2">
                                        <b-card>Hello!</b-card>
                                      </b-collapse>
                                    </b-card>
                                  </b-collapse>

              </b-card-text></b-tab> -->


              <b-tab title="Facilitator Management" @click="getFacilitatorList()">
                <b-card-text>

                  <b-button style="display: flex" @click="$bvModal.show('facilitator-modal')">+ Add new Facilitator
                  </b-button>
                  <!--        View All Facilitators-->
                  <b-form-input v-model="searchQuery" list="questions-list" placeholder="Search"
                                type="text"></b-form-input>
                  <div class="q-list" style="display: flex">
                    <b-table
                        id="my-table"
                        :current-page="currentPage"
                        :fields="facilitatorFields"
                        :items="filteredQuestions"
                        :per-page="perPage"
                        :tbody-tr-class="rowClass"
                        hover
                        striped
                    >
                      <template #cell(actions)="row">
                        <div>
                          <td style="float: left">
                            <h5 v-if="row.item.admin">
                              <b-badge variant="primary">Admin</b-badge>
                            </h5>
                            <h5 v-else>
                              <b-badge variant="danger">Not Admin</b-badge>
                            </h5>
                          </td>
                          <td style="float: left">
                            <h5 v-if="row.item.hidden">
                              <b-badge variant="danger">Disabled</b-badge>
                            </h5>
                          </td>
                          <td style="float: right">
                            <b-dropdown v-if="!checkFacilitatorID(row.item.question_id)" id="dropdown-1" dropleft text="Manage" class="m-md-2">
                              <b-dropdown-item @click="changeAdminFlag(row.item.question_id);">Change Admin Status
                              </b-dropdown-item>
                              <b-dropdown-item
                                  @click="changeHiddenFlag(row.item.question_id); row.item.hidden = !row.item.hidden ">
                                Change Disabled Status
                              </b-dropdown-item>
                            </b-dropdown>
                          </td>
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div class="tab-bar">
                    <div class="mt-3">
                      <div class="d-flex align-items-start">
                        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows"
                                      aria-controls="my-table"
                                      pills></b-pagination>
                        <b-button-group size="sm" style="background-color: whitesmoke">
                          <b-button :pressed="button[0]" variant="outline-primary"
                                    v-on:click="button = [true, false, false, false]; perPage = 5;">5 Facilitators
                          </b-button>
                          <b-button :pressed="button[1]" variant="outline-primary"
                                    v-on:click="button = [false, true, false, false]; perPage = 10;">10 Facilitators
                          </b-button>
                          <b-button :pressed="button[2]" variant="outline-primary"
                                    v-on:click="button = [false, false, true, false]; perPage = 20;">20 Facilitators
                          </b-button>
                          <b-button :pressed="button[3]" variant="outline-primary"
                                    v-on:click="button = [false, false, false, true]; perPage = 50;">50 Facilitators
                          </b-button>
                        </b-button-group>
                        <div class="button-add">
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>

              <b-tab title="Folder Management" @click="getFolderList()">
                <b-card-text>
                  <!--        View All Folders-->
                  <b-form-input v-model="searchQueryFolder" list="folders-list" placeholder="Search"
                                type="text"></b-form-input>
                  <div class="q-list" style="display: flex">
                    <b-table
                        id="my-table"
                        :current-page="currentPage"
                        :fields="folderFields"
                        :items="filteredItems"
                        :per-page="perPage"
                        :tbody-tr-class="rowClass"
                        hover
                        striped
                    >
                    </b-table>
                  </div>
                  <div class="tab-bar">
                    <div class="mt-3">
                      <div class="d-flex align-items-start">
                        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="rows"
                                      aria-controls="my-table"
                                      pills></b-pagination>
                        <b-button-group size="sm" style="background-color: whitesmoke">
                          <b-button :pressed="button[0]" variant="outline-primary"
                                    v-on:click="button = [true, false, false, false]; perPage = 5;">5 Folders
                          </b-button>
                          <b-button :pressed="button[1]" variant="outline-primary"
                                    v-on:click="button = [false, true, false, false]; perPage = 10;">10 Folders
                          </b-button>
                          <b-button :pressed="button[2]" variant="outline-primary"
                                    v-on:click="button = [false, false, true, false]; perPage = 20;">20 Folders
                          </b-button>
                          <b-button :pressed="button[3]" variant="outline-primary"
                                    v-on:click="button = [false, false, false, true]; perPage = 50;">50 Folders
                          </b-button>
                        </b-button-group>
                        <div class="button-add">
                        </div>
                      </div>
                    </div>
                  </div>

                </b-card-text>
              </b-tab>

              <!-- <b-tab title="Database Management"><b-card-text>

                                  <b-button v-b-toggle.collapse-1 variant="primary">Clear Walk Results</b-button>
                                  <b-collapse id="collapse-1" class="mt-2">
                                    <b-card>
                                      <p class="card-text">Collapse contents Here</p>
                                      <b-button v-b-toggle.collapse-1-inner size="sm">Toggle Inner Collapse</b-button>
                                      <b-collapse id="collapse-1-inner" class="mt-2">
                                        <b-card>Hello!</b-card>
                                      </b-collapse>
                                    </b-card>
                                  </b-collapse>

              </b-card-text></b-tab> -->
            </b-tabs>
          </b-overlay>
        </b-card>
      </div>
    </b-container>

    <b-modal id="walk-info-modal" hide-footer title="Walk Information">
      <b-list-group>
        <b-list-group-item>
          Walk Name: {{ walkInfo.name }}
        </b-list-group-item>
        <b-list-group-item>
          Facilitator: {{ walkInfo.facName }}
        </b-list-group-item>
        <b-list-group-item>
          Hidden
          <div style="float: right">
            <h5 v-if="walkInfo.hiddenStatus">
              <b-badge variant="danger">Hidden</b-badge>
            </h5>
            <h5 v-else>
              <b-badge variant="success">Visible</b-badge>
            </h5>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          Edit
          <div style="float: right">
            <h5 v-if="walkInfo.editStatus">
              <b-badge variant="danger">In Edit</b-badge>
            </h5>
            <h5 v-else>
              <b-badge variant="success">Not In Edit</b-badge>
            </h5>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          Finalized
          <div style="float: right">
            <h5 v-if="walkInfo.final">
              <b-badge variant="success">Finalized</b-badge>
            </h5>
            <h5 v-else>
              <b-badge variant="danger">Not Finalized</b-badge>
            </h5>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          Created On: {{ walkInfo.created }}
        </b-list-group-item>
        <b-list-group-item>
          Last Modified: {{ walkInfo.modified }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-modal id="walk-question-modal" hide-footer size="xl" v-bind:title="walkQuestionsName">
      <b-list-group>
        <b-list-group-item>
          <ul>
            <li v-for="item in walkQuestions" :key="item.questionText">
              {{ item.questionText }}
            </li>
          </ul>
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-modal id="walk-title-modal" ref="edit-walk" hide-footer size="m" title="Edit Walk">
      <b-button-group style="position: center">
        <b-form-input
            v-model="title_name"
            placeholder="Enter walk title"
        ></b-form-input>
        <b-button variant="primary">Submit</b-button>
      </b-button-group>
    </b-modal>

    <!--  Commented out due to questionLength, uncomment once finished.
    <b-modal id="question-text-modal" ref="edit-question" hide-footer title="Edit Question">
      <b-button-group style="position: center">
        <b-form-input
            v-model="question_text" :state="questionLength"
            placeholder="Enter Question"
        ></b-form-input>
        <b-button variant="primary">Submit</b-button>
      </b-button-group>
    </b-modal>
    -->
    <!--
    <b-modal size="m" id="walk-title-modal" ref="edit-walk" hide-footer title="Edit Walk">
          <b-button-group style="position: center">
            <b-form-input
                v-model="title_name"
                placeholder="Enter walk title"
            ></b-form-input>
            <b-button @click="changeWalkTitle( )" variant="primary">Submit</b-button>
          </b-button-group>
        </b-modal>

        <b-modal id="question-text-modal" ref="edit-question" hide-footer title="Edit Question">
          <b-button-group style="position: center">
            <b-form-input
                v-model="question_text" :state="questionLength"
                placeholder="Enter Question"
            ></b-form-input>
            <b-button @click="changeQuestionText( )" variant="primary">Submit</b-button>
          </b-button-group>
        </b-modal>
    -->
    <b-modal id="facilitator-modal" hide-footer title="Facilitator">
      <b-form @submit="onsubmit()">
        <b-form-group
            id="input-group-1"
            description="We'll never share your email with anyone else."
            label="Email address:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              placeholder="Enter email"
              required
              type="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Username:" label-for="input-2">
          <b-form-input
              id="input-2"
              v-model="form.username"
              placeholder="Enter username"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-3" label="Password:" label-for="input-3">
          <b-form-input
              id="input-2"
              v-model="form.password"
              placeholder="Enter password"
              required
              type="password"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import QuestionServices from "@/services/QuestionServices";
import AdminServices from "@/services/AdminServices";
import FacilitatorServices from "@/services/FacilitatorServices";
import PrivilegeWalkServices from "@/services/PrivilegeWalkServices";
import {mapActions, mapState} from "vuex";
import FacilitatorStoreIndex from "@/store/facilitator-portal/_StoreIndex";
import {StoreActions as FacilitatorStoreAction} from "@/store/facilitator-portal/actions";
import {StoreState as FacilitatorStoreState} from "@/store/facilitator-portal/state";
import QuestionListCollectionServices from "../services/QuestionListCollectionServices";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      searchQuery: "",
      filteredQuestions: [],
      searchQueryWalk: "",
      searchQueryFolder: "",
      filteredQuestionsWalk: [],
      filteredItems: [],
      fields: [
        {key: 'questions', label: 'Questions', sortable: true},
        {key: 'duplicate', label: 'Duplicates', sortable: true,},
        {key: 'actions', label: 'Hidden'},
      ],
      facilitatorFields: [
        {key: 'questions', label: 'Facilitators'},
        {key: 'actions', label: ''}
      ],
      walkFields: [
        {key: 'pwName', label: 'Walks'},
        {key: 'actions', label: 'Hidden'}
      ],
      dupePopupFields: [
        {key: 'questionText', label: 'Questions'}
      ],
      folderFields: [
        {key: 'name', label: 'Folders', sortable: true},
        {key: 'facilitator', label: 'Owner', sortable: true}
      ],
      items: [],
      button: [false, true, false, false],
      count: 0,
      listQuestion: ["This is a test?", "This is test 2?", "This is test 3?"],
      addedList: [],
      arrayData: {
        questionArray: []
      },
      facilitators: [],
      walkInfo: {
        name: '',
        ID: '',
        version: '',
        final: '',
        editStatus: '',
        hiddenStatus: '',
        modified: '',
        status: '',
        facName: ''
      },
      form: {
        email: '',
        username: '',
        password: ''
      },
      selectedFacilitatorID: null,
      showDupes: false,
      dupeItems: [],
      isLoading: false,
      walkQuestions: [],
      version: 0,
      walkQuestionsName: '',
      title_name: '',
      edit_question: false,
      edit_question_array: [],
      question_text: '',
      walk_id: null,
    }
  },
  watch: {
    searchQuery(newQuery) {
      this.filteredQuestions = this.items.filter((question) => {
        return question.questions.toLowerCase().includes(newQuery.toLowerCase());
      });
    },
    searchQueryWalk(newQuery) {
      this.filteredQuestionsWalk = this.items.filter((question) => {
        return question.pwName.toLowerCase().includes(newQuery.toLowerCase());
      });
    },
    searchQueryFolder(newQuery) {
      this.filteredItems = this.items.filter((folder) => {
        return folder.name.toLowerCase().includes(newQuery.toLowerCase());
      });
    },
  },
  computed: {
    ...mapState(FacilitatorStoreIndex.storeName, {
      id: FacilitatorStoreState.id
    }),
    rows() {
      return this.items.length
    }
  },
  methods: {
    ...mapActions(FacilitatorStoreIndex.storeName, {
      checkLogin: FacilitatorStoreAction.checkSession
    }),
    compilePW(item) {
      if (this.arrayData.questionArray.includes(item)) {
        const index = this.addedList.indexOf(item);
        this.arrayData.questionArray.splice(index, 1);
        this.count -= 1
      } else {
        this.arrayData.questionArray.push(item)
        this.count += 1
      }
    },
    /*async changeQuestionText( ) {
      // let character = /^[\w <>+-=!$%,.?"'/(/)]*$/;
      let spaces = /\s{3,}/;
      let last_char = this.question_text[this.question_text.length - 1];
      let word_array = this.question_text.split(" ");
      if (word_array[word_array.length - 1] === '') {
        word_array.pop();
      }
      if (this.question_text.indexOf("|") === -1 && !(spaces.test(this.question_text)) && word_array.length > 1 && this.question_text.length < 150 && (word_array.length > 1 && last_char === '.' || last_char === '!' || last_char === '?')) {
        if (this.question_text[this.question_text.length - 1] === ' ' && this.question_text[this.question_text.length - 2] === ' ') {
          this.question_text = this.question_text.slice(0, -1);
          this.question_text = this.question_text.slice(0, -1);
        } else if (this.question_text[this.question_text.length - 1] === ' ') {
          this.question_text = this.question_text.slice(0, -1);
        }
        this.array.splice(this.edit_question_array[0].index,0,{'question_text': this.question_text, 'step_back': this.edit_question_array[0].step_back, 'step_forward': this.edit_question_array[0].step_forward, 'idk': this.edit_question_array[0].idk});
        this.question_array.push(this.question_text);
        this.question_text = '';

        this.edit_question_array.splice(0,1);
        this.edit_question = false;
      }
    },
    async changeWalkTitle() {
      try {
        this.restrictPopUp = false;
        this.wait_save = true;
        if (!(this.title_name.length < 100 && this.title_name.length >= 5)) {
          this.$refs['modal-title'].show();
          return;
        }

        await PrivilegeWalkServices.changeWalkTitle(this.$route.query.walkID, this.title_name);
        await PrivilegeWalkServices.changeModifiedAt(this.$route.query.walkID);
      } catch (error) {
        this.wait_save = false;
      }
    },
    questionLength() {
      // let character = /^[\w <>+-=!$%,.?"'/(/)]*$/;
      let spaces = /\s{3,}/;
      let word_array = this.question_text.split(" ");
      let last_char = this.question_text[this.question_text.length - 1];
      if (word_array[word_array.length - 1] === '') {
        word_array.pop();
      }
      return this.question_text.indexOf("|") === -1 && !(spaces.test(this.question_text)) && word_array.length > 1 && this.question_text.length < 150 && (word_array.length > 1 && last_char === '.' || last_char === '!' || last_char === '?');
    },*/
    sortBy(field) {
      if (field === this.sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = field;
        this.sortDesc = false;
      }
      if (this.sortBy === 'duplicate') {
        this.items.sort((a, b) => {
          const result = a.duplicate - b.duplicate;
          return result === 0 ? a.questionText.localeCompare(b.questionText) : result;
        });
      } else if (this.sortBy === 'questionText') {
        this.items.sort((a, b) => {
          const result = a.questionText.localeCompare(b.questionText);
          return result === 0 ? (a.duplicate ? -1 : 1) : result;
        });
      }
    },
    sortIcon(column, field) {
      if (column !== field) {
        return 'sort';
      } else {
        return this.sortDesc ? 'sort-down-alt' : 'sort-up';
      }
    },
    async getQuestionList() {
      this.items = []
      await QuestionServices.getQuestionList().then((response) => {
        response.data.forEach(question => this.items.push(
                {
                  questions: question.questionText,
                  questionText: question.questionText,
                  question_id: question.question_id,
                  step_back: 1,
                  step_forward: 1,
                  hidden: question.hidden,
                  duplicate: question.duplicate,
                  duplicateQuestionIDs: question.duplicateQuestionIDs,
                  dupeChance: question.dupeChance,
                  dupe_question_id: question.dupe_question_id
                }
            )
        )
        this.searchQuery = "";
        this.filteredQuestions = this.items;
      })
    },
    async getWalkList() {
      this.isLoading = true;
      this.items = []
      await PrivilegeWalkServices.findAllWalks().then((response) => {
        response.data.forEach(walk => this.items.push(
                {
                  pwName: walk.pw_name,
                  walkID: walk.walk_id,
                  createdAt: walk.createdAt,
                  currentVersion: walk.currentVersion,
                  finalized: walk.finalized,
                  inEdit: walk.inEdit,
                  isHidden: walk.hidden,
                  modifiedAt: walk.modifiedAt,
                  walkStatus: walk.walkStatus,
                  facilitator: walk.facilitator_id.username
                }
            )
        )
        this.searchQuery = "";
        this.searchQueryWalk = "";
        this.filteredQuestionsWalk = this.items;
      })
      this.isLoading = false;
    },
    async getFacilitatorList() {
      this.isLoading = true;
      this.items = []
      await AdminServices.getFacilitatorList().then((response) => {
        response.data.forEach(facilitator => this.items.push(
                {
                  questions: facilitator.email + ' : ' + facilitator.username,
                  question_id: facilitator.facilitator_id,
                  added: false,
                  admin: facilitator.admin,
                  hidden: facilitator.hidden
                }
            )
        )
        this.searchQuery = "";
        this.filteredQuestions = this.items;
      })
      this.isLoading = false;
    },
    async getFolderList() {
      this.isLoading = true;
      this.items = []
      await AdminServices.getFolderList().then((response) => {
        response.data.forEach(folder => this.items.push(
                {
                  name: folder.folder_Name,
                  facilitator: folder.facilitator_id.username,
                  folder_id: folder.folder_id,
                }
            )
        )
        this.searchQuery = "";
        this.filteredItems = this.items;
      })
      this.isLoading = false;
    },
    async openDuplicatePopup(question) {
      this.dupeItems = await this.checkForDuplicatesSpecific(question);

      // this.dupeItems = [];
      // if (question.dupe_question_id != null) {
      //   await QuestionServices.getQuestionByID(question.dupe_question_id)
      //       .then(response => {
      //         this.dupeItems.push(response.data);
      //         this.$refs.duplicatePopupModal.show();
      //       });
      // } else {
      //   this.$refs.duplicatePopupModal.show();
      // }
      if (this.dupeItems.length == 0) {
        this.dupeItems.push({questionText: 'NO DUPLICATES'});
      }
      this.dupeItems.sort((a, b) => a.questionText.localeCompare(b.questionText));
      this.$refs.duplicatePopupModal.show();
    },

    toggleShowDupes() {
      this.showDupes = false;
      this.getQuestionList();
    },
    showDuplicates(items) {
      console.log("ShowDuplicates")
      this.showDupes = true;
      this.filteredQuestions = items;
      for (let i = 0; i < this.filteredQuestions.length; i) {
        if (this.filteredQuestions[i].duplicate == null || !this.filteredQuestions[i].duplicate) {
          this.filteredQuestions.splice(i, 1);
        } else {
          i++;
        }
      }
    },
    async checkForDuplicatesSpecific(question) {
      console.log("CheckForDuplicatesSpecific");
      this.filteredQuestions = [];
      let dupes = [];
      await QuestionServices.getQuestionList().then((response) => {
        response.data.forEach(question => this.filteredQuestions.push(
            {
              questions: question.questionText,
              questionText: question.questionText,
              question_id: question.question_id,
              step_back: 1,
              step_forward: 1,
              hidden: question.hidden,
              duplicate: question.duplicate,
              dupe_question_id: question.dupe_question_id,
              duplicateQuestionIDs: [],
              dupeChance: question.dupeChance
            }
        ))
      });

      const dupeCutoff = 15;

      for (let i = 0; i < this.filteredQuestions.length; i++) {
        if (this.filteredQuestions[i].questions !== null && question !== null && this.filteredQuestions[i].question_id != question.question_id) {
          const question1 = this.filteredQuestions[i].questions.replace(/[.,/#!$%^&*;:{}=\-_`~()\s]/g, "").toLowerCase();
          const question2 = question.questions.replace(/[.,/#!$%^&*;:{}=\-_`~()\s]/g, "").toLowerCase();
          const distance = this.calculateDistance(question1, question2);
          if (distance != null) {
            if (this.filteredQuestions[i].dupeChance == null || distance < this.filteredQuestions[i].dupeChance) {
              this.filteredQuestions[i].dupeChance = distance;
            }
            if (this.filteredQuestions[i].dupeChance <= dupeCutoff) {
              this.filteredQuestions[i].duplicate = true;
              dupes.push(this.filteredQuestions[i]);
            }
          }
        }
      }
      if (this.showDupes) {
        this.showDuplicates(this.filteredQuestions);
      }
      return dupes;
    },

    async checkForDuplicates() {
      console.log("CheckForDuplicates");
      this.items = [];
      await QuestionServices.getQuestionList().then((response) => {
        response.data.forEach(question => this.items.push(
            {
              questions: question.questionText,
              questionText: question.questionText,
              question_id: question.question_id,
              step_back: 1,
              step_forward: 1,
              hidden: question.hidden,
              duplicate: question.duplicate,
              dupe_question_id: question.dupe_question_id,
              duplicateQuestionIDs: [],
              dupeChance: question.dupeChance
            }
        ))
      });

      // TODO Run 1 time on database then run on new questions when added
      // TODO database can only handle showing 1 duplicate to a question
      const dupeCutoff = 15;

      for (let i = 0; i < this.items.length - 1; i++) {
        for (let j = i + 1; j < this.items.length; j++) {
          if (this.items[i].questions !== null && this.items[j].questions !== null) {
            const question1 = this.items[i].questions.replace(/[.,/#!$%^&*;:{}=\-_`~()\s]/g, "").toLowerCase();
            const question2 = this.items[j].questions.replace(/[.,/#!$%^&*;:{}=\-_`~()\s]/g, "").toLowerCase();
            const distance = this.calculateDistance(question1, question2);
            if (distance != null) {
              if (this.items[i].dupeChance == null) {
                this.items[i].dupeChance = distance;
              }
              if (distance <= this.items[i].dupeChance) {
                this.items[i].dupeChance = distance;
                if (this.items[i].dupeChance <= dupeCutoff) {
                  this.items[i].duplicate = true;
                  QuestionServices.putChangeDuplicate(true, this.items[i].question_id, this.items[j].question_id);
                  this.items[i].duplicateQuestionIDs.push(this.items[j].question_id);
                } else {
                  this.items[i].duplicate = false;
                  // QuestionServices.putChangeDuplicate(false, this.items[i].question_id,null);
                }
              }
              if (this.items[j].dupeChance == null) {
                this.items[j].dupeChance = distance;
              }
              if (distance <= this.items[j].dupeChance) {
                this.items[j].dupeChance = distance;
                if (this.items[j].dupeChance <= dupeCutoff) {
                  this.items[j].duplicate = true;
                  QuestionServices.putChangeDuplicate(true, this.items[j].question_id, this.items[i].question_id);
                  this.items[j].duplicateQuestionIDs.push(this.items[i].question_id);
                }
              }
            }
          }
        }
      }
      if (this.items[this.items.length - 1] != null && !this.items[this.items.length - 1].duplicate) {
        this.items[this.items.length - 1].duplicate = false;
        // QuestionServices.putChangeDuplicate(false, this.items[this.items.length-1].question_id,null);
      }
      this.showDuplicates(this.items);
    },

    calculateDistance(source, target) {
      const cutoff = 15;
      if (source == null || target == null) return null;

      const sourceLength = source.length;
      const targetLength = target.length;

      if (sourceLength === 0 || targetLength === 0) return null;

      // If the length is too different end early
      if (Math.abs(sourceLength - targetLength) > cutoff) {
        return (Infinity);
      }

      let dist = new Array(sourceLength + 1);
      for (let i = 0; i < sourceLength + 1; i++) {
        dist[i] = new Array(targetLength + 1);
        dist[i][0] = i;
      }

      for (let j = 0; j < targetLength + 1; j++) {
        dist[0][j] = j;
      }

      for (let i = 1; i < sourceLength + 1; i++) {
        for (let j = 1; j < targetLength + 1; j++) {
          let cost = source.charAt(i - 1) == target.charAt(j - 1) ? 0 : 1;
          dist[i][j] = Math.min(Math.min(dist[i - 1][j] + 1, dist[i][j - 1] + 1), dist[i - 1][j - 1] + cost);
          if (i > 1 && j > 1 && source.charAt(i - 1) == target.charAt(j - 2) && source.charAt(i - 2) == target.charAt(j - 1)) {
            dist[i][j] = Math.min(dist[i][j], dist[i - 2][j - 2] + cost);
          }
        }
      }
      return dist[sourceLength][targetLength];
    },

    changeAdminFlag(selectedID) {
      AdminServices.flipAdminFlag(selectedID);
      this.items.forEach(item => {
        if (item.question_id == selectedID) {
          item.admin = !item.admin;
        }
      })
    },
    changeHiddenFlag(selectedID) {
      AdminServices.flipHiddenFlag(selectedID);
    },
    setWalkInfo(item) {
      this.walkInfo.name = item.pwName;
      this.walkInfo.final = item.finalized;
      this.walkInfo.editStatus = item.inEdit;
      this.walkInfo.hiddenStatus = item.isHidden;
      this.walkInfo.created = item.createdAt.toString().substring(0, 10);
      if (item.modifiedAt == null) {
        this.walkInfo.modified = item.createdAt.toString().substring(0, 10) + " " + item.createdAt.toString().substring(11, 19);
      } else {
        this.walkInfo.modified = item.modifiedAt.toString().substring(0, 10) + " " + item.modifiedAt.toString().substring(11, 19);
      }
      this.walkInfo.facName = item.facilitator;
    },
    async viewQuestionsInWalk(item) {
      this.walkQuestions = '';
      this.walkQuestionsName = 'Loading...';
      await QuestionListCollectionServices.getQuestionsFromWalk(item.walkID).then(res => {
        this.walkQuestions = res.data;
        this.walkQuestionsName = item.pwName;
      })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.added === true) return 'table-success'
    },
    hideQuestion(question) {
      console.log(question)
      if (question.hidden == true) {
        AdminServices.hideQuestion(question.question_id, false, this.id).then(res => {
          console.log(res.data);
        })
      } else {
        AdminServices.hideQuestion(question.question_id, true, this.id).then(res => {
          console.log(res.data);
        })
      }
    },
    hideWalk(walk) {
      console.log(walk)
      if (walk.isHidden == true) {
        AdminServices.hideWalk(walk.walkID, false, this.id).then(res => {
          console.log(res.data);
        })
      } else {
        AdminServices.hideWalk(walk.walkID, true, this.id).then(res => {
          console.log(res.data);
        })
      }
    },
    onsubmit() {
      event.preventDefault();
      console.log(this.form);
      FacilitatorServices.addFacilitator(this.form.username, this.form.email, this.form.password).then(res => {
        console.log(res.data);
        if (res.data === 1) {
          this.getFacilitatorList();
        }
      })
      this.$bvModal.hide('faciliator-modal');
    },
    openUserManual() {
      const pdfUrl = "./assets/static/UserManualAdmin.pdf"
      window.open(pdfUrl, '_blank');
    },
    checkFacilitatorID(otherID) {
      console.log(this.id);
      console.log(otherID);
      return this.id === otherID;
    }
  },
  created() {
    if (this.id === null) {
      this.checkLogin();
    }
    console.log(this.id)
    this.getWalkList();
  }
}

</script>

<style scoped>
.btn-group-xs > .btn, .btn-xs {
  padding: .05rem .1rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

body {
  padding: 20px;
}

h1 {
  color: darkcyan;
}

.portalView {
  padding: 1.5rem;
  margin-right: 20%;
  margin-left: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
}

.walk-table {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
}

.walk-name {
  margin-left: 2rem;
  float: left;
  position: absolute;
  font-weight: normal;
  font-size: x-large;
}

.hr {
  display: inline-block;
}

.hr:after {
  content: '';
  display: block;
  border-top: 1px solid dimgray;
  margin-top: 0.2em;

}

.tab {
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.vh-100 {
  min-height: 100vh;
}
</style>