<template>
  <div>
    <div class="small-screen" v-if="this.windowWidth <= 720">
      <div>
        <b-container align="center">
          <p style="font-size: x-large; color: dimgray">Thank You For Taking Part in {{ name }}'s Privilege Walk</p>
          <!--        <p style="font-size: x-large">Codename: {{ codename }}</p>-->
          <!--        <p style="font-size: x-large">Score: {{ step }}</p>-->
          <p></p>
        </b-container>
      </div>
      <div class="small-box">
        <b-container fluid>
          <b-row align-v="center" style="margin-bottom: -1rem">
            <b-col>
              <p class="small-title">{{ WalkTitle }}</p>
            </b-col>
            <b-col class="text-right">
              <p>
                <!-- <b-button style="margin-right: 1rem" variant="outline-primary" @click="reload()">Refresh</b-button> -->
                <b-button style="margin-right: 1rem" variant="outline-secondary" href="/">Back to Home</b-button>
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <p></p>
    </div>

    <div style="margin-top: 10rem" v-else>
      <div>
        <b-container align="center">
          <p style="font-size: xx-large; color: dimgray">Thank You For Taking Part in {{ name }}'s Privilege Walk</p>
          <!--        <p style="font-size: x-large">Codename: {{ codename }}</p>-->
          <!--        <p style="font-size: x-large">Score: {{ step }}</p>-->
          <p></p>
        </b-container>
      </div>
      <div class="box">
        <b-container fluid>
          <b-row align-v="center" style="margin-bottom: -1rem">
            <b-col>
              <p class="title">{{ WalkTitle }}</p>
            </b-col>
            <b-col class="text-right">
              <p>
                <!-- <b-button style="margin-right: 1rem" variant="outline-primary" @click="reload()">Refresh</b-button> -->
                <b-button style="margin-right: 1rem" variant="outline-secondary" href="/">Back to Home</b-button>
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <p></p>

    </div>
    <div class="chart">
      <canvas id="graph"></canvas>
    </div>

  </div>
</template>

<script>
import SessionServices from "@/services/SessionServices";
import PrivilegeWalkServices from "@/services/PrivilegeWalkServices";
import Chart from "chart.js";
import "chartjs-chart-error-bars";

export default {
  name: "endWalkParticipant",
  data() {
    return {
      session_id: this.$route.query.sessionID,
      step: this.$route.query.walkstep,
      name: '',
      id: this.$route.query.id,
      walk_id: null,
      minSteps: 0,
      maxSteps: 0,
      WalkTitle: "",
      datacollection: null,
      dataoptions: null,
      codenames: ["Lowest Possible Score", this.$route.query.codename, "Highest Possible Score"],
      steps: [],
      data_length: 0,
      walk_results_id: null,
      questionResponses: [],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmClose);
    window.removeEventListener('resize', this.windowResize);
  },
  methods: {
    confirmClose(event) {
      event.preventDefault();
      event.returnValue = '';
      return ''; // For Chrome
    },
    createGraphWithErrorBars() {
      let chart = new Chart(document.getElementById("graph").getContext("2d"), {
        type: "barWithErrorBars",
        data: {
          labels: [this.$route.query.codename],
          datasets: [
            {
              backgroundColor: ["#73be73"],
              data: [
                {
                  y: this.step,
                  yMin: this.minSteps,
                  yMax: this.maxSteps,
                },
              ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            // pointLabels: {fontStyle: 'bold'},
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Black bar indicates the range of possible scores'
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Steps'
              },
              ticks: {
                min: this.minSteps - 1,
                max: this.maxSteps + 1
              },
            }]
          },
          legend: {
            display: false
          },
        },
      });
      return chart;
    },
    getFacilitatorName() {
      SessionServices.getWalk(this.session_id).then(response => {
        this.name = response.data.facilitator_id.username
      })
    },
    //methods for graph
    getWalkTitle() {
      SessionServices.getWalk(this.session_id).then(response => {
        this.WalkTitle = response.data.pw_name
      })
    },
    async setGraphInfo() {
      await SessionServices.getWalkIDFromSessionID(this.session_id).then(result => {
        this.walk_id = result.data.walk_id
      })
      if (this.step === '0') {
        this.step = 0.25;
      }//else {
      //  this.steps.push(this.step);
      //}
      await PrivilegeWalkServices.getMin(this.walk_id).then(result => {
        this.minSteps = result.data
        if (this.minSteps === 0) {
          this.minSteps = -0.25;
        }//else {
        //  this.steps.push(this.minSteps);
        //}
      })
      await PrivilegeWalkServices.getMax(this.walk_id).then(result => {
        this.maxSteps = result.data
        if (this.maxSteps === 0) {
          this.maxSteps = 0.25;
        }//else {
        //  this.steps.push(this.maxSteps);
        //}
      })
      //await this.changeGraph();
      await this.createGraphWithErrorBars();
    },
    changeGraph() {
      this.datacollection = {
        labels: this.codenames,
        datasets: [
          {
            label: 'Steps',
            backgroundColor: "#73be73",
            data: this.steps
          }
        ]
      };
      this.dataoptions = {}
    },
    reload() {
      this.$router.go()
    },
    goToPortal() {
      this.$router.push({path: 'portal', query: {id: this.id}})
    },
    windowResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  },
  mounted() {
    this.getFacilitatorName()
    this.getWalkTitle();
    this.setGraphInfo();

    window.addEventListener('beforeunload', this.confirmClose);

    this.$nextTick(() => {
      window.addEventListener('resize', this.windowResize);
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.counterInterval);
    next();
  }
}
</script>

<style scoped>
.box {
  margin-top: 85px;
  padding: 5px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.small-box {
  margin-top: 10px;
  padding: 5px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.title {
  /*float: left;*/
  //margin-bottom: -0.5rem;
  font-size: 30px;
  font-weight: 500;
}

.small-title {
  /*float: left;*/
//margin-bottom: -0.5rem;
  font-size: 20px;
  font-weight: 500;
}

.chart {
  height: 60vh;
  width: 93vw;
  margin-left: 3%;
  margin-right: 3%;
}

.small-screen {
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
}
</style>