<template>
  <div style="margin-top: 50px">
    <div class="jumbotron" style="background-color: transparent; margin-bottom: -1rem">
      <h1 class="display-4 hr">{{ codename }} Folders</h1>
      <p style="font-size: x-large">{{ pwList.length }} Stored Walks</p>
      <p style="font-size: large; margin-top: -1rem">Version: {{ version }}</p>
      <span style="display: flex">
        <b-button style=" margin: 2px;" @click="goToPortal" variant="info">Portal</b-button>
        <b-button style=" margin: 2px;" @click="$bvModal.show('modal-10')" variant="success">Create Folder</b-button>
        <b-button style=" margin: 2px;" @click="refresh" variant="warning">Refresh</b-button>
      </span>
    </div>

    <div>
      <b-row style="margin-left: 0.8rem; margin-right: 0.8rem">
        <div class="col-xl-8">
          <!-- FOLDERS  -->
          <h2> My Folders</h2>
          <div v-for="folder in allFolders" :key="folder.folder_id" class="walk-table">
            <b-card
                style="background-color: transparent; border: none;!important">
              <b-media>
                <template #aside>
                </template>
                <div>
                  <b-row>
                    <b-col class="text-left">
                      <button class="btn btn-outline-secondary btn-xxs"
                              style="margin-right: -1.5rem; margin-top: 0.5rem"
                              type="button"
                              @click="folder.active = !folder.active; folder_id=folder.folder_id">
                          <span v-if="folder.active">
                            <b-icon icon="caret-down-fill" scale="1"></b-icon>
                          </span>
                        <span v-else>
                            <b-icon icon="caret-right" scale="1"></b-icon>
                          </span>
                      </button>
                      <span class="mt-0 walk-name">{{ folder.folder_name }}
                          <span class="badge">
                            <div style="display: flex;">
                            </div>
                          </span>
                      </span>



                      <b-dropdown id="dropdown-dropright" dropright style="float: right;" class="m-2" text="Add Walks">
                        <b-button variant="primary" size="sm" @click="folder_id=folder.folder_id; createWalkFolders()" style="margin: 10px; float:right">Submit</b-button>
                        <div style="overflow:scroll;  height: 200px; margin: 2px">
                          <b-dropdown-header id="dropdown-header-label">
                            Privilege Walks
                          </b-dropdown-header>

                          <b-form-checkbox
                              v-for="walk in pwList"
                              v-model="walk.checked"
                              :key="walk.walkID"
                              :value="walk.value"
                              name="flavour-4a"
                              inline
                              style="width: 400px; margin: 6px"
                          >
                            {{ walk.pwName }}
                          </b-form-checkbox>
                        </div>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                </div>
                <b-media v-if="folder.active">
                  <template #aside>
                    <p></p>
                  </template>
                  <hr>

                  <div style="overflow:scroll;  height: 200px; margin: 2px">
                    <div v-for="walk in matchingWalks" :key="walk.walk_id" class="walk-table">
                      <b-row style="margin:5px">
                        <b-col class="text-left" style="margin:5px">


                          <span >{{ walk.pwName }}
                            <span v-if="walk.walk_owner" class="badge">
                              <div style="display: flex;">
                                <div>
                                  <b-badge variant="secondary">Collaborator</b-badge>
                                </div>
                                <div v-if="!walk.finalized" style="margin-left: 0.3rem">
                                  <b-badge variant="info">Not Finalized</b-badge>
                                </div>
                              </div>
                            </span>
                            <span v-else-if="!walk.finalized" class="badge">
                              <b-badge variant="info">Not Finalized</b-badge>
                            </span>
                          </span>

                          <b-button-group v-if="walk.priv === 'VE' || walk.priv === 'EV' || walk.priv === 'E'"
                                          style="float: right;">
                            <b-button variant="outline-dark" @click="sendPWID(walk.walk_id)">Edit</b-button>
                          </b-button-group>
                          <b-button-group v-else-if="walk.priv === 'VH' || walk.priv === 'HV' || walk.priv === 'H'"
                                          style="float: right">
                            <b-button v-b-modal.modal-1 variant="outline-dark"
                                      @click="hostWalkTitle=walk.pwName; hostWalkID=item.walk_id">Host
                            </b-button>
                          </b-button-group>
                          <b-button-group v-else-if="walk.priv === 'V'" style="float: right; margin-bottom: 2.38rem">
                          </b-button-group>
                          <b-button-group v-else style="float: right">
                            <b-button v-if="walk.finalized" v-b-modal.modal-1
                                      variant="outline-dark"
                                      @click="hostWalkTitle=walk.pwName; hostWalkID=walk.walk_id">Host
                            </b-button>
                            <b-button v-else v-b-modal.modal-8
                                      variant="outline-dark"
                                      @click="hostWalkTitle=walk.pwName; hostWalkID=walk.walk_id">Host
                            </b-button>
                            <b-button variant="outline-dark" @click="sendPWID(walk.walk_id)">Edit</b-button>
                            <b-button style="float: right;" @click="deleteWalkFolder(folder.folder_id, walk.walk_id)" class="hover-red" variant="outline-dark">Remove</b-button>
                          </b-button-group>

                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-media>
              </b-media>
            </b-card>
          </div>

          <!-- FOLDERS END -->
          <h2 style="position: center"> My Walks </h2>
          <div v-for="item in pwList" :key="item.walk_id" class="walk-table">
            <b-card
                style="background-color: transparent; border: none;!important">
              <b-media>
                <div>
                  <b-row>
                    <b-col class="text-left">
                      <span class="mt-0 walk-name">{{ item.pwName }}
                        <span v-if="item.walk_owner" class="badge">
                          <div style="display: flex;">
                            <div>
                              <b-badge variant="secondary">Collaborator</b-badge>
                            </div>
                            <div v-if="!item.finalized" style="margin-left: 0.3rem">
                              <b-badge variant="info">Not Finalized</b-badge>
                            </div>
                          </div>
                        </span>
                        <span v-else-if="!item.finalized" class="badge">
                          <b-badge variant="info">Not Finalized</b-badge>
                        </span>
                      </span>
                      <b-button-group style="float: right" class="m-2">
                        <b-button v-if="item.finalized" v-b-modal.modal-1
                                  @click="hostWalkTitle=item.pwName; hostWalkID=item.walk_id">Host
                        </b-button>
                        <b-button v-else v-b-modal.modal-8
                                  @click="hostWalkTitle=item.pwName; hostWalkID=item.walk_id">Host
                        </b-button>
                        <b-button @click="sendPWID(item.walk_id)">Edit</b-button>


                        <b-dropdown  id="dropdown-dropright" dropright text="Add to Folder">
                          <b-button variant="primary" size="sm" @click="walk_id=item.walk_id; addWalkToSelectedFolders()" style="margin: 10px; float:right">Submit</b-button>
                          <div style="overflow:scroll;  height: 200px; margin: 2px">
                            <b-dropdown-header id="dropdown-header-label">
                               Created Folders
                            </b-dropdown-header>

                            <b-form-checkbox
                                v-for="folder in allFolders.slice(0, allFolders.length)"
                                v-model="folder.checked"
                                :key="folder.folder_id"
                                :value="folder.value"
                                name="flavour-4a"
                                inline
                                style="width: 400px; margin: 6px"
                            >
                              {{ folder.folder_name }}
                            </b-form-checkbox>

                          </div>
                        </b-dropdown>
                      </b-button-group>
                    </b-col>
                  </b-row>
                </div>
              </b-media>
            </b-card>
          </div>
        </div>

        <!-- ACTIVE WALKS  -->

        <div class="col-xl-4">
          <h2>Active Walks</h2>
          <div v-for="item in activePWList" :key="item.walkID" class="walk-table">
            <b-card
                style="background-color: transparent; border: none; !important">
              <b-media>
                <template #aside>
                  <p></p>
                </template>
                <div class="d-flex align-items-center">
                  <button class="btn btn-outline-secondary btn-xxs" type="button" @click="item.active = !item.active">
                    <div v-if="item.active">
                      <b-icon icon="caret-down-fill" scale="1"></b-icon>
                    </div>
                    <div v-else>
                      <b-icon icon="caret-right" scale="1"></b-icon>
                    </div>
                  </button>
                  <div class="mt-0 walk-name">{{ item.walk_name }}</div>
                </div>
                <b-media v-if="item.active">
                  <template #aside>
                    <p></p>
                  </template>
                  <hr style="margin-left: 1rem;">
                  <b-container fluid>
                    <p style="margin-bottom: 5px; margin-top: 0.1rem">Participants in Walk:
                      {{ item.in_walk - item.num_submitted }}</p>
                    <p style="margin-bottom: 5px;">
                      Add
                      <b-form-spinbutton id="sb-inline" v-model="add_participants" inline max="50" min="1"
                                         size="sm"></b-form-spinbutton>
                      Participants
                      <b-button size="sm" variant="success" @click="addMoreParticipants(item, add_participants)">Add
                      </b-button>
                    </p>
                    <p style="margin-bottom: -0.1rem; margin-top: 0.1rem">Responses Completed: {{ item.num_submitted }}
                      of {{ item.max_submitted }}</p>
                    <b-progress :max="item.max_submitted" height="1.2rem" style="margin-bottom: 0.2rem"
                                variant="success">
                      <b-progress-bar :value="item.num_submitted">
                        <!--                        <span><strong>{{ item.num_submitted }} of {{ item.max_submitted }}</strong></span>-->
                      </b-progress-bar>
                    </b-progress>

                    <b-row>
                      <b-col cols="6">
                        <p class="mt-2" style="font-size: large">Room Key: {{ item.code }}</p>
                      </b-col>
                      <b-col class="text-right" cols="6" style="margin-top: 0.5rem">
                        <b-button size="sm" style="margin-right: 0.3rem" variant="primary"
                                  @click="goToResults(item.code)">Results
                        </b-button>
                        <b-button size="sm" variant="danger" @click="endActiveWalk(item.sessionID)">End</b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-media>
              </b-media>
            </b-card>
          </div>
        </div>


      </b-row>
    </div>


    <b-modal id="modal-1" title="Host Privilege Walk" @ok="hostWalk">
      <div>
        <h3>{{ hostWalkTitle }}</h3>
        <p>Number of Participants</p>
        <b-form-input v-model="numParticipants" :state="validation" :type="type" max="100" min="1"></b-form-input>
        <div>
          <b-form-group label="Walk Pace" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="A">Participant</b-form-radio>
            <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="B">Facilitator</b-form-radio>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal_5" hide-footer title="Already a Collaborator" @hide="$bvModal.show('modal_4')">
      <p>That facilitator is already a collaborator.</p>
    </b-modal>
    <b-modal ref="modal_6" hide-footer title="No Facilitator" @hide="$bvModal.show('modal_4')">
      <p>There is no facilitator with that email.</p>
    </b-modal>
    <b-modal ref="modal_7" hide-footer title="Collaborator Error" @hide="$bvModal.show('modal_4')">
      <p>Cannot add yourself as a collaborator.</p>
    </b-modal>
    <b-modal id="modal-8" ref="modal_8" hide-footer title="Walk Not Finalized">
      <p>Are you sure you want to host an unfinalized walk.</p>
      <b-button-group style="float: right">
        <b-button variant="primary" @click="$bvModal.hide('modal-8'); $bvModal.show('modal-1')">Yes</b-button>
        <b-button variant="primary" @click="$bvModal.hide('modal-8')">No</b-button>
      </b-button-group>
    </b-modal>
    <b-modal id="modal-10" ref="create-folder" hide-footer title="Folders">
      <b-button-group style="position: center">
        <b-form-input
            v-model="folderNameCreate"
            placeholder="Enter folder name"
        ></b-form-input>
        <b-button @click="createFolder()" variant="primary">Submit</b-button>
      </b-button-group>
    </b-modal>
    <b-modal id="modal-11" ref="edit-folder" hide-footer title="Edit Folder">
      <b-button-group style="position: center">
        <b-form-input
            v-model="folderNameEdit"
            placeholder="Enter folder name"
        ></b-form-input>
        <b-button variant="primary">Submit</b-button>
      </b-button-group>
    </b-modal>
    <b-modal ref="modal-edit" hide-footer title="Walk Edit">
      Walk is currently being edited by walk owner or collaborator. Please wait.
    </b-modal>
    <b-modal ref="modal-title" hide-footer title="Walk Title">
      Title must be 5-100 characters long.
    </b-modal>
    <b-modal ref="modal-2" hide-footer size="sm" title="Walk is Currently Running">
      <p>Can't edit an active walk.</p>
    </b-modal>
  </div>
</template>
<script>

import PrivilegeWalkServices from "@/services/PrivilegeWalkServices";
import version from "@/services/VersionID"
import FolderServices from "@/services/FolderServices";
import {mapState} from "vuex";
import StoreIndex from "@/store/facilitator-portal/_StoreIndex";
import {StoreState} from "@/store/facilitator-portal/state";
import SessionServices from "@/services/SessionServices";
import WalkResultsServices from "@/services/WalkResultsServices";

export default {
  name: "portal",
  data() {
    return {
      version: version.id,
      codename: this.$route.query.name,
      type: 'number',
      pwList: [],
      allFolders: [],
      allWalkFolders: [],
      walksInFolder: [],
      folder_id:'',
      walk_id:'',
      facilitatorFolders: [],
      activePWList: [],
      numParticipants: 1,
      collaborator_email: "",
      hostWalkTitle: "",
      counter: 0,
      add_participants: 1,
      hostWalkID: null,
      hostSessionID: null,
      hostAccessCode: null,
      show: false,
      hidden: true,
      fID: this.$route.query.ID,
      headers: [],
      csv: [],
      csvOUT: [],
      collab_walkID: '',
      form: {
        email: '',
        privileges: null,
      },
      folderNameCreate: '',
      folderNameEdit: null,
      // privileges: [{
      //   text: 'Select One',
      //   value: null
      // }, 'View Walk Results Only', 'View Walk Results and Host Walk', 'View Walk Results, Host Walk and Edit Walk'],
      privileges: [], // Must be an array reference!
      options: [
        {text: 'View Walk Results', value: 'V'},
        {text: 'Host Walk', value: 'H'},
        {text: 'Edit Walk', value: 'E'}
      ],
      admin: false,

    }
  },
  computed: {
    ...mapState(StoreIndex.storeName,{
      id: StoreState.id
    }),
    validation() {
      return this.numParticipants >= 1 && this.numParticipants <= 100
    },
    selectedWalks() {
      return this.pwList.filter(walk => walk.checked);
    },
    walkFoldersForThisFolder() {
      if (typeof this.folder_id === 'undefined') {
        return []; // Return an empty array if folder_id is not defined
      }
      return this.allWalkFolders.filter(walkFolder => walkFolder.folder_id.folder_id === this.folder_id);
    },
    matchingWalks() {
      return this.walkFoldersForThisFolder.map(walkFolder => {

        return this.pwList.find(walk => {
          return walkFolder.walk_id.walk_id === walk.walk_id;
        });

      }).filter(Boolean); // Filter out undefined values

    },
    selectedFolders() {
      return this.allFolders.filter(folder => folder.checked);
    },
  },

  methods: {
    async findAllFolders(facilitator_id){
      try {
        const response = await FolderServices.findFoldersByFacilitatorID(facilitator_id);
        this.allFolders = response.data.map(folder => ({
          folder_name: folder.folder_Name,
          folder_id: folder.folder_id,
          facilitator_id: folder.facilitator_id,
          active: false
        }));
        console.log("Folders:", this.allFolders); // Print after data is fetched
      } catch (error) {
        console.error("Error fetching folders:", error);
        // Handle errors appropriately, e.g., display an error message to the user
      }
    },
    async createFolder() {
      if (!(this.folderNameCreate.length < 100 && this.folderNameCreate.length >= 5)) {
        this.$refs['modal-title'].show()
        return;
      }
      console.log(this.folderNameCreate);
      console.log(this.id);
      await FolderServices.newFolder(this.folderNameCreate, this.id);
      await this.findAllFolders();
      this.$bvModal.hide('modal-10');
      await this.$router.go(0);
    },
    getWalkList(facilitator_id) {
      PrivilegeWalkServices.findWalks(facilitator_id).then((response) => {
        response.data.forEach(walk => this.pwList.push(
                {
                  pwName: walk.pw_name,
                  walk_id: walk.walk_id,
                  createdAt: walk.createdAt,
                  currentVersion: walk.currentVersion,
                  finalized: walk.finalized,
                  inEdit: walk.inEdit,
                  isHidden: walk.hidden,
                  modifiedAt: walk.modifiedAt,
                  walkStatus: walk.walkStatus,
                  facilitator: walk.facilitator_id.username,
                  selected: false,
                }
            )
        )
      })
    },
    async getAllWalkFolderList() {
      await FolderServices.findAllWalkFolders().then((response) => {
        response.data.forEach(walkFolder => this.allWalkFolders.push(
                {
                  walk_folder_id: walkFolder.walk_folder_id,
                  folder_id: walkFolder.folder_id,
                  walk_id: walkFolder.walk_id,
                  selected:false
                }
            )
        )
      })
    },
    async createWalkFolders() {
      // Loop through each walk in selectedWalks array
      for (const walk of this.selectedWalks) {
        // Create a new WalkFolder entity for the current walk and folder
        await FolderServices.newWalkFolder(this.folder_id, walk.walk_id);
      }

      // After creating all walk folders, refresh the list of folders and reload the page
      await this.findAllFolders();
      await this.$router.go(0);
    },
    async refresh(){
      await this.$router.go(0);
    },
    async addWalkToSelectedFolders() {
      // Loop through each walk in selectedWalks array
      for (const folder of this.selectedFolders) {
        // Create a new WalkFolder entity for the current walk and folder
        await FolderServices.newWalkFolder(folder.folder_id, this.walk_id);
      }

      // After creating all walk folders, refresh the list of folders and reload the page
      await this.findAllFolders();
      await this.$router.go(0);
    },
    sendPWID(key) {
      let active = false;
      this.activePWList.forEach(PW => {
        if (PW.walkID === key) {
          active = true;
        }
      })
      if (!active) {
        PrivilegeWalkServices.getEditStatus(key).then(response => {
          if (response.data) {
            this.$refs['modal-edit'].show();
          } else {
            PrivilegeWalkServices.setEditStatus(key, true).then(() => {
              this.$router.push({path: 'createWalk', query: {walkID: key, edit: true}});
            })
          }
        })
      } else {
        this.$refs['modal-2'].show();
      }
    },
    hostWalk() {
      if (this.validation) {
        SessionServices.addSession(this.id, this.numParticipants).then((response) => {
          this.hostSessionID = response.data.id;
          this.hostAccessCode = response.data.access_code;
          console.log("selected portal");
          console.log(this.selected);
          if(this.selected === 'B'){
            SessionServices.changeQuestionNum(this.hostAccessCode, 1);
          }
          else{
            SessionServices.changeQuestionNum(this.hostAccessCode, 0);
          }
          SessionServices.linkSession(this.hostSessionID, this.hostWalkID).then(() => {
            this.$router.push({path: 'waitingRoomHost', query: {code: this.hostAccessCode, id: this.id, selected: this.selected, sessionID: this.hostSessionID}});
          })
        })
      }
    },
    async deleteWalkFolder(folder_id, walk_id) {
      // Find the walk folder with matching folder_id and walk_id
      const walkFolder = this.allWalkFolders.find(walk_folder => walk_folder.folder_id.folder_id === folder_id && walk_folder.walk_id.walk_id === walk_id);
      console.error('All walk folders: ',this.allWalkFolders);
      if (walkFolder) {

        // Call the deleteWalkFolder method in FolderServices with walk_folder_id
        await FolderServices.deleteWalkFolder(folder_id,walk_id);


        // Reload the page
        this.$router.go(0);
      } else {
        // Handle the case where walkFolder is not found
        console.error('Walk folder not found for folder_id:', folder_id, 'and walk_id:', walk_id);
      }
    },
    getActiveWalks() {
      SessionServices.getActiveSessions(this.id).then((response) => {
        response.data.forEach((session, index) =>
          SessionServices.getWalk(session.id).then((responseWalk) => {
            WalkResultsServices.getNumberOfResultsByID(session.id).then(number => {
              this.activePWList.push({
                walk_name: responseWalk.data.pw_name,
                active: false,
                walkID: responseWalk.data.walk_id,
                code: response.data[index].access_code,
                sessionID: session.id,
                in_walk: session.numberOfCurrentParticipants,
                num_submitted: number.data,
                max_submitted: response.data[index].max
              });
            })
          })
        )
      })
    },
    addMoreParticipants(item, num) {
      SessionServices.addMoreParticipants(item.sessionID, num).then(response => {
        item.max_submitted = response.data.max;
      });
    },
    goToResults(code) {
      this.$router.push({path: 'results', query: {roomKey: code, id: this.id}})
    },
    endActiveWalk(sessionID) {
      SessionServices.deactivateSession(sessionID)
      this.$router.go();
    },
    goToPortal() {
      this.$router.push({path: 'portal', query: {id: this.fID}});
    },
  },
  created() {
    this.findAllFolders(this.$route.query.ID);
    this.getWalkList(this.$route.query.ID);
    this.getAllWalkFolderList();
    localStorage.clear();
  },
  mounted() {
    setInterval(() => {
      // Execute the function periodically
      for (const folder of this.allFolders) {
        if(folder.folder_id!==this.folder_id){
          folder.active=false;
        }
      }
    }, 500);
    this.getActiveWalks();
  }
}
</script>
<style scoped>
/*h1 {*/
/*  font-size: clamp(30px, 10vw, 60px);*/
/*}*/

/*p {*/
/*  font-size: clamp(12px, 5vw, 28px) !important;*/
/*}*/

.btn-group-xs > .btn, .btn-xxs {
  padding: .05rem .1rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.hover-red:hover {
  background-color: red;
  color: white; /* Change text color to ensure visibility */
}

.btn-group-xs > .btn, .btn-xs {
  padding: .3rem .45rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

body {
  padding: 20px;
}

h1 {
  color: darkcyan;
}

.badge {
  font-size: 15px;
  position: inherit;
  margin-top: 0.12rem;
}

.portalView {
  padding: 1.5rem;
  margin-right: 20%;
  margin-left: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
}

.walk-table {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.walk-name {
  margin-left: 2rem;
  float: left;
  position: absolute;
  font-weight: normal;
  font-size: x-large;
}

.hr {
  display: inline-block;
}

.hr:after {
  content: '';
  display: block;
  border-top: 1px solid dimgray;
  margin-top: 0.2em;
}

hr {
  margin-bottom: 0;
  margin-top: 5px;
  margin-right: 1rem;
  border: none;
  height: 1px;
  color: #333;
  background-color: #333;
}

li {
  display: block;
}
</style>