export const StoreGetters = {
    getSelectedQuestions: 'getSelectedQuestions'
};
export default {
    getSelectedQuestions: state => {
        return state.questions.filter(question => {
            return question.added === true && !question.inWalk;
        })
    }
};
