<template>
  <div>
    <title-bar></title-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import titleBar from './components/titleBar.vue'

export default {
  name: 'App',
  components: {
    titleBar
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  background: #e8e8e8 !important;
  font-family: 'Poppins';
}
</style>
