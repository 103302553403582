import axios from 'axios'

const WALK_RESPONSE_API_URL = "/api/walkresponse"

class WalkResponseServices {
    submitResponse(questionListCollectionId,participant_id,response) {
        return axios.post(WALK_RESPONSE_API_URL + '/add/question/'+ questionListCollectionId + '/participant/' + participant_id +  '/response/' + response);
    }
}

export default new WalkResponseServices()