<template>
  <div>
    <div class="box">
      <b-container fluid>
        <b-row align-v="center" style="margin-bottom: -1rem">
          <b-col>
            <p class="title">{{ WalkTitle }}</p>
            <p class="room-key">Room Code: {{ roomKey }}</p>
          </b-col>
          <b-col class="text-right">
            <p>
              <b-button style="margin-right: 1rem" variant="outline-primary" @click="refreshGetData()">Refresh</b-button>
              <b-button style="margin-right: 1rem" variant="outline-secondary" @click="goToPortal()">Back to Portal</b-button>
              <b-button variant="outline-secondary" @click="individualQuestionScores()">Individual Question Scores</b-button>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-form-group v-slot="{ ariaDescribedby }" style="margin-left: 1rem;">
            <b-form-radio-group
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                :options="options"
                name="plain-inline"
                plain
                @change="changeGraph"
            ></b-form-radio-group>
          </b-form-group>
        </b-row>
      </b-container>

      <!--      <p class="title">{{ WalkTitle }}</p>-->
      <!--      <div class="button">-->
      <!--        <div class="d-flex align-items-start">-->
      <!--          <b-button variant="outline-primary" @click="reload()" style="margin-right: 1rem">Refresh</b-button>-->
      <!--          <b-button variant="outline-secondary" @click="goToPortal()">Back to Portal</b-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div><p class="room-key">Room Code: {{ roomKey }}</p></div>-->
      <!--      <p></p>-->
    </div>
    <p></p>
    <b-overlay :show="isLoading" rounded="sm">

      <BarChart :chart-data="datacollection" :chart-options="dataoptions"
                style="margin-left: 3%; margin-right: 3%; height: 60vh"></BarChart>
    </b-overlay>
  </div>
</template>

<script>
import BarChart from "@/services/BarChart";
import SessionServices from "@/services/SessionServices";
import WalkResultsServices from "@/services/WalkResultsServices";
import Papa from "papaparse";
import PrivilegeWalkServices from "@/services/PrivilegeWalkServices";
import QuestionListCollectionServices from "@/services/QuestionListCollectionServices";

export default {
  components: {
    BarChart
  },
  data() {
    return {
      roomKey: this.$route.query.roomKey,
      id: this.$route.query.id,
      WalkTitle: "",
      datacollection: null,
      dataoptions: null,
      csv: [],
      csvRows: [],
      headers: [],
      csvOUT: '',
      sessionID: null,
      counterInterval: null,
      clockInterval: null,
      selected: 'N',
      options: [
        {text: 'Chronological', value: 'N'},
        {text: 'Shuffle Results', value: 'S'},
        {text: 'Results Ascending', value: 'A'},
        {text: 'Results Descending', value: 'D'}
      ],
      codenames: [],
      steps: [],
      data_length: 0,
      walk_results_id: null,
      questionResponses: [],
      min: 0,
      max: 0,
      numQuestions: 0,
      lastCodename: "placeholder",
      codenameCount: 0,
      currentQuestionText: "",
      originalCodenames: [],
      originalSteps: [],
      firstLoad: false,
      codenameStore: [],
      numCodenameStore: [],
      codenameNames: [],
      codenameExists: false,
      isLoading: false,
      walkType: this.$route.query.walkType,
    }
  },
  methods: {
    showMsgBox(mess) {
      this.$bvModal.msgBoxOk(mess, {
        title: 'Distribution of Answers for each question',
        size: 'sm',
        buttonSize: 'md',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
    individualQuestionScores(){
      this.$router.push({path: 'responsesPerQuestion', query: {roomKey: this.roomKey, id: this.id, sessionID: this.sessionID}});

      },
    async getData() {
      this.isLoading = true;
      if (this.sessionID === null) {
        await SessionServices.getSessionByAccessCode(this.roomKey).then(response => {
          this.sessionID = response.data.id;
        })
        //await this.getNumQuestions();
      }
      if (this.walk_results_id === null) {
        this.codenameCount = 0;
        await this.getGraphData();
      } else {
        // Check if the latest walk results id is the latest in the database
        await WalkResultsServices.checkResults(this.sessionID, this.walk_results_id).then(async response => {

          // If there is a difference latest results id in the database
          //      grab the latest results id and redraw graph
          if (response.data === true) {
            this.codenames = [];
            this.steps = [];
            this.originalSteps = [];
            this.originalCodenames = [];
            this.codenameStore = [];
            this.numCodenameStore = [];
            this.codenameCount = 0;
            await this.getGraphData();
          }
        });
      }

      setTimeout(() => {
        this.getData();
        this.isLoading = false;
      }, 10000);
    },
    // This method will grab the data outside the usual timer.
    //   It is only called when the Refresh button is pressed.
    //   A check may need to be added later to prevent this from running if the initial
    //   page setup never ended, but it probably isn't necessary unless the server is
    //   running slowly.
    async refreshGetData() {
      this.isLoading = true;
      if (this.firstLoad === true) {
        if (this.sessionID === null) {
          await SessionServices.getSessionByAccessCode(this.roomKey).then(response => {
            this.sessionID = response.data.id;
          })
          //await this.getNumQuestions();
        }
        if (this.walk_results_id === null) {
          this.codenameCount = 0;
          await this.getGraphData();
        } else {
          // Check if the latest walk results id is the latest in the database
          await WalkResultsServices.checkResults(this.sessionID, this.walk_results_id).then(async response => {

            // If there is a difference latest results id in the database
            //      grab the latest results id and redraw graph
            if (response.data === true) {
              this.codenames = [];
              this.steps = [];
              this.originalSteps = [];
              this.originalCodenames = [];
              this.codenameStore = [];
              this.numCodenameStore = [];
              this.codenameCount = 0;
              await this.getGraphData();
            }
          });
        }
      }
      this.isLoading = false;
    },
    async getGraphData() {
      this.isLoading = true;
      if (this.numQuestions === 0) {
        await this.getNumQuestions();
      }
      await WalkResultsServices.getResults(this.sessionID).then(async response => {
        for (let i = 0; i < response.data.length; i++) {
          for (let cS = 0; cS < this.codenameStore.length; cS++){
            if (this.codenameStore[cS].walkResponse.participant.codename === response.data[i].walkResponse.participant.codename) {
              this.codenameExists = true;
              this.numCodenameStore[cS] += 1;
              cS = this.codenameStore.length;
            }
          }
          if (this.codenameExists === false) {
            this.codenameStore.push(response.data[i]);
            this.numCodenameStore.push(1);
          }
          this.codenameExists = false;
          this.walk_results_id = response.data[response.data.length - 1].id;
        }
        let currentQuestionNumResponse;
        for (let i = 0; i < this.codenameStore.length; i++) {
          await SessionServices.getCurrentQuestionNum(this.sessionID).then((resp) => {
            if (resp.data === 0) {
              currentQuestionNumResponse = 0;
            }
          });
          console.log("CurrentQuestionNumberResponse: " + currentQuestionNumResponse);

          if(this.numCodenameStore[i] === this.numQuestions && currentQuestionNumResponse === 0 || this.walkType === "f" && this.numCodenameStore[i] >= 1) { //if(this.numCodenameStore[i] === this.numQuestions) {
            console.log(currentQuestionNumResponse);
            this.codenames.push(this.codenameStore[i].walkResponse.participant.codename);
            this.originalCodenames.push(this.codenameStore[i].walkResponse.participant.codename);
            if (this.codenameStore[i].walkResult.totalSteps === 0){
              this.steps.push(0.25);
              this.originalSteps.push(0.25);
            } else {
              this.steps.push(this.codenameStore[i].walkResult.totalSteps);
              this.originalSteps.push(this.codenameStore[i].walkResult.totalSteps);
            }
          }
        }
        this.changeGraph();
      })
      this.isLoading = false;
    },
    async getNumQuestions() {

      await SessionServices.getWalk(this.sessionID).then(async (resp) => {
        await QuestionListCollectionServices.getQuestionsCollectionFromWalkAndVersion(resp.data.walk_id, resp.data.currentVersion).then(async res => {
          this.firstLoad = false;
          await res.data.forEach(q => {
            this.currentQuestionText = q.questionText;
            this.numQuestions += 1;
          })
          this.firstLoad = true;
        })
      })

    },
    getWalkTitle() {
      SessionServices.getWalkTitleFromAccessCode(this.roomKey).then(response => {
        this.WalkTitle = response.data
      })
      SessionServices.getSessionByAccessCode(this.roomKey).then(respon => {
        SessionServices.getWalk(respon.data.id).then(response => {
          PrivilegeWalkServices.getMin(response.data.walk_id).then(resp => {
            this.min = resp.data
          })
          PrivilegeWalkServices.getMax(response.data.walk_id).then(res => {
            this.max = res.data
          })
        })
      })
    },
    parseFile(file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.forEach(point => {
            if (!this.codenames.includes(point.codename)) {
              this.codenames.push(point.codename)
              this.steps.push(point.step)
            }
          })
        }.bind(this),
      });

      // If their final step value is 0 change it to 0.25
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i] === '0') {
          this.steps.splice(i, 1, '0.25');
        }
      }

      // Update Graph
      this.changeGraph()
    },
    changeGraph() {
      if(this.selected === 'N'){  // Chronological
        this.chronological();
      } else if (this.selected === 'S') {  // Shuffle Results
        this.shuffleArray();
      } else if (this.selected === 'A') {  // Results Ascending
        this.sort('A');
      } else if (this.selected === 'D') {  // Results Descending
        this.sort('D')
      }
      this.datacollection = {
        labels: this.codenames,
        datasets: [
          {
            label: 'Steps',
            backgroundColor: "#73be73",
            data: this.steps
          }
        ]
      };
      this.dataoptions = {}
    },
    chronological() {
      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i] = this.originalSteps[i];
        this.codenames[i] = this.originalCodenames[i];
      }
    },
    sort(type) {
      let map = [];

      // Map steps with codename for sorting
      for (let i = 0; i < this.steps.length; i++) {
        map.push({
          step: this.steps[i],
          label: this.codenames[i]
        });
      }
      if (type === 'A') {
        // Sort the map into ascending order
        map.sort(function (a, b) {
          return parseFloat(a.step) - parseFloat(b.step);
        });
      } else {
        // Sort the map into descending order
        map.sort(function (a, b) {
          return parseFloat(b.step) - parseFloat(a.step);
        });
      }

      // Put map back into graph variables
      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i] = map[i].step;
        this.codenames[i] = map[i].label;
      }
    },
    shuffleArray() {
      for (let i = this.steps.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = this.steps[i];
        let temp2 = this.codenames[i];
        this.steps[i] = this.steps[j];
        this.codenames[i] = this.codenames[j];
        this.steps[j] = temp;
        this.codenames[j] = temp2;
      }
    },
    reload() {
      this.$router.go()
    },
    goToPortal() {
      this.$router.push({path: 'portal', query: {id: this.id}})
    }
  },
  created() {
    this.getWalkTitle();
    this.walk
    //this.getNumQuestions();
    this.getData();
    //this.getData().then(() => {
    //  this.getGraphData();  //Is this line needed???
    //});
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.counterInterval);
    next();
  }
}
</script>

<style scoped>
.box {
  margin-top: 85px;
  padding: 5px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.title {
  /*float: left;*/
  margin-bottom: -0.5rem;
  font-size: 30px;
  font-weight: 500;
}

.room-key {
  font-size: larger;
  /*float: left;*/
  /*margin-top: 0.7rem;*/
  /*margin-bottom: -1px;*/
}
</style>