<template>
  <div style="margin-top: 50px">
    <b-overlay :show="ifFacilitatorNotSet" rounded="sm">
      <div class="jumbotron" style="background-color: transparent; margin-bottom: -1rem">
        <h1 class="display-4 hr">{{ this.capitalizeFirstLetter(codename) }}'s Facilitator Portal</h1>
        <p style="font-size: x-large">{{ pwList.length }} Walks Created</p>
        <p style="font-size: large; margin-top: -1rem">Version: {{ version }}</p>
        <span style="display: flex">
          <b-button :to="{ path: 'createWalk' }" variant="success">Create Walk</b-button>
          <b-button v-if=admin style="margin-left: 0.3rem" :to="{path: 'admin', query: {ID: id}}" variant="info">Admin Page</b-button>
          <b-button :to="{path: 'folders', query: {ID: id}}" style="margin-left: 0.3rem" variant="warning" >Folder View</b-button>


        </span>
      </div>
      <div>
        <b-row style="margin-left: 0.8rem; margin-right: 0.8rem">
          <div class="col-xl-8">
            <h2 style="position: center"> My Walks </h2>
            <div v-for="item in pwList" :key="item.walkID" class="walk-table">
              <b-card
                  style="background-color: transparent; border: none;!important">
                <b-media>
                  <template #aside>
                    <p></p>
                  </template>
                  <div>
                    <b-row>
                      <b-col class="text-left">
                        <button class="btn btn-outline-secondary btn-xxs"
                                style="margin-right: -1.5rem; margin-top: 0.5rem"
                                type="button"
                                @click="item.active = !item.active; getResults(item, item.csv)">
                          <span v-if="item.active">
                            <b-icon icon="caret-down-fill" scale="1"></b-icon>
                          </span>
                          <span v-else>
                            <b-icon icon="caret-right" scale="1"></b-icon>
                          </span>
                        </button>
                        <span class="mt-0 walk-name">{{ item.walk_name }}
                          <span v-if="item.walk_owner" class="badge">
                            <div style="display: flex;">
                              <div>
                                <b-badge variant="secondary">Collaborator</b-badge>
                              </div>
                              <div v-if="!item.finalized" style="margin-left: 0.3rem">
                                <b-badge variant="info">Not Finalized</b-badge>
                              </div>
                            </div>
                          </span>
                          <span v-else-if="!item.finalized" class="badge">
                            <b-badge variant="info">Not Finalized</b-badge>
                          </span>
                        </span>
                        <b-button-group v-if="item.priv === 'VE' || item.priv === 'EV' || item.priv === 'E'"
                                        style="float: right;">
                          <b-button variant="outline-dark" @click="sendPWID(item.walkID)">Edit</b-button>
                        </b-button-group>
                        <b-button-group v-else-if="item.priv === 'VH' || item.priv === 'HV' || item.priv === 'H'"
                                        style="float: right">
                          <b-button v-b-modal.modal-1 variant="outline-dark"
                                    @click="hostWalkTitle=item.walk_name; hostWalkID=item.walkID">Host
                          </b-button>
                        </b-button-group>
                        <b-button-group v-else-if="item.priv === 'V'" style="float: right; margin-bottom: 2.38rem">
                        </b-button-group>
                        <b-button-group v-else style="float: right">
                          <b-button v-if="item.finalized" v-b-modal.modal-1
                                    variant="outline-dark"
                                    @click="hostWalkTitle=item.walk_name; hostWalkID=item.walkID">Host
                          </b-button>
                          <b-button v-else v-b-modal.modal-8
                                    variant="outline-dark"
                                    @click="hostWalkTitle=item.walk_name; hostWalkID=item.walkID">Host
                          </b-button>
                          <b-button variant="outline-dark" @click="sendPWID(item.walkID)">Edit</b-button>
                        </b-button-group>
                      </b-col>
                    </b-row>
                  </div>
                  <b-media v-if="item.active">
                    <template #aside>
                      <p></p>
                    </template>
                    <hr>
                    <span v-if="item.walk_owner" style="font-size: larger">Walk Owner: <i>{{ item.walk_owner }}</i></span>
                    <span v-if="item.collabs" style="font-size: larger">Collaborators: <i>{{ item.collabs }}</i></span>
                    <h5 class="mt-0">Walk Status: <span style="font-weight: 300">{{ item.walkstatus }}</span></h5>
                    <b-button v-if="!item.priv" v-b-modal.modal_4 size="sm" style="margin-bottom: 0.3rem"
                              variant="primary" @click="collab_walkID = item.walkID">Add
                      Collaborator
                    </b-button>
                    <span v-if="item.priv !== 'HE' && item.priv !== 'E' && item.priv !== 'H'">
                    <h5 class="mt-0">Results</h5>
                    <div v-for="results in item.csv" :key="results.name">
                      <!--                    <li><a href="#" @click="downloadCSV(results.Name, results.CSV)"><span style="font-weight: normal">(Download)</span></a> {{ results.Name }}</li>-->
                      <li>
                        <b-icon icon="arrow-return-right"></b-icon>
                        {{ results.Name }}
                      </li>
                      <ul style="margin-bottom: -0.01rem">
                        <li>
                          <b-icon icon="arrow-return-right"></b-icon>
                          <button class="btn btn-success btn-xs" style="margin-right: 2px" type="button"
                                  @click="goToResults(results.code)">View
                            Graph
                          </button>
                          <button v-b-modal.modal-3 class="btn btn-primary btn-xs" type="button"
                                  @click="downloadCSV(results.Name, results.CSV)">Download CSV
                          </button>
                        </li>
                      </ul>
                    </div>
                    </span>
                  </b-media>
                </b-media>
              </b-card>
            </div>
          </div>

          <div class="col-xl-4 order-first order-xl-last">
            <h2>Active Walks</h2>
            <div v-for="item in activePWList" :key="item.walkID" class="walk-table">
              <b-card
                  style="background-color: transparent; border: none; !important">
                <b-media>
                  <template #aside>
                    <p></p>
                  </template>
                  <div class="d-flex align-items-center">
                    <button class="btn btn-outline-secondary btn-xxs" type="button" @click="item.active = !item.active">
                      <div v-if="item.active">
                        <b-icon icon="caret-down-fill" scale="1"></b-icon>
                      </div>
                      <div v-else>
                        <b-icon icon="caret-right" scale="1"></b-icon>
                      </div>
                    </button>
                    <div class="mt-0 walk-name">{{ item.walk_name }}</div>
                  </div>
                  <b-media v-if="item.active">
                    <template #aside>
                      <p></p>
                    </template>
                    <hr style="margin-left: 1rem;">
                    <b-container fluid>
                      <p style="margin-bottom: 5px; margin-top: 0.1rem">Participants in Walk:
                        {{ item.in_walk - item.num_submitted }}</p>
                      <p style="margin-bottom: 5px;">
                        Add
                        <b-form-spinbutton id="sb-inline" v-model="add_participants" inline max="50" min="1"
                                           size="sm"></b-form-spinbutton>
                        Participants
                        <b-button size="sm" variant="success" @click="addMoreParticipants(item, add_participants)">Add
                        </b-button>
                      </p>
                      <p style="margin-bottom: -0.1rem; margin-top: 0.1rem">Responses Completed: {{ item.num_submitted }}
                        of {{ item.max_submitted }}</p>
                      <b-progress :max="item.max_submitted" height="1.2rem" style="margin-bottom: 0.2rem"
                                  variant="success">
                        <b-progress-bar :value="item.num_submitted">
                          <!--                        <span><strong>{{ item.num_submitted }} of {{ item.max_submitted }}</strong></span>-->
                        </b-progress-bar>
                      </b-progress>

                      <b-row>
                        <b-col cols="6">
                          <p class="mt-2" style="font-size: large">Room Key: {{ item.code }}</p>
                        </b-col>
                        <b-col class="text-right" cols="6" style="margin-top: 0.5rem">
                          <b-button size="sm" style="margin-right: 0.3rem" variant="primary"
                                    @click="goToResults(item.code)">Results
                          </b-button>
                          <b-button size="sm" variant="danger" @click="endActiveWalk(item.sessionID)">End</b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-media>
                </b-media>
              </b-card>
            </div>
          </div>
        </b-row>
      </div>


      <b-modal id="modal-1" title="Host Privilege Walk" @ok="hostWalk">
        <div>
          <h3>{{ hostWalkTitle }}</h3>
          <p>Number of Participants</p>
          <b-form-input v-model="numParticipants" :state="validation" :type="type" max="100" min="1"></b-form-input>
          <div>
            <b-form-group label="Walk Pace" v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="A">Participant</b-form-radio>
              <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="B">Facilitator</b-form-radio>
            </b-form-group>
          </div>
        </div>
      </b-modal>
      <b-modal ref="modal-2" hide-footer size="sm" title="Walk is Currently Running">
        <p>Can't edit an active walk.</p>
      </b-modal>
      <b-modal id="modal-3" hide-footer size="xl" title="Open CSV in Excel">
        <p>These are the steps to open the csv in excel</p>
        <p><b>Step 1: </b>Open the CSV file in a text file and copy everything in that file.</p>
        <p><b>Step 2: </b>Open a new excel document. Click once on cell A1 and paste.</p>
        <p><b>Step 3: </b>Click on the clipboard icon, "Paste Options". Then click "Use Text Import Wizard..".</p>
        <p><b>Step 4: </b>Click "Delimited". Make sure "Start import at row" is 1 and "File origin" is Windows (ANSI).
          Then click next.</p>
        <p><b>Step 5: </b>Under Delimiters make sure only "Other" is selected and set it to a pipe character, "|". Then
          click
          next.</p>
        <p><b>Step 6: </b>Under Column data format click "General". Then click Finish.</p>
      </b-modal>
      <b-modal id="modal_4" hide-footer title="Add Collaborator">
        <p>Add a facilitator to collaborate on your walk.</p>
        <b-form @submit.stop.prevent="addCollaborator(); $bvModal.hide('modal-4')">
          <b-form-group
              id="input-group-1"
              label="Email address:"
              label-for="input-1"
          >
            <b-form-input
                id="input-1"
                v-model="form.email"
                placeholder="Enter email"
                required
                type="email"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-3" v-slot="{ ariaDescribedby }" label="Allowed Privileges:" label-for="input-3">
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="privileges"
                :aria-describedby="ariaDescribedby"
                :options="options"
                name="flavour-1"
            ></b-form-checkbox-group>
          </b-form-group>
          <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
      </b-modal>
      <b-modal ref="modal_5" hide-footer title="Already a Collaborator" @hide="$bvModal.show('modal_4')">
        <p>That facilitator is already a collaborator.</p>
      </b-modal>
      <b-modal ref="modal_6" hide-footer title="No Facilitator" @hide="$bvModal.show('modal_4')">
        <p>There is no facilitator with that email.</p>
      </b-modal>
      <b-modal ref="modal_7" hide-footer title="Collaborator Error" @hide="$bvModal.show('modal_4')">
        <p>Cannot add yourself as a collaborator.</p>
      </b-modal>
      <b-modal id="modal-8" ref="modal_8" hide-footer title="Walk Not Finalized">
        <p>Are you sure you want to host an unfinalized walk.</p>
        <b-button-group style="float: right">
          <b-button variant="primary" @click="$bvModal.hide('modal-8'); $bvModal.show('modal-1')">Yes</b-button>
          <b-button variant="primary" @click="$bvModal.hide('modal-8')">No</b-button>
        </b-button-group>
      </b-modal>
      <b-modal ref="modal-edit" hide-footer title="Walk Edit">
        Walk is currently being edited by walk owner or collaborator. Please wait.
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>
import FacilitatorServices from "@/services/FacilitatorServices";
import PrivilegeWalkServices from "@/services/PrivilegeWalkServices";
import SessionServices from "@/services/SessionServices";
import version from "@/services/VersionID"
import WalkResultsServices from "@/services/WalkResultsServices";
import {mapActions, mapState} from "vuex";
import StoreIndex from "@/store/facilitator-portal/_StoreIndex"
import {StoreActions} from "@/store/facilitator-portal/actions";
import {StoreState} from "@/store/facilitator-portal/state";

export default {
  name: "portal",
  data() {
    return {
      version: version.id,
      codename: "",
      type: 'number',
      pwList: [],
      activePWList: [],
      numParticipants: 1,
      collaborator_email: "",
      hostWalkTitle: "",
      counter: 0,
      add_participants: 1,
      hostWalkID: null,
      hostSessionID: null,
      hostAccessCode: null,
      show: false,
      hidden: true,
      headers: [],
      csv: [],
      csvOUT: [],
      collab_walkID: '',
      form: {
        email: '',
        privileges: null,
      },
      // privileges: [{
      //   text: 'Select One',
      //   value: null
      // }, 'View Walk Results Only', 'View Walk Results and Host Walk', 'View Walk Results, Host Walk and Edit Walk'],
      privileges: [], // Must be an array reference!
      options: [
        {text: 'View Walk Results', value: 'V'},
        {text: 'Host Walk', value: 'H'},
        {text: 'Edit Walk', value: 'E'}
      ],
      admin: false,
      selected: 'A'
    }
  },
  computed: {
    ...mapState(StoreIndex.storeName,{
      id: StoreState.id
    }),
    validation() {
      return this.numParticipants >= 1 && this.numParticipants <= 100
    },
    ifFacilitatorNotSet() {
      return this.codename === ""
    },
  },
  methods: {
    ...mapActions(StoreIndex.storeName, {
      checkLogin: StoreActions.checkSession
    }),
    addMoreParticipants(item, num) {
      SessionServices.addMoreParticipants(item.sessionID, num).then(response => {
        item.max_submitted = response.data.max;
      });
    },
    async getFacilitatorNameByID() {
      await FacilitatorServices.getFacilitatorByID(this.id).then(response => {
        this.admin = response.data.admin;
        this.codename = response.data.username;
      })
    },
    sendPWID(key) {
      let active = false;
      this.activePWList.forEach(PW => {
        if (PW.walkID === key) {
          active = true;
        }
      })
      if (!active) {
        PrivilegeWalkServices.getEditStatus(key).then(response => {
          if (response.data) {
            this.$refs['modal-edit'].show();
          } else {
            PrivilegeWalkServices.setEditStatus(key, true).then(() => {
              this.$router.push({path: 'createWalk', query: {walkID: key, edit: true}});
            })
          }
        })
      } else {
        this.$refs['modal-2'].show();
      }
    },
    async findWalks() {
      await PrivilegeWalkServices.findWalkCollabByID(this.id).then(response => {
        response.data.forEach(walk => {
          let priv = "";
          walk.collaborator.forEach(collab => {
            if (collab.collaborators.user_id === this.id) {
              priv = collab.privileges;
            }
          })

          if (!walk.hidden) {
            this.pwList.push({
              walk_name: walk.pw_name,
              walk_owner: walk.facilitator_id.username,
              priv: priv,
              active: false,
              finalized: walk.finalized,
              walkID: walk.walk_id,
              csv: [],
              walkstatus: walk.walkStatus
            })
          }
        })
      })

      await PrivilegeWalkServices.findWalks(this.id).then((response) => {
        response.data.forEach(walk => {
          let array_collab = []
          walk.collaborator.forEach(collab => {
            array_collab.push(collab.collaborators.username);
          })
          let regex = /["[]]/i;
          let string = array_collab.toString().replace(regex, "");

          if (!walk.hidden) {
            this.pwList.push({
              walk_name: walk.pw_name,
              active: false,
              finalized: walk.finalized,
              collabs: string.replaceAll(",", ", "),
              walkID: walk.walk_id,
              csv: [],
              walkstatus: walk.walkStatus
            })
          }
        })
      })
    },
    hostWalk() {
      if (this.validation) {
        SessionServices.addSession(this.id, this.numParticipants).then((response) => {
          this.hostSessionID = response.data.id;
          this.hostAccessCode = response.data.access_code;
          console.log("selected portal");
          console.log(this.selected);
          if(this.selected === 'B'){
            SessionServices.changeQuestionNum(this.hostAccessCode, -1);
          }
          else{
            SessionServices.changeQuestionNum(this.hostAccessCode, 0);
          }
          SessionServices.linkSession(this.hostSessionID, this.hostWalkID).then(() => {
            this.$router.push({path: 'waitingRoomHost', query: {code: this.hostAccessCode, id: this.id, selected: this.selected, sessionID: this.hostSessionID}});
          })
        })
      }
    },
    getActiveWalks() {
      SessionServices.getActiveSessions(this.id).then((response) => {
        response.data.forEach((session, index) =>
            SessionServices.getWalk(session.id).then((responseWalk) => {
              WalkResultsServices.getNumberOfResultsByID(session.id).then(number => {
                this.activePWList.push({
                  walk_name: responseWalk.data.pw_name,
                  active: false,
                  walkID: responseWalk.data.walk_id,
                  code: response.data[index].access_code,
                  sessionID: session.id,
                  in_walk: session.numberOfCurrentParticipants,
                  num_submitted: number.data,
                  max_submitted: response.data[index].max
                });
              })
            })
        )
      })
    },
    endActiveWalk(sessionID) {
      SessionServices.deactivateSession(sessionID)
      this.$router.go();
    },
    goToResults(code) {
      this.$router.push({path: 'results', query: {roomKey: code, id: this.id}})
    },
    async getResults(walk, csv) {
      if (csv.length > 0) {
        return;
      }

      await SessionServices.getInactiveSessionByWalkID(walk.walkID).then(async response => {
        for (const data of response.data) {
          await WalkResultsServices.getCsvResults(data.id).then(async respon => {
            if (respon.data !== "") {
              await SessionServices.getDate(data.id).then(date => {
                walk.csv.push({
                  Name: walk.walk_name + "-" + date.data,
                  CSV: respon.data,
                  code: data.access_code,
                  date: date.data
                });
              });
            }
          });
        }
      });
    },
    downloadCSV(name, csv) {
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = name.toString() + '.csv';
      anchor.click();
    },
    async addCollaborator() {
      await FacilitatorServices.getFacilitatorIDByEmail(this.form.email).then(async response => {
        if (response.data === "") {
          this.$refs['modal_6'].show();
          return;
        } else if (response.data === this.id) {
          this.$refs['modal_7'].show();
          return;
        }
        let priv = "";

        if (this.privileges.length === 0) {
          return;
        } else if (this.privileges.length === 1) {
          priv = this.privileges[0];
        } else if (this.privileges.length === 2) {
          priv = this.privileges[0] + this.privileges[1];
        } else {
          priv = "VHE";
        }

        await PrivilegeWalkServices.addCollab(this.collab_walkID, response.data, priv).then(res => {
          if (res.data === "") {
            return this.$refs['modal_5'].show();
          }
          this.$router.go();
        })
      })
    },
    checkIfLoggedIn() {
      this.checkLogin().then(() => {
        try {
          if (this.id === null) {
            this.$router.push({name: 'landingPage'})
          } else {
            this.getFacilitatorNameByID();
            this.findWalks();
            this.getActiveWalks();
            localStorage.clear();
          }
        } catch (error) {
          console.error(error);
        }
      })
    },
    capitalizeFirstLetter(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
  mounted() {
    console.log(this.id)
    if (this.id === null) {
      this.checkIfLoggedIn()
    } else {
      this.getFacilitatorNameByID();
      this.findWalks();
      this.getActiveWalks();
      localStorage.clear();
    }
  }
}
</script>

<style scoped>
/*h1 {*/
/*  font-size: clamp(30px, 10vw, 60px);*/
/*}*/

/*p {*/
/*  font-size: clamp(12px, 5vw, 28px) !important;*/
/*}*/

.btn-group-xs > .btn, .btn-xxs {
  padding: .05rem .1rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .3rem .45rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

body {
  padding: 20px;
}

h1 {
  color: darkcyan;
}

.badge {
  font-size: 15px;
  position: inherit;
  margin-top: 0.12rem;
}

.portalView {
  padding: 1.5rem;
  margin-right: 20%;
  margin-left: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
}

.walk-table {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}


.walk-name {
  margin-left: 2rem;
  float: left;
  position: absolute;
  font-weight: normal;
  font-size: x-large;
}

.hr {
  display: inline-block;
}

.hr:after {
  content: '';
  display: block;
  border-top: 1px solid dimgray;
  margin-top: 0.2em;
}

hr {
  margin-bottom: 0;
  margin-top: 5px;
  margin-right: 1rem;
  border: none;
  height: 1px;
  color: #333;
  background-color: #333;
}

li {
  display: block;
}

.userManualButton {
  position: absolute;
  top: 100px;
  right: 100px;
}
</style>
