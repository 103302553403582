import {StoreState} from "@/store/landing-page/state";
import {router} from "@/main";

export const StoreMutations = {
  SET_LOGIN_SELECTED: 'SET_LOGIN_SELECTED'
};

export default {
  SET_LOGIN_SELECTED(state, payload) {
    state[StoreState.loginSelected] = payload;
    router.push({name: 'landingPage'})
  }
};
