import axios from 'axios'

const SESSION_API_URL = "/api/"

class SessionServices {
    getCurrentQuestionNum(id){
        return axios.get(SESSION_API_URL + "session/currentQuestionNum/" + id);
    }
    changeQuestionNum(accessCode, questionNum){
        return axios.post(SESSION_API_URL + "session/changeQuestionNum/" + accessCode + "/" + questionNum);
    }
    addSession(facilitatorId, maxParticipants){
        return axios.post(SESSION_API_URL + "session/add/" + facilitatorId + "/" + maxParticipants);
    }
    linkSession(sessionId, walkID){
        return axios.post(SESSION_API_URL + "sessionprivilegewalkcollection/add/session/" + sessionId + "/privilegewalk/" + walkID);
    }
    getActiveSessions(facilitatorId){
        return axios.get(SESSION_API_URL + "session/active_session/" + facilitatorId);
    }
    getSessions(facilitatorId){
        return axios.get(SESSION_API_URL + "session/all_session/" + facilitatorId);
    }
    getWalk(sessionID){
        return axios.get(SESSION_API_URL + "sessionprivilegewalkcollection/get_walk/" + sessionID);
    }
    getSessionByAccessCode(accessCode){
        return axios.get(SESSION_API_URL + "session/get_accessCode/" + accessCode);
    }
    addParticipantToSession(userID, accessCode) {
        return axios.post(SESSION_API_URL + "sessionparticipantcollection/session/add/participants/" + userID +"/access_code/" + accessCode);
    }
    getWalkIDFromSessionID(sessionID) {
        return axios.get(SESSION_API_URL + "sessionprivilegewalkcollection/get_walk/" + sessionID);
    }
    deactivateSession(session_id){
        return axios.put(SESSION_API_URL + "session/id/" + session_id + "/disable");
    }
    addMoreParticipants(session_id, num_participants){
        return axios.put(SESSION_API_URL + "session/add/moreparticipants/" + session_id + "/" + num_participants);
    }
    getSessionByWalkID(walkID){
        return axios.get(SESSION_API_URL + "sessionprivilegewalkcollection/sessions/privilegewalks/" + walkID);
    }
    getDate(session_id){
        return axios.get(SESSION_API_URL + 'session/date/' + session_id);
    }
    getWalkIDFromAccessCode(access_code) {
        return axios.get(SESSION_API_URL + "sessionprivilegewalkcollection/get_walk_id/" + access_code);
    }
    getWalkTitleFromAccessCode(access_code) {
        return axios.get(SESSION_API_URL + "sessionprivilegewalkcollection/get_walk_title/" + access_code);
    }
    getInactiveSessionByWalkID(walkID){
        return axios.get(SESSION_API_URL + "sessionprivilegewalkcollection/sessions/inactive/privilegewalks/" + walkID);
    }
}

export default new SessionServices()