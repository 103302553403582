<template>
  <div style="margin-top: 70px;">
    <div style="left: 0; width: 113px; height: 100vh; background-color: blue; position: fixed;">

    </div>
    <b-card no-body style="min-height: 90vh; border-color: transparent; background-color: transparent !important;">
      <b-tabs pills card vertical style="min-height: 90vh; background-color: transparent !important;">
        <b-tab title="Tab 1" active><b-card-text>Tab contents 1</b-card-text></b-tab>
        <b-tab title="Tab 2"><b-card-text>Tab contents 2</b-card-text></b-tab>
        <b-tab title="Tab 3"><b-card-text>Tab contents 3</b-card-text></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import TestServices from "@/services/TestServices";

export default {
  name: "TestView",
  data() {
    return {
      msgFromCon: []
    }
  },
  methods: {
    getResponse(){
      TestServices.getText().then((response) => {
        this.msgFromCon = response.data
      })
    }
  },
  created() {
    this.getResponse()
  }
}
</script>

<style scoped>


</style>