import axios from "axios";

const QUESTION_LIST_API_URL = "/api/questionlistcollection/"

class QuestionListCollectionServices {
    createCollection(walk_id, question_id, version) {
        return axios.post(QUESTION_LIST_API_URL + "add/" + walk_id + "/" + question_id + "/" + version);
    }
    createCollectionWeight(walk_id, question_id, version, forward_score, backward_score, idk, order) {
        return axios.post(QUESTION_LIST_API_URL +
            "add/" + walk_id +
            "/" + question_id +
            "/" + version +
            "/forward/" + forward_score +
            "/backward/" + backward_score +
            "/idk/" + idk +
            "/order/" + order
        );
    }
    createSometimesWeight(walk_id, question_id, version, sometimes_score, sometimes) {
        return axios.post(QUESTION_LIST_API_URL +
            "add/" + walk_id +
            "/" + question_id +
            "/" + version +
            "/sometimes_score/" + sometimes_score +
            "/sometimes/" + sometimes
        );
    }
    getQuestionsFromWalk(walk_id){
        return axios.get(QUESTION_LIST_API_URL + "get/allquestion/" + walk_id);
    }
    getQuestionsCollectionFromWalk(walk_id){
        return axios.get(QUESTION_LIST_API_URL + "get/privilegewalk/question/" + walk_id);
    }
    getQuestionsCollectionFromWalkAndVersion(walk_id, version){
        return axios.get(QUESTION_LIST_API_URL + "get/privilegewalk/question/version/" + walk_id + "/" + version);
    }
    getQuestionsFromWalkVersion(walk_id, version){
        return axios.get(QUESTION_LIST_API_URL + "get/allquestion/version/" + walk_id + "/" + version);
    }
    deleteQuestionsCollection(walk_id){
        return axios.delete(QUESTION_LIST_API_URL + "delete/questionList/" + walk_id);
    }
}

export default new QuestionListCollectionServices()