<template>
  <div style="margin-bottom: 5rem; margin-top: 5%">
    <!--
    <div class="searchBar">
      <div class="tagBar">
        <div class="tag">
          <b-form-group label-for="tags-with-dropdown">
            <b-form-tags id="tags-with-dropdown" v-model="value" no-outer-focus class="mb-2">
              <template v-slot="{ tags, disabled, addTag, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="success"
                    >{{ tag }}
                    </b-form-tag>
                  </li>
                </ul>

                <b-dropdown size="sm" variant="outline-dark" block menu-class="w-100">
                  <template #button-content>
                    <b-icon icon="tag-fill"></b-icon>
                    Choose Tags
                  </template>
                  <b-dropdown-form @submit.stop.prevent="() => {}">
                    <b-form-group
                        label="Search tags"
                        label-for="tag-search-input"
                        label-cols-md="auto"
                        class="mb-0"
                        label-size="sm"
                        :description="searchDesc"
                        :disabled="disabled"
                    >
                      <b-form-input
                          v-model="search"
                          id="tag-search-input"
                          type="search"
                          size="sm"
                          autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </b-dropdown-form>
                  <b-dropdown-divider></b-dropdown-divider>
                  <li>
                    <ul class="dropdown-menu d-block scrollable-menu position-relative mt-0 border-0">
                      <b-dropdown-item-button
                          v-for="option in availableOptions"
                          :key="option"
                          @click="onOptionClick({ option, addTag })"
                      >{{ option }}
                      </b-dropdown-item-button>
                    </ul>
                  </li>
                  <b-dropdown-text v-if="availableOptions.length === 0">
                    There are no tags available to select
                  </b-dropdown-text>
                </b-dropdown>
              </template>
            </b-form-tags>
          </b-form-group>
        </div>
        <div class="searchButton">
          <b-button variant="success" size="md" @click="searchTags()" style="margin-bottom: -4rem">
            <b-icon icon="search"></b-icon>
          </b-button>
        </div>
      </div>
      <div style="display: flex; margin-top: -3.5rem;">
        <span style="flex: 1; margin-top: 3.3rem; margin-left: 1rem">Search Facilitator's Questions:</span>
        <b-form-input v-model="email" style="flex: 5.5; margin-bottom: 0.5rem; margin-right: 6rem" placeholder="Enter Email"></b-form-input>
      </div>
    </div>
    -->

    <div v-if="wait_questions" class="loading-questions">
      <b-spinner type="grow" label="Spinning"></b-spinner>
      &nbsp;
      <b-spinner type="grow" label="Spinning"></b-spinner>
      &nbsp;
      <b-spinner type="grow" label="Spinning"></b-spinner>
    </div>

    <div v-if="!wait_questions" class="q-list">
      <div style="font-size: x-large; font-weight: bold">
        Question List
      </div>
      <b-form-input type="text" v-model="searchQuery" list="questions-list" placeholder="Search"></b-form-input>
      <b-table
          id="my-table"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :tbody-tr-class="rowClass"
          striped
          hover
      >
        <template #cell(actions)="row">
          <div v-if="row.item.inwwalk" class="mr-1">
            <b>In Walk</b>
          </div>
          <b-button v-else size="sm" @click="compilePW(row.item); row.item.added = !row.item.added" class="mr-1">
            {{ row.item.added ? 'Remove' : 'Add' }}
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="colorbox">
      <div class="tab-bar">
        <div class="mt-3">
          <div class="d-flex align-items-start">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
                          pills></b-pagination>
            <b-button-group size="sm" style="background-color: whitesmoke">
              <b-button variant="outline-primary" :pressed="button[0]"
                        v-on:click="button = [true, false, false]; perPage = 10;">10 Questions
              </b-button>
              <b-button variant="outline-primary" :pressed="button[1]"
                        v-on:click="button = [false, true, false]; perPage = 20;">20 Questions
              </b-button>
              <b-button variant="outline-primary" :pressed="button[2]"
                        v-on:click="button = [false, false, true]; perPage = 50;">50 Questions
              </b-button>
            </b-button-group>
          </div>
        </div>


        <div class="button-add">
          <!--          {{ count }} Questions Selected-->
          <b-button variant="success" @click="editWalkView()">
            Add {{ count }} Selected Questions to Walk
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionServices from "@/services/QuestionServices";
import TagServices from "@/services/TagServices";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {key: 'questions', label: 'Questions'},
        {key: 'actions', label: ''}
      ],
      id: this.$route.query.ID,
      items: [],
      preItems: [],
      button: [false, false, false],
      count: 0,
      searchQuery: "",
      addedList: [],
      email: '',
      inwalk: [],
      arrayData: {
        questionArray: []
      },
      options: [],
      search: '',
      value: [],
      walk_id: null,
      wait_questions: false,
      totalPages: 1,
      totalElements: 10
    }
  },
  computed: {
    rows() {
      return this.preItems.length
    },
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      const options = this.options.filter(opt => this.value.indexOf(opt) === -1);
      if (criteria) {
        return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
      }
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    }
  },
  watch: {
    searchQuery(newQuery) {
      this.items = this.preItems.filter((question) => {
        return question.questions.toLowerCase().includes(newQuery.toLowerCase());
      });
    },
  },
  methods: {
    onOptionClick({option, addTag}) {
      addTag(option)
      this.search = '';
    },
    editWalkView() {
      if (this.walk_id) {
        this.$router.push({
          path: 'createWalk',
          query: {qarray: this.arrayData.questionArray, ID: this.id, walkID: this.walk_id, edit: true}
        });
      } else {
        this.$router.push({path: 'createWalk', query: {qarray: this.arrayData.questionArray, ID: this.id}});
      }
    },
    compilePW(item) {
      if (this.arrayData.questionArray.includes(item)) {
        const index = this.addedList.indexOf(item);
        this.arrayData.questionArray.splice(index, 1);
        this.count -= 1
      } else {
        this.arrayData.questionArray.push(item)
        this.count += 1
      }
    },
    async addToQuestion(question) {
      let tag_array = [];
      if(question.tags){
        question.tags.forEach(tag => {
          tag_array.push(tag.name);
        })
      }
      if (this.inwalk.some(item => item.questionID === question.question_id)) {
        this.items.push(
            {
              questions: question.questionText,
              question_text: question.questionText,
              questionID: question.question_id,
              tags: tag_array,
              added: true,
              inwwalk: true,
              step_back: -1,
              step_forward: 1,
              step_sometimes: 0,
              sometimes: false,
              idk: true
            }
        )
      } else {
        this.items.push(
            {
              questions: question.questionText,
              question_text: question.questionText,
              questionID: question.question_id,
              tags: tag_array,
              added: false,
              inwwalk: false,
              step_back: -1,
              step_forward: 1,
              step_sometimes: 0,
              sometimes: false,
              idk: true
            }
        )
      }
      this.preItems = this.items;
    },
    getQuestionList(page) {
      this.items = [];
      this.wait_questions = true;
      if (page > 0)
        this.currentPage = page;
      QuestionServices.getQuestionListNotHidden(this.currentPage, this.perPage).then((response) => {
        this.totalPages = response.data.totalPages;
        // we have to subtract the items per page here because if we don't vue will add another
        // page option to the pagination which is always blank
        this.totalElements = response.data.totalElements - this.perPage;
        response.data.content.forEach(question => {
          this.addToQuestion(question);
        });
        this.searchQuery = "";
        this.wait_questions = false;
      });
    },
    async getQuestionListNotHidden() {
      this.items= []
      await QuestionServices.getQuestionList().then((response) => {
        response.data.forEach(question => {
            if(question.hidden === false) {
              this.items.push(
                  {
                    questions: question.questionText,
                    question_text: question.questionText,
                    questionID: question.question_id,
                    added: false,
                    inwwalk: false,
                    step_back: -1,
                    step_forward: 1,
                    step_sometimes: 0,
                    sometimes: false,
                    idk: true
                  }
              )
            }
        })
        this.searchQuery = "";
        this.preItems = this.items;
      })
    },
    getMoreQuestions(buttonOpts, pageSize) {
      this.button = buttonOpts;
      this.perPage = pageSize;
      this.getQuestionList();
    },
    async searchTags() {
      if(this.email.length > 0){
        this.items = [];
        this.wait_questions = true;
        await QuestionServices.getQuestionListByEmail(this.email).then(response => {
          this.wait_questions = false;
          response.data.forEach(question => {
            this.addToQuestion(question);
          })
        })
        return;
      }
      if (this.value.length > 0) {
        this.items = [];
        this.wait_questions = true;
        await TagServices.getQuestionsByTags(this.value).then(response => {
          this.wait_questions = false;
          response.data.forEach(question => {
            this.addToQuestion(question);
          })
        });
      } else {
        this.items = [];
        this.getQuestionList();
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.added === true) return 'table-success'
    }
  },
  created() {
    TagServices.getTags().then(response => {
      this.options = response.data;
    });

    if (localStorage.getItem('array')) {
      this.inwalk = JSON.parse(localStorage.getItem('array'));
    }

    if (this.$route.query.walkID) {
      this.walk_id = this.$route.query.walkID;
    }

    this.button = [true, false, false];

    this.getQuestionListNotHidden();
  }
}
</script>

<style scoped>
.tab-bar {
  bottom: 0;
  left: 20px;
  position: fixed;
}

.q-list {
  margin-top: 1rem;
  padding: 1rem;
  margin-right: 6rem;
  margin-left: 6rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
}

.button-add {
  position: fixed;
  font-size: x-large;
  bottom: 10px;
  right: 20px;
}

.form-control {
  margin-top: 3rem;
}

.colorbox {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background: linear-gradient(rgba(232, 232, 232, 0.4), rgba(30, 30, 30, 1) 170%);
}

.searchBar {
  /*padding: 1rem;*/
  margin-right: 3%;
  margin-left: 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.tagBar {
  display: flex;
}

.searchButton {
  flex: 0.5;
  margin-top: 0.7rem;
  margin-left: 1rem;
  margin-bottom: -4rem;
}

.tag {
  flex: 10;
  margin-top: -2.5rem;
  margin-left: 0.5rem;
  /*margin-bottom: -0.5rem;*/
}

table td {
  table-layout: fixed;
  overflow: hidden;
  word-wrap: break-word;
}

.scrollable-menu {
  height: auto;
  width: 100%;
  max-height: 30vh;
  overflow-y: auto;
  z-index: 2;
}

.loading-questions{
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
}
</style>