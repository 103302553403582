import axios from 'axios'

const FACILITATOR_API_URL = "/api/facilitator/"

class FacilitatorServices {
    getID(codename) {
        return axios.get(FACILITATOR_API_URL + 'id/' + codename);
    }
    getName(participant_id) {
        return axios.get(FACILITATOR_API_URL + 'username/' + participant_id);
    }
    getPWList(participant_id) {
        return axios.get(FACILITATOR_API_URL + 'pwName/' + participant_id);
    }
    addFacilitator(username, email, password){
        return axios.post(FACILITATOR_API_URL + 'add/' + username + '/' + email + '/' + password);
    }
    getFacilitatorID(emailOrUsername, password){
        return axios.post(FACILITATOR_API_URL + 'login',
            {
                    emailOrUsername: emailOrUsername,
                    password: password
                  });
    }
    getFacilitatorByID(facilitator_id){
        return axios.get(FACILITATOR_API_URL + 'get_facilitator/id/' + facilitator_id);
    }
    getFacilitatorIsHidden(facilitator_id){
        return axios.get(FACILITATOR_API_URL + 'isHidden/' + facilitator_id);
    }
    getFacilitatorIDByEmail(email){
        return axios.get(FACILITATOR_API_URL + 'get_facilitator/email/' + email);
    }
    recoverPass(email){
        return axios.put(FACILITATOR_API_URL + 'recover/' + email);
    }
    checkCode(email, code){
        return axios.put(FACILITATOR_API_URL + 'recover/check/' + email + '/' + code);
    }
    setNewPassword(email, password){
        return axios.put(FACILITATOR_API_URL + 'setNewPass/' + email + '/' + password);
    }
    resetPasswordRecovery(facilitator_id){
        return axios.put(FACILITATOR_API_URL + 'resetCode/' + facilitator_id);
    }
}

export default new FacilitatorServices()