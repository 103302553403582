import {QuestionAdapter} from "@/services/adapters/QuestionAdapter";

export const StoreMutations = {
  SET_QUESTIONS_IN_WALK: 'SET_QUESTIONS_IN_WALK',
  ADD_TO_QUESTIONS_IN_WALK: 'ADD_TO_QUESTIONS_IN_WALK',
  REMOVE_FROM_QUESTIONS_IN_WALK: 'REMOVE_FROM_QUESTIONS_IN_WALK',
  SET_QUESTIONS: 'SET_QUESTIONS',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  SET_WALK_ID: 'SET_WALK_ID',
  SET_WALK_TITLE: 'SET_WALK_TITLE'
};

export default {
  SET_QUESTIONS_IN_WALK(state, payload) {
    state.questionsInWalk = [];
    state.questionsInWalk = payload;
  },
  ADD_TO_QUESTIONS_IN_WALK(state, payload) {
    state.questionsInWalk.push(payload);
  },
  REMOVE_FROM_QUESTIONS_IN_WALK(state, payload) {
    state.questionsInWalk.splice(payload, 1);
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload.map(question => {
      let tag_array = [];
      if(question.tags){
        question.tags.forEach(tag => {
          tag_array.push(tag.name);
        })
      }
      if (state.questionsInWalk.some(item => item.questionID === question.question_id)) {
        return new QuestionAdapter({
          question_text: question.questionText,
          questionID: question.question_id,
          tags: tag_array,
          added: true,
          inWalk: true,
          step_back: -1,
          step_forward: 1,
          idk: true
        })
      } else {
        return new QuestionAdapter({
          question_text: question.questionText,
          questionID: question.question_id,
          tags: tag_array,
          added: false,
          inWalk: false,
          step_back: -1,
          step_forward: 1,
          idk: true
        })
      }
    })
  },
  UPDATE_QUESTION(state, payload) {
    state.questions[payload.index][payload.key] = payload.value;
  },
  SET_WALK_ID(state, payload) {
    state.walkId = payload;
  },
  SET_WALK_TITLE(state, payload) {
    state.walk_title = payload;
  }
};
