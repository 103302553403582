<template>
  <div>
    <div class="box">
      <b-container fluid>
        <b-row align-v="center" style="margin-bottom: -1rem">
          <b-col>
            <p class="title">Question: {{(questionNum)}}</p>
            <p class="room-key">Room Code: {{ roomKey }}</p>
            <p class="room-key">{{ questionText[questionNum-1]}}</p>
          </b-col>
          <b-col class="text-right">
            <p class="text-primary">Answers Submitted:{{ numAnswersSubmitted }} / {{ numParticipants }}
              <b-progress :max="numParticipants" height="2rem">
                <b-progress-bar :value="numAnswersSubmitted">
                </b-progress-bar>
              </b-progress>
              <b-button style="margin-right: 1rem; margin-top: 1rem" variant="danger" @click="endWalkEarly()">End Walk</b-button>
              <b-button style="margin-top: 1rem" variant="outline-primary" @click="reloadPage(count)">Refresh</b-button>
<!--              <b-dropdown id="dropdown-1" style="margin-right: 1rem" dropleft text="Select Question" class="m-md-2">-->
<!--                <li v-for='index in questionText.length' :key='index'>-->
<!--                  <b-dropdown-item @click="questionNum = index; updateGraph(count);">{{questionText[index-1]}}</b-dropdown-item>-->
<!--                </li>-->
<!--              </b-dropdown>-->
            </p>
          </b-col>
        </b-row>
        <!--        <b-row>-->
        <!--          <b-form-group v-slot="{ ariaDescribedby }" style="margin-left: 1rem;">-->
        <!--            <b-form-radio-group-->
        <!--                v-model="selected"-->
        <!--                :aria-describedby="ariaDescribedby"-->
        <!--                :options="options"-->
        <!--                name="plain-inline"-->
        <!--                plain-->
        <!--                @change="changeGraph"-->
        <!--            ></b-form-radio-group>-->
        <!--          </b-form-group>-->
        <!--        </b-row>-->
      </b-container>

      <!--      <p class="title">{{ WalkTitle }}</p>-->
      <!--      <div class="button">-->
      <!--        <div class="d-flex align-items-start">-->
      <!--          <b-button variant="outline-primary" @click="reload()" style="margin-right: 1rem">Refresh</b-button>-->
      <!--          <b-button variant="outline-secondary" @click="goToPortal()">Back to Portal</b-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div><p class="room-key">Room Code: {{ roomKey }}</p></div>-->
      <!--      <p></p>-->
    </div>
    <div class="button-next">
      <div v-if="questionNum === questionText.length">
        <b-button variant="danger" @click="changeQuestion(100);">End Walk</b-button>
      </div>
      <div v-else>
        <b-button variant="success" @click="changeQuestion(1);">Next Question</b-button>
      </div>
    </div>
    <div class="button-name">
      <div v-if="count!== 0" style="float:left">
        <b-button variant="success" @click="changeQuestion(-1)">Previous Question</b-button>
      </div>
    </div>
    <b-modal ref="modal" hide-footer title="Not everyone has answered this question yet">
      <div v-if="questionNum === questionText.length">
        <b-button variant="danger" @click="endActiveWalk();">End Walk Anyway</b-button>
      </div>
      <div v-else>
        <b-button variant="success" @click="moveAnyway()">Continue Anyway</b-button>
      </div>
    </b-modal>
    <b-modal ref="endWalkEarlyCheck" hide-footer title="Are You Sure You Want To End The Walk Early?">
        <b-button variant="danger" @click="endActiveWalk();">End Walk</b-button>
    </b-modal>
    <p></p>
    <b-overlay :show="isLoading" rounded="sm">

      <BarChart :chart-data="datacollection" :chart-options="dataoptions"
                style="margin-left: 3%; margin-right: 3%; height: 60vh"></BarChart>

    </b-overlay>
  </div>
</template>

<script>
import WalkResultsServices from "@/services/WalkResultsServices";
import SessionServices from "@/services/SessionServices";
import BarChart from "@/services/BarChartPerQuestion";
import QuestionListCollectionServices from "@/services/QuestionListCollectionServices";

export default {
  name: "facilitatorLedWalkFacilitator",
  components:{
    BarChart
  },
  data() {
    return {
      id: this.$route.query.id,
      roomKey: this.$route.query.roomKey,
      datacollection: null,
      dataoptions: null,
      currentQuestionCount: [],
      count: 0,
      sessionID: this.$route.query.sessionID,
      questionResponses: [],
      questionNum: 1,
      questionText: [],
      walkID: null,
      numAnswersSubmitted: 0,
      numParticipants: 1,
      counterInterval: null,
      isLoading: false,

      walkType: "f",
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.confirmClose);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmClose);
  },
  methods: {
    confirmClose(event) {
      event.preventDefault();
      event.returnValue = '';
      return ''; // For Chrome
    },
    changeQuestion(next){
      if(this.numAnswersSubmitted < this.numParticipants){
        this.$refs["modal"].show();
      }
      else if(next == -17){
        this.$refs["modal"].hide();
      }
      else if(next === 100 && this.numAnswersSubmitted >= this.numParticipants){
        SessionServices.changeQuestionNum(this.roomKey, 0);
        this.endActiveWalk();
      }
      else{
        this.questionNum += next;
        this.updateGraph(this.count);
        SessionServices.changeQuestionNum(this.roomKey, this.questionNum);
      }

    },
    moveAnyway(){
      this.questionNum += 1;
      this.updateGraph(this.count);
      SessionServices.changeQuestionNum(this.roomKey, this.questionNum)
      this.$refs["modal"].hide();
    },
    endWalkEarly() {
      this.$refs["endWalkEarlyCheck"].show();
    },
    reloadPage(count){
      this.isLoading = true;
      this.getAllQuestionCounts();
      this.updateGraph(count);
      SessionServices.getSessionByAccessCode(this.roomKey).then((resp) => {
        this.numParticipants = resp.data.numberOfCurrentParticipants}
      )

      this.isLoading = false;
    },
    endActiveWalk() {
      SessionServices.deactivateSession(this.sessionID);
      this.$router.push({path: 'results', query: {roomKey: this.roomKey, id: this.id, walkType: this.walkType}})
    },
    async updateGraph(count) {
      this.isLoading = true;
      console.log("numQ");
      console.log(this.questionText.length);
      console.log("qnum");
      console.log(this.questionNum);
      if ((this.questionText.length) < this.questionNum) {
        console.log(this.questionResponses);
        console.log(count);
        console.log(this.count);
        console.log(this.questionNum);
        count = 0;
        this.count = 0;
        this.questionNum = 1;
        await SessionServices.changeQuestionNum(this.roomKey, this.questionNum)
      }
      if (this.questionResponses.length > 0) {
        this.currentQuestionCount[0] = this.questionResponses[(this.questionNum*5)-5];
        this.currentQuestionCount[1] = this.questionResponses[(this.questionNum*5)-5 + 1];
        this.currentQuestionCount[2] = this.questionResponses[(this.questionNum*5)-5 + 2];
        this.currentQuestionCount[3] = this.questionResponses[(this.questionNum*5)-5 + 3];
        this.currentQuestionCount[4] = this.questionResponses[(this.questionNum*5)-5 + 4];
        console.log("divided by 5");
        console.log(this.questionResponses/5);
        console.log(this.questionNum);
        if(this.questionNum>this.questionResponses.length/5){
          this.numAnswersSubmitted = 0;
          console.log("changed to zero");
        }
        else{
          console.log("normal");
          this.numAnswersSubmitted = this.questionResponses[(this.questionNum*5)-5] + this.questionResponses[(this.questionNum*5)-5 + 1] + this.questionResponses[(this.questionNum*5)-5 + 2] + this.questionResponses[(this.questionNum*5)-5 + 3] + this.questionResponses[(this.questionNum*5)-5 + 4];
        }
      }
/*
      // Mark missed questions as "Prefer not to answer" if joining late
      for (let i = 0; i < this.questionNum - 1; i++) {
        // If the response for any answer option is missing, mark it as "Prefer not to answer"
        if (!this.questionResponses[i * 5]) {
          this.questionResponses[(this.questionNum*5)-5 + 4] = 0;
        }
      }
*/
      this.datacollection = {
        labels: ['Yes', 'No', 'Sometimes', 'I Dont Know', 'Prefer Not to Answer'],
        datasets: [
          {
            label: 'Number of Responses',
            backgroundColor: "#73be73",
            data: this.currentQuestionCount
          }
        ]
      };
      this.dataoptions = {}
      this.isLoading = false;
    },
    async getAllQuestionCounts(){
      await WalkResultsServices.getScoresPerQuestion(this.sessionID).then(resp => {
        this.questionResponses = resp.data;
      })
      // for(let i = 0; i < 4; i++){
      //   this.currentQuestionCount[i] = this.questionResponses[i];
      // }
      await this.updateGraph(this.count);
    },
    async getQuestionText(){
      await SessionServices.getWalk(this.sessionID).then((resp) => {
        QuestionListCollectionServices.getQuestionsCollectionFromWalkAndVersion(resp.data.walk_id, resp.data.currentVersion).then(res => {
          res.data.forEach(q => {
            this.questionText.push(q.question_id.questionText);
          })
        })
      })
    },
  },
  created() {
    SessionServices.changeQuestionNum(this.roomKey, 1);
    this.counterInterval = setInterval(
        function()
        {
          this.reloadPage(0);
        }.bind(this), 3000);

    return this.reloadPage(0);
  },
  beforeRouteLeave(to, from , next) {
    clearInterval( this.counterInterval )
    next();
  },
  beforeMount() {
    this.getAllQuestionCounts();
    this.getQuestionText();
    SessionServices.getSessionByAccessCode(this.roomKey).then((resp) => {this.numParticipants = resp.data.numberOfCurrentParticipants})
  }

}

</script>

<style scoped>
.box {
  margin-top: 85px;
  padding: 5px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: whitesmoke;
}

.title {
  /*float: left;*/
  margin-bottom: -0.5rem;
  font-size: 30px;
  font-weight: 500;
}

.room-key {
  font-size: larger;
  /*float: left;*/
  /*margin-top: 0.7rem;*/
  /*margin-bottom: -1px;*/
}

.button-next {
  position: fixed;
  font-size: x-large;
  bottom: 30px;
  right: 20px;
}

.button-name {
  position: fixed;
  font-size: x-large;
  bottom: 30px;
  left: 20px;
}
</style>