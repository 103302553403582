export const StoreState = {
  questionsInWalk: 'questionsInWalk',
  walkId: 'walkId',
  version: 'version',
  questions: 'questions',
  walk_title: 'walk_title'
};

export default {
  questionsInWalk: [],
  walkId: null,
  version: 0,
  questions: [],
  walk_title: ""
};
