<template>
  <div class="back">
    <div class="smallButtonSelect" style="margin-top: 10rem;" v-if="this.windowWidth <= 720">
      <b-container align="center">
        <!--        <b-button :pressed="toggleF" block variant="outline-dark"-->
        <!--                  v-on:click="Facilitator = true;-->
        <!--                  forgot = false;-->
        <!--                  enterCode = false;-->
        <!--                  newPass = false;-->
        <!--                  show = true;-->
        <!--                  Participant = false;-->
        <!--                  toggleP = false;-->
        <!--                  toggleF = true;">Facilitator-->
        <!--        </b-button>-->
        <!--        <b-button :pressed="toggleP" block variant="outline-dark"-->
        <!--                   v-on:click="Participant = true;-->
        <!--                  show = true;-->
        <!--                  Facilitator = false;-->
        <!--                  toggleP = true;-->
        <!--                  toggleF = false;">Participant-->
        <!--        </b-button>-->
        <b-button v-if="show" size="lg" style="font-size: large; color: dimgray" block @click="openEnterCodePage" variant="outline-dark">Join a Walk <!-- should use router -->
        </b-button>
        <b-button v-else size="lg" style="font-size: xxx-large; color: dimgray" block @click="openEnterCodePage" variant="outline-dark">Join a Walk <!-- should use router -->
        </b-button>
      </b-container>

      <!--                   Form under button if we ever want to go back                               -->
      <div class="form">
        <span v-if="show" style="font-size: x-large">Facilitator</span>
        <b-form v-if="show" @submit.stop.prevent>
          <b-form-group v-if="show" id="input-group-1" label="Email/Username:" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="form.emailOrUsername"
                :state="validationUsernameOrEmail"
                placeholder="Enter Email/Username"
                required
            ></b-form-input>
            <b-form-invalid-feedback :state="validationUsernameOrEmail">
              <div v-if="form.emailOrUsername.length >= 50">
                Too many characters!
              </div>
              <div v-else>
                Enter Email/Username
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="show" id="input-group-3" label="Password:" label-for="input-3">
            <b-form-input
                id="input-3"
                v-model="form.password"
                :state="validationPass"
                placeholder="Enter Password"
                required
                type="password"
                v-on:keyup.enter="getFacilitatorID(validationUsernameOrEmail, validationPass)"
            ></b-form-input>
            <b-form-invalid-feedback :state="validationPass">
              <div v-if="form.password.length >= 50">
                Too many characters!
              </div>
              <div v-else>
                Password needs at least 5 characters and 3 numbers.
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button size="large" style="font-size: large;" v-if="show" variant="primary"
                    @click="getFacilitatorID(validationUsernameOrEmail, validationPass)">
            Submit
          </b-button>
          <b-button v-if="Participant" variant="primary" @click="getParticipantID()">Submit</b-button>

          <b-button size = "large" style ="font-size: large; margin: 10px" @click="openUserManual()">
            Help
          </b-button>

          <p></p>
          <!-- Forgot Password Form -->
          <span v-if="forgot || enterCode || newPass" class="fgtpass">Forgot Password</span>

          <b-form-group v-if="forgot && !wait_email" id="input-group-1" label="Enter Email:" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="email_recovery"
                :state="validationEmailPassRecov"
                placeholder="Enter Email"
                required
            ></b-form-input>
            <b-form-invalid-feedback :state="validationEmailPassRecov">
              <div v-if="email_recovery.length >= 50">
                Too many characters!
              </div>
              <div v-else>
                Input Email
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <div v-if="wait_email" style="justify-content: center; text-align: center;">
            <b-spinner label="Spinning" type="grow"></b-spinner>
            <b-spinner label="Spinning" type="grow"></b-spinner>
            <b-spinner label="Spinning" type="grow"></b-spinner>
          </div>
          <b-form-group v-if="enterCode" id="input-group-2" label="Enter Code From Email:" label-for="input-2">
            <b-form-input
                id="input-1"
                v-model="code_recovery"
                :state="validationCode"
                placeholder="Enter Code"
                required
                type="number"
            ></b-form-input>
            <b-form-invalid-feedback :state="validationCode">
              6 Digit Code
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="newPass" id="input-group-1" label="Enter New Password:" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="password1_recovery"
                :state="validationNewPass"
                placeholder="Enter Password"
                required
                type="password"
            ></b-form-input>
            <b-form-invalid-feedback :state="validationNewPass">
              <div v-if="password2_recovery.length >= 50">
                To many characters!
              </div>
              <div v-else>
                Password needs at least 5 characters, 3 numbers, and both need to match
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group v-if="newPass" id="input-group-1" label="Enter New Password Again:" label-for="input-1">
            <b-form-input
                id="input-2"
                v-model="password2_recovery"
                :state="validationNewPass"
                placeholder="Enter Password"
                required
                type="password"
            ></b-form-input>
          </b-form-group>

          <b-button v-if="forgot && !wait_email" variant="primary" @click="forgotPassword()">
            Submit
          </b-button>
          <b-button v-if="enterCode" variant="primary" @click="checkRecoveryCode();">
            Submit
          </b-button>
          <b-button v-if="newPass" variant="primary" @click="changePassword()">
            Submit
          </b-button>
          <b-button v-if="forgot || enterCode" style="margin-left: 0.3rem"
                    variant="danger" @click="forgot = false; enterCode = false; Facilitator = true;">
            Cancel
          </b-button>


          <!-- Forgot Password Form -->

          <p><b-button size="large" v-if="show && !forgot && !enterCode && !newPass" href="javascript:undefined"
                       style="font-size: large; margin-left: 0.0rem" @click="Facilitator = !Facilitator; forgot = !forgot">Forgot Password</b-button></p>
        </b-form>
      </div>
    </div>

    <div class="buttonSelect" style="margin-top: 15rem;" v-else>
      <b-container align="center">
        <!--        <b-button :pressed="toggleF" block variant="outline-dark"-->
        <!--                  v-on:click="Facilitator = true;-->
        <!--                  forgot = false;-->
        <!--                  enterCode = false;-->
        <!--                  newPass = false;-->
        <!--                  show = true;-->
        <!--                  Participant = false;-->
        <!--                  toggleP = false;-->
        <!--                  toggleF = true;">Facilitator-->
        <!--        </b-button>-->
        <!--        <b-button :pressed="toggleP" block variant="outline-dark"-->
        <!--                   v-on:click="Participant = true;-->
        <!--                  show = true;-->
        <!--                  Facilitator = false;-->
        <!--                  toggleP = true;-->
        <!--                  toggleF = false;">Participant-->
        <!--        </b-button>-->
        <b-button block @click="openEnterCodePage" variant="outline-dark">Join a Walk <!-- should use router -->
        </b-button>
      </b-container>

      <!--                   Form under button if we ever want to go back                               -->
      <div class="form">
        <span v-if="show" style="font-size: x-large">Facilitator</span>
        <b-form v-if="show" @submit.stop.prevent>
          <b-form-group v-if="show" id="input-group-1" label="Email/Username:" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="form.emailOrUsername"
                :state="validationUsernameOrEmail"
                placeholder="Enter Email/Username"
                required
            ></b-form-input>
            <b-form-invalid-feedback :state="validationUsernameOrEmail">
              <div v-if="form.emailOrUsername.length >= 50">
                Too many characters!
              </div>
              <div v-else>
                Enter Email/Username
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="show" id="input-group-3" label="Password:" label-for="input-3">
            <b-form-input
                id="input-3"
                v-model="form.password"
                :state="validationPass"
                placeholder="Enter Password"
                required
                type="password"
                v-on:keyup.enter="getFacilitatorID(validationUsernameOrEmail, validationPass)"
            ></b-form-input>
            <b-form-invalid-feedback :state="validationPass">
              <div v-if="form.password.length >= 50">
                Too many characters!
              </div>
              <div v-else>
                Password needs at least 5 characters and 3 numbers.
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button v-if="show" variant="primary"
                    @click="getFacilitatorID(validationUsernameOrEmail, validationPass)">
            Submit
          </b-button>

          <b-button style ="margin: 10px" @click="openUserManual()">
            Help
          </b-button>

          <p></p>

          <b-button v-if="Participant" variant="primary" @click="getParticipantID()">Submit</b-button>
          <!-- Forgot Password Form -->
          <span v-if="forgot || enterCode || newPass" class="fgtpass">Forgot Password</span>

          <b-form-group v-if="forgot && !wait_email" id="input-group-1" label="Enter Email:" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="email_recovery"
                :state="validationEmailPassRecov"
                placeholder="Enter Email"
                required
            ></b-form-input>
            <b-form-invalid-feedback :state="validationEmailPassRecov">
              <div v-if="email_recovery.length >= 50">
                Too many characters!
              </div>
              <div v-else>
                Input Email
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <div v-if="wait_email" style="justify-content: center; text-align: center;">
            <b-spinner label="Spinning" type="grow"></b-spinner>
            <b-spinner label="Spinning" type="grow"></b-spinner>
            <b-spinner label="Spinning" type="grow"></b-spinner>
          </div>
          <b-form-group v-if="enterCode" id="input-group-2" label="Enter Code From Email:" label-for="input-2">
            <b-form-input
                id="input-1"
                v-model="code_recovery"
                :state="validationCode"
                placeholder="Enter Code"
                required
                type="number"
            ></b-form-input>
            <b-form-invalid-feedback :state="validationCode">
              6 Digit Code
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="newPass" id="input-group-1" label="Enter New Password:" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="password1_recovery"
                :state="validationNewPass"
                placeholder="Enter Password"
                required
                type="password"
            ></b-form-input>
            <b-form-invalid-feedback :state="validationNewPass">
              <div v-if="password2_recovery.length >= 50">
                To many characters!
              </div>
              <div v-else>
                Password needs at least 5 characters, 3 numbers, and both need to match
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group v-if="newPass" id="input-group-1" label="Enter New Password Again:" label-for="input-1">
            <b-form-input
                id="input-2"
                v-model="password2_recovery"
                :state="validationNewPass"
                placeholder="Enter Password"
                required
                type="password"
            ></b-form-input>
          </b-form-group>


          <b-button v-if="forgot && !wait_email" variant="primary" @click="forgotPassword()">
            Submit
          </b-button>
          <b-button v-if="enterCode" variant="primary" @click="checkRecoveryCode();">
            Submit
          </b-button>
          <b-button v-if="newPass" variant="primary" @click="changePassword()">
            Submit
          </b-button>
          <b-button v-if="forgot || enterCode" style="margin-left: 0.3rem"
                    variant="danger" @click="forgot = false; enterCode = false; Facilitator = true;">
            Cancel
          </b-button>
          <a v-if="show && !forgot && !enterCode && !newPass" href="javascript:undefined"
             style="margin-left: 0.5rem" @click="Facilitator = !Facilitator; forgot = !forgot">Forgot Password</a>
          <!-- Forgot Password Form -->

        </b-form>
      </div>
    </div>
    <b-modal ref="modal" hide-footer title="New User">
      <!--      <b-button v-if="Facilitator" class="mt-3" variant="outline-primary" block @click="addFacilitator()">Create Account?-->
      <!--      </b-button>-->
      <!--      <b-button v-if="Participant" class="mt-3" variant="outline-primary" block @click="addParticipant()">Create Account?-->
      <!--      </b-button>-->
      Sorry, no new facilitators at this time.
    </b-modal>
    <b-modal ref="modal_bad_input" hide-footer title="Missing or Invalid Fields">
      <h4>Please fill in or fix fields.</h4>
    </b-modal>
    <b-modal ref="modal_bad_user_input" hide-footer title="Incorrect User Login">
      <h4>Incorrect information.</h4>
    </b-modal>
    <b-modal ref="modal_pass_recovery" hide-footer title="Error">
      <h4>{{ error_message }}</h4>
    </b-modal>
    <b-modal ref="modal_bad_user" hide-footer title="You are Deactivated">
      <h4>Your account has been deactivated. Please contact an administrator.</h4>
    </b-modal>
  </div>
</template>

<script>
import FacilitatorServices from "@/services/FacilitatorServices";
import ParticipantServices from "@/services/ParticipantServices";
import version from "@/services/VersionID"
import {mapMutations, mapState} from "vuex";
import {StoreMutations} from "@/store/facilitator-portal/mutations";
import StoreIndex from "@/store/facilitator-portal/_StoreIndex"
import LandingPageStoreIndex from "@/store/landing-page/_StoreIndex"
import {StoreState as LandingPageStoreState} from "@/store/landing-page/state"

export default {
  name: 'LandingPage',
  data() {
    return {
      version: version.id,
      data: '',
      Facilitator: false,
      Participant: false,
      array: [],
      toggleF: false,
      toggleP: false,
      length_message: '',
      pass_regex: /(?=(.*?\d){3})[a-zA-Z0-9]{5,}/,
      username_regex: /[a-zA-Z0-9]{3,}/,
      email_regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      form: {
        emailOrUsername: '',
        password: ''
      },
      forgot: false,
      enterCode: false,
      newPass: false,
      email_recovery: '',
      code_recovery: '',
      password1_recovery: '',
      password2_recovery: '',
      error_message: '',
      wait_email: false,
      disabled: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    validationUsernameOrEmail() {
      if (this.form.emailOrUsername !== '') {
        const isEmail = this.email_regex.test(String(this.form.emailOrUsername)) && this.form.emailOrUsername.length < 50;
        const isUsername = this.username_regex.test(String(this.form.emailOrUsername)) && this.form.emailOrUsername.length < 50;
        return isEmail || isUsername;
      }
      return null;
    },
    validationEmailPassRecov() {
      return this.email_regex.test(String(this.email_recovery)) && this.email_recovery.length < 50
    },
    validationCode() {
      return this.code_recovery.length === 6;
    },
    validationPass() {
      if (this.form.password !== '')
        return this.pass_regex.test(this.form.password) && this.form.password.length < 50
      return null;
    },
    validationNewPass() {
      return this.pass_regex.test(this.password1_recovery) && this.password1_recovery.length < 50 && this.password1_recovery === this.password2_recovery;
    },
    ...mapState(LandingPageStoreIndex.storeName,{
      show: LandingPageStoreState.loginSelected
    })
  },
  methods: {
    ...mapMutations(StoreIndex.storeName, {
      SET_ID: StoreMutations.SET_ID
    }),
    getFacilitatorID(emailOrUsername, pass) {
      if (emailOrUsername === true && pass === true) {
        FacilitatorServices.getFacilitatorID(this.form.emailOrUsername, this.form.password).then((response) => {
          this.data = response.data
          if(this.data !== '') {
            FacilitatorServices.getFacilitatorIsHidden(response.data).then((resp) => {
              this.disabled = resp.data
              if (resp.data === true) {
                this.$refs['modal_bad_user'].show()
                return
              } else {
                if (this.data === '') {
                  this.$refs['modal_bad_user'].show()
                } else {
                  FacilitatorServices.resetPasswordRecovery(this.data);
                  this.SET_ID(this.data);
                  this.$router.push({path: 'portal'})
                }
              }
            })
          }
          else{
            if (this.data === '') {
              this.$refs['modal_bad_user_input'].show()
            } else {
              FacilitatorServices.resetPasswordRecovery(this.data);
              this.SET_ID(this.data);
              this.$router.push({path: 'portal'})
            }
          }
        })
      } else {
        this.$refs['modal_bad_input'].show()
      }
    },
    getParticipantID() {
      ParticipantServices.getParticipant(this.form.username).then((response) => {
        this.data = response.data
        if (response.data === "") {
          this.$refs['modal'].show()
        } else {
          this.$router.push({path: 'enterCode'});
        }
      })
    },
    addParticipant() {
      ParticipantServices.addParticipant(this.form.username).then((response) => {
        this.data = response.data
        if (response.data === "") {
          this.$refs['modal'].show()
        } else {
          this.$router.push({path: 'enterCode'});
        }
      })
    },
    addFacilitator() {
      FacilitatorServices.addFacilitator(this.form.username, this.form.email, this.form.password).then((response) => {
        this.data = response.data
        this.toPortal()
      })
    },
    toPortal() {
      FacilitatorServices.getFacilitatorID(this.form.username, this.form.email, this.form.password).then((response) => {
        this.data = response.data
        if (response.data === '') {
          this.$refs['modal_bad_input'].show()
        } else {
          this.$router.push({path: 'portal', query: {id: this.data}})
        }
      })
    },
    async forgotPassword() {
      if ((this.email_recovery === '') || !this.email_regex.test(String(this.email_recovery)) || (this.email_recovery.length > 50)) {
        this.$refs['modal_bad_input'].show()
      } else {
        this.wait_email = true;
        await FacilitatorServices.recoverPass(this.email_recovery).then(res => {
          if (res.data === "") {
            this.enterCode = true;
            this.forgot = false;
          } else {
            this.error_message = res.data;
            this.$refs['modal_pass_recovery'].show();
          }
          this.wait_email = false;
        })
      }
    },
    async checkRecoveryCode() {
      await FacilitatorServices.checkCode(this.email_recovery, this.code_recovery).then(res => {
        if (res.data === "Good") {
          this.newPass = true;
          this.enterCode = false;
        } else {
          this.error_message = res.data;
          this.$refs['modal_pass_recovery'].show();
        }
      })
    },
    async changePassword() {
      if (this.pass_regex.test(this.password1_recovery) && this.password1_recovery.length < 50 && this.password1_recovery === this.password2_recovery) {
        await FacilitatorServices.setNewPassword(this.email_recovery, this.password1_recovery).then(res => {
          if (res.data === "Good") {
            this.newPass = false;
            this.Facilitator = true;
            this.$bvToast.toast('Password Has Been Changed.', {
              title: `Success!`,
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-center'
            })
          }
        })
      }
    },
    openEnterCodePage() {
      this.$router.push({name: 'enterCode'});
    },
    openUserManual() {
      const pdfUrl = "./assets/static/UserManualLogin.pdf"
      window.open(pdfUrl, '_blank');
    },
    windowResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.windowResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowResize);
  },
}
</script>

<style scoped>
p {
  color: dimgray;
}

.back {
  /*background-image: url('../assets/footsteps.gif') !important;*/
  height: 72vh;
  width: 100vw;
  background-image: url('../assets/footsteps.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 2rem;
  background-size: 100vh;
  position: page;
}

.version {
  position: fixed;
  bottom: 30px;
  left: 20px
}

.buttonSelect {
  padding: 1.5rem;
  margin-right: 20%;
  margin-left: 20%;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: whitesmoke;
}

.smallButtonSelect {
  padding: 1.0rem;
  margin-right: 5%;
  margin-left: 5%;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: whitesmoke;
}

.form {
  /*margin-top: 0.5rem;*/
  margin-right: 1.5%;
  margin-left: 1.5%;
}

.fgtpass {
  font-size: larger;
}

.menu {
  text-decoration: none;
  color: #333333;
}

.logIn {
  font-size: 23px;
  margin-top: 5rem;
  float: right;
  margin-right: 1rem;
}

.userManualButton {
  position: absolute;
  top: 100px;
  right: 100px;
}


</style>
