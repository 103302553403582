import axios from 'axios'

const SPRING_SECURITY_API_URL = "/api/spring-security/"

class SpringSecurityServices {
    /**
     * Checks to see if a session is valid, if it is this method will return the UUID
     * Of the person logged into this session else it will return null
     * @returns {Promise<AxiosResponse<Boolean>>}
     */
    getSession() {
        return axios.post(SPRING_SECURITY_API_URL + 'check-session');
    }

    logout() {
        return axios.post(SPRING_SECURITY_API_URL + 'logout');
    }
}

export default new SpringSecurityServices()